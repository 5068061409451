import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
    // Checkbox,
    FormControlLabel,
    FormGroup,
    makeStyles,
    MenuItem,
    Select as MaterialSelect,
    TextField,
    withStyles,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import Select from 'react-select';
import { CalendarToday } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    getCvTags,
    getInterviewErrors,
    getRecruitmentPlans,
} from '../../features/processes/interviewSlice';
import _, { isEmpty } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { GreenCheckbox } from '../Forms/Checkboxs/GreenCheckbox';
import { schoolsInterview } from '../../configs/school';
import { positions } from '../../configs/app';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    FormControl: {
        width: '16rem',
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    hrStyle: {
        marginTop: 'none',
        marginBottom: 'none',
        width: '16rem',
        position: 'absolute',
        top: '0',
        borderTop: '5px solid orange',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '15.5rem',
    },
    validate: {
        color: 'red',
    },
    buttonAdd: {
        backgroundColor: '#2DCE89',
        color: 'white',
        fontSize: '12px',
        marginTop: '0.3rem',
        '&&:hover': {
            backgroundColor: '#2DCE89',
            color: 'white',
            fontSize: '12px',
        },
    },
    closeButton: {
        position: 'absolute',
        zIndex: 1000,
        color: '#525f7f',
        right: '7px',
        top: '4px',
    },
    divButtonAddStyle: {
        marginTop: '0.9rem',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '18.75rem',
    },
    selectReactStyle: {
        width: '18.7rem',
    },
    closeIconStyle: {
        width: '24px',
        height: '24px',
    },
    marginTitleRecruitment: {
        marginBottom: '0',
        fontSize: '1.2rem',
    },
    inputStyle: {
        border: '1px solid #ced4da',
        borderRadius: 4,
        width: '18.75rem',
    },
    selectStyle: {
        border: '1px solid #ced4da',
        borderRadius: 4,
        width: '18.75rem',
    },
    labelCheckbox: {
        fontSize: '1rem',
        color: '#525F7F',
        fontWeight: 'lighter',
    },
}));

const getSchoolOptions = (schools) => {
    let result = schools.map(school => {
        return {
            value: school,
            label: school,
        };
    });

    return [...[{ value: ' ', label: 'Trường học...' }], ...result];
};

const getPositionOptions = (options) => {
    let result = options.map(item => {
        return {
            value: item,
            label: item,
        };
    });

    return [...[{ value: ' ', label: 'Trường học...' }], ...result];
};

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const formatLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
    </div>
);

function EditInformation(props) {
    const classes = useStyles();

    const handleChangeInterviewDate = (e) => {
        props.setInterviewDate(e);
    };

    const errors = useSelector(getInterviewErrors);
    const cvTags = useSelector(getCvTags);
    const recruitmentPlans = useSelector(getRecruitmentPlans);

    const renderRecruitmentPlan = (recruitmentPlans) => {
        if (!isEmpty(recruitmentPlans) && recruitmentPlans.length > 0) {
            let recruitmentPlanRenders = recruitmentPlans.filter(
                i => i.status === 'Đã phê duyệt');
            return recruitmentPlanRenders.map(item => {
                let title = '';
                if (!isEmpty(item.recruitment_plan_details_estimate)) {
                    let planDetailEstimateDetail = Object.entries(
                        item.recruitment_plan_details_estimate);
                    let planDetailEstimateDetailLength = planDetailEstimateDetail.length;
                    for (let key in planDetailEstimateDetail) {
                        let item = planDetailEstimateDetail[key];
                        title += `${item[0]} - ${item[1]} người ${(parseInt(
                            planDetailEstimateDetailLength - 1)) ===
                            parseInt(key) ? '' : '\n'}`;
                    }
                }
                return (
                    <MenuItem key={`item-search-${item.id}`} title={`${title}`}
                        value={`${item.id}`}>{item.division} - {item.name}</MenuItem>);
            });
        }
    };

    const handleChangeCVTag = (event) => {
        const cvTag = props.cvTag;
        props.setCVTag({
            ...cvTag,
            [event.target.value]: event.target.checked,
        });
    };

    const handleOnchangeSchool = (value) => {
        props.setSchool(value);
    };

    let formatValueSelect = (itemSelect, options) => {
        if (itemSelect?.value) {
            return itemSelect;
        } else {
            let index = _.findIndex(options, (item) => {
                return item.value == itemSelect;
            });
            if (index != -1) {
                return options[index];
            } else {
                return null;
            }
        }
    };

    let formatValueSelect1 = (itemSelect, options) => {

        if (itemSelect?.value) {
            return itemSelect;
        } else {
            let index = _.findIndex(options, (item) => {
                return item?.value ? item.value == itemSelect : item ==
                    itemSelect;
            });
            return index != -1 ? {
                value: options[index],
                label: options[index],
            } : null;
        }
    };

    const renderCvTags = (cvTags) => {
        let result = '';

        if (cvTags && cvTags.length > 0) {
            result = cvTags.map(item => {
                return (
                    <FormControlLabel
                        key={`${item.id}-search`}
                        className={'m-0 d-flex'}
                        control={
                            <GreenCheckbox
                                style={{ padding: '3px' }}
                                checked={props.cvTag[`${item.id}`]}
                                onChange={handleChangeCVTag}
                                value={item.id}
                                name={item.name} />
                        }
                        label={
                            <Typography
                                className={classes.labelCheckbox}>{item.name_vn}</Typography>
                        }
                    />
                );
            });
        }

        return result;
    };

    return (
        <>
            <h3 className={'position-relative'}>Thông tin
                <hr className={classes.hrStyle} />
            </h3>

            <Grid className={'d-flex flex-row justify-content-between py-2'}>
                <Grid className={`w-50`}>
                    <p className={'mb-0'}>Họ và tên ứng viên <span
                        className={classes.validate}>*</span></p>
                    <TextField placeholder="Nhập họ và tên ứng viên..."
                        type="text"
                        variant="filled"
                        value={props.name}
                        onChange={event => props.setName(
                            event.target.value)}
                        className={classes.inputStyle}
                    />
                    <span
                        className={'text-danger errors-name errors'}>{errors?.name?.[0]}</span>
                </Grid>
                <Grid className={`w-50`}>
                    <p className={'mb-0'}>Email <span
                        className={classes.validate}>*</span></p>
                    <TextField placeholder="Nhập email ứng viên..."
                        type="email"
                        variant="filled"
                        value={props.email}
                        onChange={event => props.setEmail(
                            event.target.value)}
                        className={classes.inputStyle}
                    />
                    <span
                        className={'text-danger errors-email errors'}>{errors?.email?.[0]}</span>
                </Grid>
            </Grid>
            <Grid className={'d-flex flex-row justify-content-between pb-2'}>
                <Grid className={`w-50`}>
                    <p className={'mb-0'}>Số điện thoại <span
                        className={classes.validate}>*</span></p>
                    <TextField placeholder="Nhập số điện thoại..."
                        type="text"
                        variant="filled"
                        value={props.phone}
                        onChange={event => props.setPhone(
                            event.target.value)}
                        className={classes.inputStyle}
                    />
                    <br />
                    <span
                        className={'text-danger errors-phone errors'}>{errors?.phone?.[0]}</span>
                </Grid>
                <Grid className={`w-50`}>
                    <p className={'mb-0'}>Link CV <span
                        className={classes.validate}>*</span></p>
                    <TextField placeholder="Link CV..."
                        type="text"
                        variant="filled"
                        value={props.linkCv}
                        onChange={event => props.setLinkCv(
                            event.target.value)}
                        className={classes.inputStyle}
                    />
                    <br />
                    <span
                        className={'text-danger errors-link_cv errors'}>{errors?.cv_link?.[0]}</span>
                </Grid>
            </Grid>

            <Grid className={'d-flex flex-row justify-content-between pb-2'}>
                <Grid className={`w-50`}>
                    <p className={'mb-0'}>Thời gian hẹn phỏng vấn</p>
                    <form className={classes.container} noValidate>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            noValidate
                        >
                            <DateTimePicker
                                fullWidth
                                value={props.interviewDate}
                                ampm={false}
                                clearable
                                onChange={event => handleChangeInterviewDate(
                                    event)}
                                cancelLabel={'CANCEL'}
                                format="dd-MM-yyyy HH:mm"
                                className={'date-picker-custom'}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton>
                                            <CalendarToday />
                                        </IconButton>
                                    ),
                                    placeholder: 'Chọn ngày giờ...',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </form>
                    <span
                        className={'text-danger errors-interview_date errors'}>{errors?.interview_date?.[0]}</span>
                </Grid>
                <Grid className={`w-50`}>
                    <p className={'mb-0'}>Kế hoạch tuyển dụng</p>
                    <FormControl className={classes.selectStyle}>
                        <MaterialSelect
                            input={<BootstrapInput />}
                            displayEmpty
                            value={props.recruitmentPlanId}
                            onChange={event => props.setRecruitmentPlanId(
                                event.target.value)}
                            renderValue={
                                props.recruitmentPlanId !== ' '
                                    ? undefined
                                    : () => <div>Chọn kế hoạch...</div>
                            }
                        >
                            <MenuItem value={' '}>Kế hoạch tuyển
                                dụng...</MenuItem>

                            {renderRecruitmentPlan(recruitmentPlans)}

                        </MaterialSelect>
                    </FormControl>
                    <br />
                    <span
                        className={'text-danger errors-recruitment_plan_id errors'}>{errors?.recruitment_plan_id?.[0]}</span>
                </Grid>
            </Grid>
            <Grid className={'d-flex flex-row justify-content-between'}>
                <Grid className={classes.selectReactStyle}>
                    <p className={'mb-0'}>Trường học</p>
                    <Select
                        value={formatValueSelect(props.school, getSchoolOptions(schoolsInterview))}
                        placeholder={'Chọn trường học...'}
                        defaultValue={' '}
                        options={getSchoolOptions(schoolsInterview)}
                        formatGroupLabel={formatLabel}
                        onChange={value => handleOnchangeSchool(value)}
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            control: base => ({
                                ...base,
                                height: 45,
                                minHeight: 45,
                                borderRadius: '5px',
                                border: '1px solid #cad1d7 !important',
                            }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                    />
                </Grid>
                <Grid className={`w-50`}>
                    <p className={'mb-0'}>CV Tag:</p>
                    <FormGroup className={'mb-0'}>
                        {renderCvTags(cvTags)}
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid className={'d-flex flex-row justify-content-between'}>
                <Grid className={classes.selectReactStyle}>
                    <p className={'mb-0'}>Vị trí</p>
                    <Select
                        value={formatValueSelect1(props.position, positions)}
                        placeholder={'Chọn vị trí...'}
                        defaultValue={' '}
                        options={getPositionOptions(positions)}
                        formatGroupLabel={formatLabel}
                        onChange={value => props.setPosition(value)}
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            control: base => ({
                                ...base,
                                height: 45,
                                minHeight: 45,
                                borderRadius: '5px',
                                border: '1px solid #cad1d7 !important',
                            }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                    />
                </Grid>
            </Grid>
        </>
    );
}

EditInformation.propTypes = {
    name: PropTypes.string,
    setName: PropTypes.func,
    email: PropTypes.string,
    setEmail: PropTypes.func,
    phone: PropTypes.string,
    setPhone: PropTypes.func,
    linkCv: PropTypes.string,

    setLinkCv: PropTypes.func,
    setPosition: PropTypes.func,
    school: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    position: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),

    setSchool: PropTypes.func,
    recruitmentPlanId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    setRecruitmentPlanId: PropTypes.func,
    interviewDate: PropTypes.oneOfType([
        PropTypes.string,
    ]),
    setInterviewDate: PropTypes.func,
    cvTag: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    setCVTag: PropTypes.func,
};

export default EditInformation;