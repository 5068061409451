import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import {CalendarToday} from '@material-ui/icons';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import {isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {
    resetRecruitmentOfCreatPlan,
} from '../../../../features/processes/processSlice';
import moment from 'moment';
import {
    getRecruitmentPlanErrors, resetErrors, updateRecruitmentPlan,
} from '../../../../features/processes/recruitmentPlanSlice';

import {NotificationContainer, NotificationManager} from 'react-notifications';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '15.5rem',
    },
    formControlRecruitmentPlan: {
        width: '39rem',
        marginRight: '5px',
    },
    formControlTechnology: {
        width: '12rem',
        marginRight: '5px',
    },
    marginSendIcon: {
        marginRight: '5px',
    },
    formControlDivision: {
        width: '7rem',
        marginRight: '5px',
    },
    nameRecruitmentPlan: {
        margin: 0,
    },
    validate: {
        color: 'red',
    },
    buttonSave: {
        backgroundColor: '#2DCE89',
        color: 'white',
        fontSize: '12px',
        marginTop: '0.3rem',
        '&&:hover': {
            backgroundColor: '#2DCE89',
            color: 'white',
            fontSize: '12px',
        },
    },
    closeButton: {
        position: 'absolute',
        zIndex: 1000,
        color: '#525f7f',
        right: '7px',
        top: '4px',
    },
    inputQuantity: {
        border: '1px solid #cad1d7',
        paddingLeft: ' 11px',
        width: '3rem',
        borderRadius: '5px',
    },
    nameRecruitmentTitle: {
        marginBottom: '0.6rem',
        marginTop: '0.6rem',
    },
    closeIconStyle: {
        width: '24px',
        height: '24px',
    },

    inputSearchStyle: {
        border: '1px solid #ced4da',
        borderRadius: 4,
        width: '31.6rem',
    },
    groupTitleTechnologyAndQuantity: {
        marginTop: '0.1rem',
    },
    timeForRecruitment: {
        width: '200px',
    },

}));

export default function UpdateRecruitmentPlanModal(props) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [dateTimeRecruitment, setDateTimeRecruitment] = useState();
    const [dateEnd, setDateEnd] = useState('');
    const [division, setDivision] = useState('');
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [idUpdate, setIdUpdate] = useState('');
    const [planDetails, setPlanDetails] = useState([]);
    const errors = useSelector(getRecruitmentPlanErrors);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(props.recruitmentPlanUpdate)) {
            let recruitmentPlan = props.recruitmentPlanUpdate;
            let planDetailDefault = [];
            if (recruitmentPlan.details.length > 0) {
                for (let detailKey in recruitmentPlan.details) {
                    let detail = recruitmentPlan.details[detailKey];
                    let time = (Date).now() + Math.random();
                    planDetailDefault.push({...detail, time: time});
                }
            } else {
                planDetailDefault = [
                    {
                        time: (Date).now(),
                        type: ' ',
                        estimate_quantity: 1,
                        quantity: 1,
                    }];
            }
            setName(recruitmentPlan.name);
            setIdUpdate(recruitmentPlan.id);
            setPlanDetails(planDetailDefault);
            setDateEnd(recruitmentPlan.date_end);
            setDivision(recruitmentPlan.division);
            setDateTimeRecruitment(
                moment(recruitmentPlan.date_recruitment_end, 'DD-MM-YYYY').
                    format('YYYY-MM-DD'));
        }
    }, [props.recruitmentPlanUpdate]);

    useEffect(() => {
        return () => {
            dispatch(resetRecruitmentOfCreatPlan());
            dispatch(resetErrors());
        };
    }, []);

    const handleClose = (event) => {
        event.preventDefault();
        props.setShowUpdateModal(false);
    };
    const handleChangeDateTimeRecruitment = (event) => {
        setDateTimeRecruitment(event);
    };

    const handleUpdateRecruitmentPlan = async () => {
        let data = {
            id: idUpdate,
            details: planDetails,
            date_recruitment_end: moment(dateTimeRecruitment, 'YYYY-MM-DD').
                format('YYYY-MM-DD'),
            name,
        };
        setLoading(true);
        let response = await dispatch(updateRecruitmentPlan(data));
        if (response?.payload?.status === 200) {
            NotificationManager.success('Thành công!', '', 1500);
            props.setShowUpdateModal(false);
        }
        setLoading(false);
    };

    const handleSetEstimateQuantity = (event, index) => {
        let dataDetails = planDetails;
        planDetails[index].estimate_quantity = event.target.value;
        setPlanDetails([...dataDetails]);
    };

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.showUpdateModal}
                onClose={handleClose}
                maxWidth={'lg'}
                style={{width: '46rem'}}
                className={'m-auto'}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <IconButton onClick={handleClose} size={'medium'}
                                className={classes.closeButton}>
                        <CloseIcon fontSize={'large'}
                                   className={classes.closeIconStyle} />
                    </IconButton>
                    <h2>Cập nhật kế hoạch tuyển dụng</h2>
                    <Grid className={'d-flex'}>
                        <p className={`mb-0 pr-1`}>Từ nhu cầu nhân sự: </p>
                        <p className={`mb-0`}> {`${props?.recruitmentPlanUpdate?.division} - ${props?.recruitmentPlanUpdate?.name}`}</p>
                    </Grid>

                    <p className={classes.nameRecruitmentTitle}>Tên kế hoạch
                        tuyển dụng <span
                            className={classes.validate}>*</span></p>
                    <div className={'d-flex align-items-center'}>
                        <p className={'m-0'}>{division} <span
                            className={'mr-1'}>-</span></p>
                        <p className={classes.nameRecruitmentPlan}>{name}</p>
                    </div>
                    <span
                        className={'text-danger errors-name errors'}>{errors?.name?.[0]}</span>
                    <br />

                    <Grid container spacing={3}
                          className={classes.groupTitleTechnologyAndQuantity}>
                        <Grid item xs className={'text-center'}>
                            <p align={'left'}>Công nghệ <span
                                className={classes.validate}>*</span></p>
                        </Grid>
                        <Grid item xs className={'text-center'}>
                            <p className={'text-center'}>Số lượng cần
                                tuyển <span
                                    className={classes.validate}>*</span></p>
                        </Grid>
                        <Grid item xs className={'text-center'}>
                            <p>Số lượng nhân sự đầu ra <span
                                className={classes.validate}>*</span></p>
                        </Grid>
                    </Grid>
                    {planDetails.map((item, index) => {
                        return (
                            <Grid container spacing={3} key={item.time}
                                  className={'pt-0'}>
                                <Grid item xs className={'text-left py-0'}>
                                    <p className={'pl-3 mb-0'}>{item.type}</p>
                                </Grid>
                                <Grid item xs className={'text-center pt-0'}>
                                    <input type="number"
                                           onChange={event => handleSetEstimateQuantity(
                                               event, index)}
                                           className={classes.inputQuantity}
                                           value={item.estimate_quantity}
                                           max={20} min={0} />
                                </Grid>
                                <Grid item xs className={'text-center py-0'}>
                                    <p className={'mb-0'}>{item.quantity}</p>
                                </Grid>
                            </Grid>
                        );
                    })}

                    <span
                        className={'text-danger errors-details errors'}>{errors?.['details.0.estimate_quantity']?.[0]}</span>
                    <br />

                    <Grid className={`d-flex flex-column `}>
                        <Grid item xs className={`d-flex pb-0 pl-0`}>
                            <p className={`${classes.timeForRecruitment} mb-0`}>Thời
                                hạn tuyển dụng <span
                                    className={classes.validate}>*</span></p>
                            <p className={`pl-3 mb-0`}>Thời hạn
                                bàn giao <span
                                    className={classes.validate}>*</span>
                            </p>

                        </Grid>
                        <Grid item xs={8}
                              className={`d-flex align-content-center pl-0`}>
                            <Grid item xs
                                  className={`${classes.timeForRecruitment}  pl-0 `}>
                                <form
                                    className={`${classes.timeForRecruitment}`}
                                    noValidate>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        noValidate
                                    >
                                        <DatePicker
                                            fullWidth
                                            value={dateTimeRecruitment}
                                            onChange={event => handleChangeDateTimeRecruitment(
                                                event)}
                                            format="dd-MM-yyyy"
                                            className={`date-picker-custom w-80`}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton>
                                                        <CalendarToday />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </form>
                            </Grid>
                            <Grid item xs
                                  className={`d-flex align-content-center align-items-center pl-3`}>
                                <span>{dateEnd}</span>
                            </Grid>
                        </Grid>

                        <span
                            className={'text-danger errors-date_recruitment_end errors'}>{errors?.['date_recruitment_end']?.[0]}</span>
                        <Grid>
                            <Grid item xs align={'right'}>
                                <Button disabled={loading}
                                        onClick={() => {handleUpdateRecruitmentPlan();}}
                                        className={classes.buttonSave}>
                                    <span
                                        className={`${classes.marginSendIcon} pr-1`}>{loading
                                        ? 'Đang xử lý....'
                                        : 'Gửi'}</span>
                                    <SendIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>
            <NotificationContainer />

        </div>
    );
}

UpdateRecruitmentPlanModal.propTypes = {
    setShowUpdateModal: PropTypes.func,
    showUpdateModal: PropTypes.bool,
    recruitmentPlanUpdate: PropTypes.object,
};

