import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types';
import {TextArea} from 'semantic-ui-react';
import {useSelector} from 'react-redux';
import {getInterviewErrors} from '../../features/processes/interviewSlice';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  FormControl: {
    width: '16rem',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 16px 15px 15px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  hrStyle: {
    marginTop: 'none',
    marginBottom: 'none',
    width: '16rem',
    position: 'absolute',
    top: '0',
    borderTop: '5px solid orange',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '15.5rem',
  },
  buttonAdd: {
    backgroundColor: '#2DCE89',
    color: 'white',
    fontSize: '12px',
    marginTop: '0.3rem',
    '&&:hover': {
      backgroundColor: '#2DCE89',
      color: 'white',
      fontSize: '12px',
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1000,
    color: '#525f7f',
    right: '7px',
    top: '4px',
  },
  divButtonAddStyle: {
    marginTop: '0.9rem',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  closeIconStyle: {
    width: '24px',
    height: '24px',
  },
  marginTitleRecruitment: {
    marginBottom: '0.4rem',
    fontSize: '1.2rem',
  },
  inputStyle: {
    border: '1px solid #ced4da',
    borderRadius: 2,
    width:'4rem',
  },
  selectStyle: {
    width:"8rem",
  },

  textAreaEvaluate: {
    resize:'none',
    display:'-webkit-box',
    WebkitBoxOrient:'vertical',
    overflow: 'hidden',
    whiteSpace: 'normal',
    borderRadius:'3px',
    borderColor:'#c0c0c0',
    color:'#838383',
    transition:'all 0.5s ease',
    height:'95px',
    WebkitLineClamp:'6',
    width:'100%',
    '&::-webkit-input-placeholder': {
      color: '#83838385',
    },

    '&:-moz-placeholder': { /* Firefox 18- */
      color: '#83838385',
    },

    '&::-moz-placeholder': {  /* Firefox 19+ */
      color: '#83838385',
    },

    '&:-ms-input-placeholder': {
      color: '#83838385',
    },

    '&::placeholder': {
      color: '#83838385',
    },
  },

}));

function EditPoint(props) {
  const classes = useStyles();
  const errors = useSelector(getInterviewErrors);

  return (
      <>
        <h3 className={'position-relative'}>Kết quả phỏng vấn
          <hr className={classes.hrStyle}/>
        </h3>

        <Grid className={'d-flex flex-column w-100 pt-2'}>
          <Grid className={'d-flex flex-row justify-content-between w-100 pb-2'}>
            <Grid>
              <p className={'mb-0'}>Có đến phỏng vấn?
              </p>
              <FormControl className={classes.selectStyle}>
                <Select
                    input={<BootstrapInput/>}
                    value={props.isCome}
                    defaultValue={' '}
                    onChange={event => props.setIsCome(
                        event.target.value)}
                >
                  <MenuItem value={' '}>Có / Không</MenuItem>
                  <MenuItem value={"1"}>Có</MenuItem>
                  <MenuItem value={"0"}>Không</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid className={'text-center'}>
              <p className={'mb-0'}>Điểm kiểm tra (%)
              </p>
              <TextField type="number"
                         variant="filled"
                         value={props.technicalScore}
                         max={100}
                         min={0}
                         onChange={event => props.setTechnicalScore(event.target.value)}
                         className={classes.inputStyle}
              />
            </Grid>
            <Grid className={'text-center'}>
              <p className={'mb-0'}>Điểm phỏng vấn trực tiếp </p>
              <TextField type="number"
                         variant="filled"
                         max={100}
                         min={0}
                         value={props.softskillScore}
                         onChange={event => props.setSoftskillScore   (event.target.value)}
                         className={classes.inputStyle}
              />
            </Grid>
          </Grid>
          <span className={'text-danger errors-softskill_score errors'}>{errors?.softskill_score?.[0]}</span>
          <span className={'text-danger errors-technical_score errors'}>{errors?.technical_score?.[0]}</span>

          <Grid className={'d-flex flex-column w-100'}>
            <p className={'mb-0'}>Nhận xét(nếu có)</p>
            <TextArea className={`${classes.textAreaEvaluate} `}
                      value={props.comment}
                      onChange={event => props.setComment(event.target.value)}
                      onFocus={event => event.target.select()}
                      placeholder={'Nhập nhận xét...'}
                      rows={1}/>
          </Grid>

          <Grid className={'d-flex flex-row w-100 justify-content-center align-items-center pt-2'}>
            <p className={'mb-0 pr-3'}>Kết quả cuối cùng</p>
            <FormControl className={classes.selectStyle}>
              <Select
                  input={<BootstrapInput/>}
                  value={props.isPass}
                  defaultValue={' '}
                  onChange={event => props.setIsPass(event.target.value)}
              >
                <MenuItem value={' '} >NA</MenuItem>
                <MenuItem value={"1"} className={'text-success'}>Pass</MenuItem>
                <MenuItem value={"0"} className={'text-danger'}>Fail</MenuItem>
              </Select>
            </FormControl>
          </Grid>

        </Grid>

      </>
  );
}

EditPoint.propTypes = {
  isCome: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setIsCome: PropTypes.func,
  technicalScore: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setTechnicalScore: PropTypes.func,
  softskillScore: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setSoftskillScore: PropTypes.func,
  comment: PropTypes.string,
  setComment: PropTypes.func,
  isPass: PropTypes.string,
  setIsPass: PropTypes.func,
};

export default EditPoint;