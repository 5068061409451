import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from '../configs/axios';
const initialState = {
    subjects: [],
    subject: {},
    status: '',
}

export const fetchSubjects = createAsyncThunk('subject/fetchSubjects',
    async ({rejectWithValue}) => {
        try {
            return await axios.get(`/subjects`);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

const subjectSlice = createSlice({
    name: 'subject',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchSubjects.pending]: (state)=>{
            state.status = 'loading'
        },

        [fetchSubjects.fulfilled]: (state, action)=>{
            state.subjects = action?.payload?.data?.data;
        },
    }
});

export const getSubjects = state => state.subject.subjects;
export default subjectSlice.reducer;
