import React, {useEffect, useState} from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import List from './List';
import TextField from '@material-ui/core/TextField';

// core components
import componentStyles from 'assets/theme/views/admin/dashboard.js';
import {useDispatch, useSelector} from 'react-redux';
import {fetchInterns, getLoading} from '../../../features/user/userSlice';
import {MenuItem, Select, withStyles} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import useDebounce from '../../../hooks/useDebounce';
import SchoolIcon from '@material-ui/icons/School';
import {fetchSubjects} from '../../../features/subjectSlice';
import {
    fetchRecruitmentPlanApprovedAndDone,
    getRecruitmentPlans,
} from '../../../features/processes/interviewSlice';
import {isEmpty} from 'lodash';
import {useLocation} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../assets/css/custom/intern.css';
import moment from 'moment';
import {positions} from 'configs/app';
import {dehaDivisions} from 'configs/school';
import CircularIndeterminate
    from '../../../components/Loading/CircularIndeterminate';

const useStyles = makeStyles(componentStyles);

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

function Educate() {
    const dispatch = useDispatch();
    const recruitmentPlans = useSelector(getRecruitmentPlans);
    const classes = useStyles();
    const selectStyle = {
        marginRight: '10px',
        marginBottom: '10px',
        width: '15rem',
    };
    const inputSearchStyle = {
        border: '1px solid #ced4da',
        borderRadius: 4,
        width: '15rem',
    };
    const breadCrumbsStyle = {
        paddingTop: '0.5rem',
        marginBottom: '-0.55rem',
        marginLeft: '3px',
        fontWeight: 'bold',
    };
    const titleStyle = {
        marginTop: '-1.25rem',
    };
    const iconSchoolStyle = {
        position: 'absolute',
        marginTop: '1.17rem',
    };

    const [name, setName] = useState('');
    const [trainingState, setTrainingState] = useState('');
    const [recruitmentPlanId, setRecruitmentPlanId] = useState(' ');
    const [position, setPosition] = useState(' ');
    const [division, setDivision] = useState(' ');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const loadApi = useSelector(getLoading);
    const location = useLocation();
    useEffect(() => {
        async function setUp() {
            document.title = 'Đào tạo';
            dispatch(fetchSubjects({}));
            await dispatch(fetchRecruitmentPlanApprovedAndDone());
        }

        setUp();
    }, []);

    useEffect(() => {
        async function getInternsWhenChangeRecruitment() {
            if (location?.recruitment_plan_id) {
                await dispatch(fetchInterns({
                    recruitment_plan_id: location?.recruitment_plan_id,
                    page: 1,
                }));

                setRecruitmentPlanId(location?.recruitment_plan_id);
            } else {
                await dispatch(fetchInterns());
            }
        }

        getInternsWhenChangeRecruitment();
        return () => {
        };
    }, [location?.recruitment_plan_id]);

    function formatDateSearch(date) {
        return date ? moment(date, 'dd/MM/yyyy').format('DD/MM/yyyy') : '';
    }

    function searchWithName(e) {
        let nameSearch = e.target.value;
        setName(nameSearch);
        search(nameSearch, trainingState, recruitmentPlanId);
    }

    const searchWithoutDebounce = (
        name, trainingState, recruitmentPlanId, dateRange) => {
        let data = {
            page: 1,
            name: name,
            training_state: trainingState,
            recruitment_plan_id: recruitmentPlanId,
            end_from_date: formatDateSearch(dateRange?.[0]),
            end_to_date: formatDateSearch(dateRange?.[1]),
        };

        dispatch(fetchInterns(data));
    };
    const search = useDebounce(function(...arg) {
        const [nameSearch, trainingState, recruitmentPlanId, dateRange] = arg;
        let data = {
            page: 1,
            name: nameSearch,
            training_state: trainingState,
            recruitment_plan_id: recruitmentPlanId,
            end_from_date: formatDateSearch(dateRange?.[0]),
            end_to_date: formatDateSearch(dateRange?.[1]),
        };
        dispatch(fetchInterns(data));
    });

    function searchWithTraining(e) {
        let stateSearch = e.target.value;
        setTrainingState(stateSearch);
        searchWithoutDebounce(name, stateSearch, recruitmentPlanId, dateRange);
    }

    function searchWithRecruitmentPlan(e) {
        let value = e.target.value;
        setRecruitmentPlanId(value);
        searchWithoutDebounce(name, trainingState, value, dateRange);
    }

    const searchWithDateRange = (date) => {
        setDateRange(date);
        search(name, trainingState, recruitmentPlanId, date);
    };

    const renderRecruitmentPlan = (recruitmentPlans) => {
        if (!isEmpty(recruitmentPlans) && recruitmentPlans.length > 0) {
            return recruitmentPlans.map(item => {
                return (<MenuItem key={`item-serch-${item.id}`}
                                  value={item.id}>{item.division} - {item.name}</MenuItem>);
            });
        }
    };

    const searchWithPosition = (e) => {
        let position = e.target.value;
        setPosition(position);

    };

    const searchWithDivision = (e) => {
        let division = e.target.value;
        setDivision(division);
    };

    return (
        <>
            {/* Page content */}
            <Container
                maxWidth={false}
                className={`educate ${classes.flexGrow_1}`}
                classes={{root: classes.containerRoot}}
            >
                {loadApi ? (<CircularIndeterminate />
                ) : ''}
                <div>
                    <SchoolIcon style={iconSchoolStyle} />
                    <Breadcrumbs style={breadCrumbsStyle}
                                 aria-label="breadcrumb">
                        <Link color="inherit" href="/educates">
                            Đào tạo
                        </Link>
                    </Breadcrumbs>
                    <h2 style={titleStyle}>Quản lý thực tập sinh</h2>
                    <TextField id="filled-search" placeholder="Tìm kiếm..."
                               className={classes.searchBoxWrap}
                               type="search"
                               variant="filled"
                               style={inputSearchStyle}
                               onChange={(e) => {
                                   searchWithName(e);
                               }}
                    />
                    <FormControl style={selectStyle}>
                        <Select
                            input={<BootstrapInput />}
                            defaultValue={' '}
                            onChange={(e) => searchWithTraining(e)}
                        >
                            <MenuItem value={' '}>Trạng thái...</MenuItem>
                            <MenuItem value={'training'}>Đang thực
                                tập</MenuItem>
                            <MenuItem value={'passed'}>Đã hoàn thành</MenuItem>
                            <MenuItem value={'stopped'}>Dừng thực
                                tập </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl style={selectStyle}>
                        <Select
                            input={<BootstrapInput />}
                            value={recruitmentPlanId}
                            defaultValue={recruitmentPlanId}
                            onChange={(e) => searchWithRecruitmentPlan(e)}
                        >
                            <MenuItem value={' '}>Kế hoạch tuyển
                                dụng...</MenuItem>
                            {renderRecruitmentPlan(recruitmentPlans)}
                        </Select>
                    </FormControl>
                    <FormControl
                        className={`search-date-range ${classes.searchBoxWrap}`}>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Ngày kết thúc..."
                            monthsShown={2}
                            showNextMonths
                            onChange={(update) => searchWithDateRange(update)}
                            isClearable={true}
                            className={`intern-search-picker-input`}
                        />
                    </FormControl>
                    <FormControl style={selectStyle}>
                        <Select
                            input={<BootstrapInput />}
                            defaultValue={''}
                            value={position}
                            onChange={(e) => searchWithPosition(e)}
                        >
                            <MenuItem value={' '}>Vị trí...</MenuItem>
                            {
                                positions.map((item, key) => {
                                    return (<MenuItem key={key}
                                                      value={item}>{item}</MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl style={selectStyle}>
                        <Select
                            input={<BootstrapInput />}
                            defaultValue={''}
                            value={division}
                            onChange={(e) => searchWithDivision(e)}
                        >
                            <MenuItem value={' '}>Bộ phận...</MenuItem>
                            {
                                dehaDivisions.map((item, key) => {
                                    return (<MenuItem key={key}
                                                      value={item}>{item}</MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    <List nameProp={name} trainingProp={trainingState}
                          recruitmentPlanId={recruitmentPlanId} />
                </div>
            </Container>
        </>
    );
}

export default Educate;
