import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import RecruitmentPlanDetailModal from "./Details/RecruitmentPlanDetailModal";
import {useAuth} from '../../../../contexts/AuthContext';
import {
    getRecruitmentRequestForCreatePlan,
} from '../../../../features/processes/processSlice';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchRecruitmentPlan,
    getRecruitmentPlan,
    getRecruitmentPlans,
    getRecruitmentPlanShow,
    getRecruitmentPlanShowID,
    getRecruitmentRequestIDForCreate,
} from '../../../../features/processes/recruitmentPlanSlice';
import Typography from '@material-ui/core/Typography';
import {isEmpty} from 'lodash';
import Pagination from '@material-ui/lab/Pagination';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import UpdateRecruitmentPlanModal from './UpdateRecruitmentPlanModal';
import {useQuery} from '../../../../hooks/useQuery';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    iconColor: {
        color: '#ffffff',
    },
    edit: {
        borderRadius: '50%',
        marginLeft: '5px',
        backgroundColor: '#ff9800',
        '&:hover': {
            backgroundColor: 'rgba(255,152,0,0.78)',
        },
    },
    cancel: {
        borderRadius: '50%',
        marginLeft: '5px',
        backgroundColor: '#F44336',
        '&:hover': {
            backgroundColor: '#f16156',
        },
    },
    show: {
        borderRadius: '50%',
        backgroundColor: '#00bcd4',
        '&:hover': {
            backgroundColor: 'rgba(0,188,212,0.85)',
        },
    },
    vertical_bl: {
        verticalAlign: 'baseline',
    },
    buttonDisabled: {
        opacity: ".65",
        boxShadow: "none!important",
        cursor: 'not-allowed !important',
        pointerEvents: "none",
    },
});

export default function RecruitmentPlanList(props) {
    const classes = useStyles();
    const [showRecruitmentPlanDetailModal, setShowRecruitmentPlanDetailModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [recruitmentPlanUpdate, setRecruitmentPlanUpdate] = useState({});
    const {authorize} = useAuth()
    const recruitmentPlans = useSelector(getRecruitmentPlans)
    const recruitmentPlanId = useSelector(getRecruitmentPlanShowID)
    const recruitmentRequestId = useSelector(getRecruitmentRequestIDForCreate)
    const handleShowRecruitmentPlanDetail = (id) => {
        setShowRecruitmentPlanDetailModal(true);
        dispatch(getRecruitmentPlanShow({id}))
    };

    const location = useLocation()
    const dispatch = useDispatch()
    const recruitmentPlanShow = useSelector(getRecruitmentPlan);
    // get recruitment plan for create
    useEffect( () => {
        async function getRecruitmentRequestWhenIdNotNull() {
            if (recruitmentRequestId) {
                await dispatch(getRecruitmentRequestForCreatePlan({
                    id: recruitmentRequestId,
                }));
            }
        }

        getRecruitmentRequestWhenIdNotNull().then(()=>{});
        return () => {
        };
    }, [recruitmentRequestId]);

    const handleUpdate = (recruitmentPlan) => {
        setRecruitmentPlanUpdate(recruitmentPlan)
        setShowUpdateModal(true)
    }

    const query = useQuery();
    let recruitmentPlanShowID = query.get('recruitment_plan_id');
    useEffect( () => {
        async function replaceUrlWhenUserTabActionIsReload() {
            if (window.performance.navigation.type === 1 && !isEmpty(recruitmentPlanShowID)) {
                let urlReload = location.origin + location.pathname;
                window.location.replace(urlReload);
            }
        }
        replaceUrlWhenUserTabActionIsReload().then();
    }, []);

    useEffect( () => {
        async function getRecruitmentPlanWhenIdNotNull() {
            if (!isEmpty(recruitmentPlanShowID)) {
                await dispatch(getRecruitmentPlanShow({
                    id: recruitmentPlanShowID,
                }));
            }
        }

         getRecruitmentPlanWhenIdNotNull().then(()=>{});
        return () => {
        };
    }, [recruitmentPlanShowID]);

    useEffect( () => {
        async function getRecruitmentPlanWhenNotNull() {
            if (recruitmentPlanId) {
                await dispatch(getRecruitmentPlanShow({
                    id: recruitmentPlanId,
                }));
            }
        }

         getRecruitmentPlanWhenNotNull().then(()=>{});
    }, [recruitmentPlanId]);

    useEffect( () => {
        async function showRecruitmentPlanDetailModalIfNotNull() {
            if (!isEmpty(recruitmentPlanShow)) {
                setShowRecruitmentPlanDetailModal(true);
            }
        }
        showRecruitmentPlanDetailModalIfNotNull().then(()=>{});
    }, [recruitmentPlanShow]);

    const getEditClass = (status) => {
        return status !== "Đang chờ" ? classes.buttonDisabled : '';
    }

    const renderRecruitmentPlan = (recruitmentPlans) => {
        if (!isEmpty(recruitmentPlans) && !isEmpty(recruitmentPlans?.data) && recruitmentPlans.data.length > 0) {
            let meta = recruitmentPlans.meta;

            return recruitmentPlans.data.map((item, index) => {
                let iterator = (index + 1) + ((meta.current_page - 1) * meta.per_page);

                return (
                    <TableRow key={`plan-item-${item.id}`}>
                        <TableCell component="th" align="center" scope="row" className={classes.vertical_bl}>
                            {iterator}
                        </TableCell>
                        <TableCell align="center"
                                   className={classes.vertical_bl}>{`${item.division} - ${item.name}`}</TableCell>
                        <TableCell align="center" className={classes.vertical_bl}>{item.created_at}</TableCell>
                        <TableCell align="center" className={classes.vertical_bl}>{item.status}</TableCell>
                        <TableCell align="center"
                                   className={classes.vertical_bl}>{item.demand_originator_name}</TableCell>
                        <TableCell align="center" className={classes.vertical_bl}>
                            {
                                authorize.hasPermission('show-plan-recruitment')
                                    ?
                                    (<IconButton className={classes.show}
                                                 onClick={() => handleShowRecruitmentPlanDetail(item.id)}>
                                        <VisibilityIcon
                                            className={classes.iconColor}/>
                                    </IconButton>)
                                    : ''
                            }
                            {
                                authorize.hasPermission('edit-plan-recruitment')
                                    ?
                                    (<IconButton className={`${classes.edit} ${getEditClass(item.status)}`}
                                                 onClick={() => handleUpdate(item)}
                                    >
                                        <EditIcon
                                            className={classes.iconColor}/>
                                    </IconButton>)
                                    : ''
                            }
                        </TableCell>
                    </TableRow>
                )
            })
        } else {
            return (
                <TableRow align="center">
                    <TableCell width="5rem">
                        <Typography variant="subtitle2">No data.</Typography>
                    </TableCell>
                </TableRow>
            );
        }
    }
    const handleChange = async (event, value) => {
        let data = {
            page: value,
            name: props.nameProp,
            status: props.status,
            division: props.divisionSearch
        };
        await dispatch(fetchRecruitmentPlan(data));
    };
    const getPagination = (paginate) => {
        if (paginate) {
            return (
                <Pagination
                    count={paginate.last_page}
                    onChange={handleChange}
                    color="primary" variant="outlined"
                    boundaryCount={5}
                    size={'small'}
                    page={paginate.current_page}/>);
        }
    };

    return (
        <TableContainer component={Paper} style={{margin: '20px 0'}}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">STT</TableCell>
                        <TableCell align="center">Tên kế hoạch</TableCell>
                        <TableCell align="center">Thời gian khởi tạo&nbsp;</TableCell>
                        <TableCell align="center">Trạng thái&nbsp;</TableCell>
                        <TableCell align="center">Người gửi&nbsp;</TableCell>
                        <TableCell align="center">Hành động&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderRecruitmentPlan(recruitmentPlans)}
                </TableBody>
            </Table>


            <Box justifyContent="flex-start" display="flex"
                 style={{margin: '20px 0'}}>
                {getPagination(recruitmentPlans?.meta)}
            </Box>
            <UpdateRecruitmentPlanModal
                setShowUpdateModal={setShowUpdateModal}
                showUpdateModal={showUpdateModal}
                recruitmentPlanUpdate={recruitmentPlanUpdate}
            />
            <RecruitmentPlanDetailModal showRecruitmentPlanDetailModal={showRecruitmentPlanDetailModal}
                                        setShowRecruitmentPlanDetailModal={setShowRecruitmentPlanDetailModal}
            />
        </TableContainer>
    );
}

RecruitmentPlanList.propTypes = {
    nameProp: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    divisionSearch: PropTypes.string.isRequired,
};