import React, { useEffect, useState } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import '../../../../assets/css/custom/container.css';

import componentStyles from "assets/theme/views/admin/dashboard.js";
import { MenuItem, Select, withStyles } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AddIcon from '@material-ui/icons/Add';
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import HelpIcon from '@material-ui/icons/Help';
import Button from "@material-ui/core/Button";
import WorkIcon from "@material-ui/icons/Work";
import { useDispatch, useSelector } from 'react-redux';

import useDebounce from '../../../../hooks/useDebounce';
import { useAuth } from '../../../../contexts/AuthContext';
import RecruitmentProcess from "../Recruitments/RecruitmentProcess";
import { isEmpty } from 'lodash';
import AddInterviewModal from "./AddInterviewModal";
import InterviewList from "./InterviewList";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import {
    fetchCvTag,
    fetchInterview,
    fetchRecruitmentPlanApprovedAndDone,
    getCvTags,
    getInterviews,
    getRecruitmentPlans,
} from '../../../../features/processes/interviewSlice';
import { useLocation } from 'react-router-dom';
import { exportExcelBy } from '../../../../services/exportExcel';
import { schoolsInterview } from "../../../../configs/school";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { positions } from '../../../../configs/app';

const useStyles = makeStyles(componentStyles);
const interviewStyles = makeStyles({
    btnAction: {
        backgroundColor: '#2DCE89',
        height: 'fit-content',
        maxHeight: '50px',
        marginLeft: '5px',
        '&:hover': {
            backgroundColor: '#2ade91 !important',
        },
    },

    btnExcel: {
        backgroundColor: 'green',
        height: 'fit-content',
        maxHeight: '50px',
        marginLeft: '5px',
        '&:hover': {
            backgroundColor: '#2DCE89 !important',
        },
    },
    btnActionWrap: {
        display: 'flex'
    },

    disableCursor: {
        cursor: 'not-allowed'
    },
    disableButton: {
        pointerEvents: 'none',
        opacity: '0.5'
    },

    searchBoxWrap: {
        marginRight: '10px',
        marginBottom: '10px',
        width: '15rem',
    },

    searchBoxDate: {
        marginBottom: '10px',
        width: '15rem',
    }
});
const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

function Interview() {
    const classes = useStyles();
    const interviewClasses = interviewStyles();

    const inputSearchStyle = {
        border: '1px solid #ced4da',
        borderRadius: 4,
        width: '15rem',
    };
    const breadCrumbsStyle = {
        paddingTop: '0.5rem',
        marginBottom: '-0.55rem',
        marginLeft: '3px',
        fontWeight: 'bold',
    };
    const titleStyle = {
        marginTop: '-1.25rem',
    };
    const workIconStyle = {
        position: 'absolute',
        marginTop: '1.4rem',
    };

    const breadcrumbStyle = {
        marginLeft: "-0.48rem",
    };
    const buttonRecruitmentProcess = {
        position: "absolute",
        width: "15rem",
        fontSize: "15px",
        color: "rgb(8 89 191)",
        top: "6rem",
        right: "0.4rem"
    };
    const addIconStyle = {
        top: "-0.1rem",
        position: "relative",
        right: "0.5rem",
        height: "23px",
        width: "23px",
    };

    const helpIconStyle = {
        height: "25px",
        width: "25px"
    }
    const [showAddRecruitmentManageModal, setShowAddRecruitmentManageModal] = useState(false)
    const [nameSearch, setNameSearch] = useState('');
    const [status, setStatus] = useState(' ');
    const [school, setSchool] = useState(' ');
    const [position, setPosition] = useState(' ');
    const [recruitmentPlanId, setRecruitmentPlanId] = useState(' ');
    const [cvTagId, setCvTagId] = useState(' ');
    const [loading, setLoading] = useState(false);
    const recruitmentPlans = useSelector(getRecruitmentPlans)
    const cvTags = useSelector(getCvTags)
    const dispatch = useDispatch();
    const interviews = useSelector(getInterviews);
    const [dateRange, setDateRange] = useState([null, null]);
    const [dateRangeInterview, setDateRangeInterview] = useState([null, null]);
    const [dateRangeInternship, setDateRangeInternship] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [startInterviewDate, endInterviewDate] = dateRangeInterview;
    const [startDateInternship, endDateInternship] = dateRangeInternship;

    const { authorize } = useAuth()
    useEffect(() => {
        document.title = 'Quản lý ứng viên';
        async function getInterviews() {
            await dispatch(fetchRecruitmentPlanApprovedAndDone());
            await dispatch(fetchCvTag());
        }
        getInterviews();
    }, []);

    const location = useLocation();

    useEffect(() => {
        async function getInterviewsWhenUpgradeRecruitmentPlan() {
            if (location?.recruitment_plan_id) {
                await dispatch(fetchInterview({
                    recruitment_plan_id: location?.recruitment_plan_id,
                    page: 1,
                }));
                setRecruitmentPlanId(location?.recruitment_plan_id);
            } else {
                await dispatch(fetchInterview());
            }
        }

        getInterviewsWhenUpgradeRecruitmentPlan().then(() => { });
        return () => {
        };
    }, [location?.recruitment_plan_id]);

    function searchWithName(e) {
        let nameSearch = e.target.value;
        setNameSearch(nameSearch);
        search(nameSearch, status, recruitmentPlanId, school, cvTagId, dateRange, dateRangeInterview, dateRangeInternship, position);
    }

    function searchWithSchool(e) {
        let value = e.target.value;
        setSchool(value);
        searchWithoutDebounce(nameSearch, status, recruitmentPlanId, value, cvTagId, dateRange, dateRangeInterview, dateRangeInternship, position);
    }
    function searchWithPosition(e) {
        let value = e.target.value;
        setPosition(value);
        searchWithoutDebounce(nameSearch, status, recruitmentPlanId, school, cvTagId, dateRange, dateRangeInterview, dateRangeInternship, value);
    }

    function searchWithStatus(e) {
        let value = e.target.value;
        setStatus(value);
        searchWithoutDebounce(nameSearch, value, recruitmentPlanId, school, cvTagId, dateRange, dateRangeInterview, dateRangeInternship, position)
    }

    function searchWithRecruitmentPlan(e) {
        let value = e.target.value;
        setRecruitmentPlanId(value);
        searchWithoutDebounce(nameSearch, status, value, school, cvTagId, dateRange, dateRangeInterview, dateRangeInternship, position)
    }

    function searchWithCVTag(e) {
        let value = e.target.value;
        setCvTagId(value);
        searchWithoutDebounce(nameSearch, status, recruitmentPlanId, school, value, dateRange, dateRangeInterview, dateRangeInternship, position)
    }

    const searchWithoutDebounce = (nameData, statusData, recruitmentPlanIdData, school, cvTagIdData, date, dateRangeInterview, dateRangeInternship, position) => {
        let data = {
            page: 1,
            search: nameData,
            status: statusData,
            school: school,
            recruitment_plan_id: recruitmentPlanIdData,
            cv_tag_id: cvTagIdData,
            created_from_date: formatDateSearch(date[0]),
            created_to_date: formatDateSearch(date[1]),
            interview_from_date: formatDateSearch(dateRangeInterview[0]),
            interview_to_date: formatDateSearch(dateRangeInterview[1]),
            internship_from_date: formatDateSearch(dateRangeInternship[0]),
            internship_to_date: formatDateSearch(dateRangeInternship[1]),
            position

        };
        dispatch(fetchInterview(data));
    }

    const search = useDebounce(function (...arg) {
        const [name, status, recruitmentPlanId, school, cvTagId, dateRange, dateRangeInterview, dateRangeInternship, position] = arg;

        let data = {
            page: 1,
            search: name,
            status: status,
            recruitment_plan_id: recruitmentPlanId,
            school: school,
            position,
            cv_tag_id: cvTagId,
            created_from_date: formatDateSearch(dateRange[0]),
            created_to_date: formatDateSearch(dateRange[1]),
            interview_from_date: formatDateSearch(dateRangeInterview[0]),
            interview_to_date: formatDateSearch(dateRangeInterview[1]),
            internship_from_date: formatDateSearch(dateRangeInternship[0]),
            internship_to_date: formatDateSearch(dateRangeInternship[1]),
        };
        dispatch(fetchInterview(data));
    });

    const addRecruitmentManageModal = () => {
        setShowAddRecruitmentManageModal(true);
    }

    const [showRecruitmentProcessModal, setShowRecruitmentProcessModal] = useState();

    const exportExcel = async () => {
        setLoading(true)
        let data = {
            search: name,
            status: status,
            recruitment_plan_id: recruitmentPlanId,
            school: school,
            cv_tag_id: cvTagId,
            created_from_date: formatDateSearch(dateRange[0]),
            created_to_date: formatDateSearch(dateRange[1]),
            interview_from_date: formatDateSearch(dateRangeInterview[0]),
            interview_to_date: formatDateSearch(dateRangeInterview[1]),
            internship_from_date: formatDateSearch(dateRangeInternship[0]),
            internship_to_date: formatDateSearch(dateRangeInternship[1]),
            position

        };
        await exportExcelBy('interviews/export-excel', 'CandidateData', data);

        setLoading(false)
    };

    const showRecruitmentProcess = () => {
        setShowRecruitmentProcessModal(true);
    };

    const renderSchoolOptionSearch = (schools) => {
        return schools.map(school => {
            return (
                <MenuItem key={`${school}-search`} value={school}>{school}</MenuItem>
            );
        });
    }

    const renderRecruitmentPlan = (recruitmentPlans) => {
        if (!isEmpty(recruitmentPlans) && recruitmentPlans.length > 0) {
            return recruitmentPlans.map(item => {
                let title = '';
                if (!isEmpty(item.recruitment_plan_details_estimate)) {
                    let planDetailEstimateDetail = Object.entries(item.recruitment_plan_details_estimate);
                    let planDetailEstimateDetailLength = planDetailEstimateDetail.length;
                    for (let key in planDetailEstimateDetail) {
                        let item = planDetailEstimateDetail[key];
                        title += `${item[0]} - ${item[1]} người ${(parseInt(planDetailEstimateDetailLength - 1)) === parseInt(key) ? '' : '\n'}`
                    }
                }
                return (<MenuItem key={`item-serch-${item.id}`} title={title} value={item.id}>{item.division} - {item.name}</MenuItem>);
            })
        }
    }

    const renderCVTag = (cvTags) => {
        if (!isEmpty(cvTags) && cvTags.length > 0) {
            return cvTags.map(item => {
                return (<MenuItem key={`item-search-${item.id}`} value={item.id}>{item['name_vn']}</MenuItem>);
            })
        }
    }

    const getDisableWrapButtonClass = () => {
        return loading || (interviews?.data && interviews?.data.length < 1) ? interviewClasses.disableCursor : ''
    }

    const getDisableButtonClass = () => {
        return loading || (interviews?.data && interviews?.data.length < 1) ? interviewClasses.disableButton : ''
    }

    function formatDateSearch(date) {
        return date ? moment(date, 'dd/MM/yyyy').format('DD/MM/yyyy') : "";
    }

    const searchWithDateRange = (date) => {
        setDateRange(date);
        search(nameSearch, status, recruitmentPlanId, school, cvTagId, date, dateRangeInterview, dateRangeInternship);
    }

    const searchWithInterviewDateRange = (date) => {
        setDateRangeInterview(date);
        search(nameSearch, status, recruitmentPlanId, school, cvTagId, dateRange, date, dateRangeInternship);
    };

    const searchWithInternshipDateRange = (date) => {
        setDateRangeInternship(date);
        search(nameSearch, status, recruitmentPlanId, school, cvTagId, dateRange, dateRangeInterview, date);
    };
    return (
        <>
            {/* Page content */}
            <Container
                maxWidth={false}
                className={`educate ${classes.flexGrow_1}`}
                classes={{ root: classes.containerRoot }}
            >
                <div>
                    <WorkIcon style={workIconStyle} />
                    <Breadcrumbs style={breadCrumbsStyle} aria-label="breadcrumb">
                        <Link color="inherit">
                            Tuyển dụng
                        </Link>
                        <Link color="inherit" to="/candidate-manage" style={breadcrumbStyle}>
                            Quản lý ứng viên
                        </Link>
                    </Breadcrumbs>
                    <h2 style={titleStyle}>Quản lý ứng viên</h2>
                    <div className={'d-flex justify-content-between '}>
                        <div className={'d-flex flex-wrap '}>
                            <TextField id="filled-search"
                                className={interviewClasses.searchBoxWrap}
                                placeholder="Tìm kiếm..."
                                type="search"
                                variant="filled"
                                value={nameSearch}
                                onChange={e => searchWithName(e)}
                                style={inputSearchStyle}
                            />
                            <FormControl className={interviewClasses.searchBoxWrap}>
                                <Select
                                    input={<BootstrapInput />}
                                    defaultValue={' '}
                                    value={status}
                                    onChange={e => searchWithStatus(e)}

                                >
                                    <MenuItem value={' '}>Trạng thái...</MenuItem>
                                    <MenuItem value={'Chưa hẹn thời gian phỏng vấn'}>Chưa hẹn thời gian phỏng
                                        vấn</MenuItem>
                                    <MenuItem value={'Chưa có kết quả'}>Chưa có kết quả</MenuItem>
                                    <MenuItem value={'Đã có kết quả'}>Đã có kết quả</MenuItem>
                                    <MenuItem value={'Đã gửi email cảm ơn'}>Đã gửi email cảm ơn</MenuItem>
                                    <MenuItem value={'Đã hẹn ngày thực tập'}>Đã hẹn ngày thực tập</MenuItem>
                                    <MenuItem value={'Không nhận việc'}>Không nhận việc</MenuItem>
                                    <MenuItem value={'Đã nhận việc'}>Đã nhận việc</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={interviewClasses.searchBoxWrap}>
                                <Select
                                    input={<BootstrapInput />}
                                    onChange={event => searchWithRecruitmentPlan(event)}
                                    value={recruitmentPlanId}
                                    defaultValue={recruitmentPlanId}
                                >
                                    <MenuItem value={' '}>Kế hoạch tuyển dụng...</MenuItem>
                                    {
                                        renderRecruitmentPlan(recruitmentPlans)
                                    }

                                </Select>
                            </FormControl>
                            <FormControl className={interviewClasses.searchBoxWrap}>
                                <Select
                                    input={<BootstrapInput />}
                                    onChange={event => searchWithCVTag(event)}
                                    value={cvTagId}
                                    defaultValue={cvTagId}
                                >
                                    <MenuItem value={' '}>CV Tag...</MenuItem>
                                    {renderCVTag(cvTags)}
                                </Select>
                            </FormControl>
                            <FormControl className={interviewClasses.searchBoxWrap}>
                                <Select
                                    input={<BootstrapInput />}
                                    defaultValue={' '}
                                    value={school}
                                    onChange={e => searchWithSchool(e)}
                                >
                                    <MenuItem value={' '}>Trường học...</MenuItem>
                                    {
                                        renderSchoolOptionSearch(schoolsInterview)
                                    }
                                </Select>
                            </FormControl>
                            <FormControl className={`search-date-range ${interviewClasses.searchBoxWrap}`}>
                                <DatePicker
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Thời gian khởi tạo..."
                                    monthsShown={2}
                                    showNextMonths
                                    onChange={(update) => searchWithDateRange(update)}
                                    isClearable={true}
                                    className={`interview-search-picker-input`}
                                />
                            </FormControl>

                            <FormControl className={`search-date-range ${interviewClasses.searchBoxWrap}`}>
                                <DatePicker
                                    selectsRange={true}
                                    startDate={startInterviewDate}
                                    endDate={endInterviewDate}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Thời gian phỏng vấn..."
                                    monthsShown={2}
                                    showNextMonths
                                    onChange={(update) => searchWithInterviewDateRange(update)}
                                    isClearable={true}
                                    className={`interview-search-picker-input`}
                                />
                            </FormControl>

                            <FormControl className={`search-date-range ${interviewClasses.searchBoxDate} ${interviewClasses.searchBoxWrap}`}>
                                <DatePicker
                                    selectsRange={true}
                                    startDate={startDateInternship}
                                    endDate={endDateInternship}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Ngày hẹn thực tập..."
                                    monthsShown={2}
                                    showNextMonths
                                    onChange={(update) => searchWithInternshipDateRange(update)}
                                    isClearable={true}
                                    className={`interview-search-picker-input`}
                                />
                            </FormControl>

                            <FormControl className={interviewClasses.searchBoxWrap}>
                                <Select
                                    input={<BootstrapInput />}
                                    defaultValue={' '}
                                    value={position}
                                    onChange={e => searchWithPosition(e)}
                                >
                                    <MenuItem value={' '}>Vị trí...</MenuItem>
                                    {
                                        positions.map((item, key) => {
                                            return (<MenuItem key={key} value={item}>{item}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>

                        <div className={interviewClasses.btnActionWrap}>
                            <div className={`${getDisableWrapButtonClass()}`}
                                style={{ maxHeight: '50px', width: '120px' }}
                            >
                                <Button className={`${interviewClasses.btnExcel} ${getDisableButtonClass()}`}
                                    disabled={loading}
                                    title={'Export excel'}
                                    onClick={() => exportExcel()}>
                                    <span style={{ color: '#ffffff' }}>
                                        <CloudDownloadIcon style={addIconStyle} />
                                        {loading ? 'Exporting...' : 'Excel'}
                                    </span>
                                </Button>
                            </div>

                            {
                                authorize.hasPermission('create-interview')
                                    ?
                                    (<Button
                                        style={{ width: '172px' }}
                                        className={interviewClasses.btnAction}
                                        onClick={addRecruitmentManageModal}>
                                        <span style={{ color: '#ffffff' }}>
                                            <AddIcon style={addIconStyle} />
                                            Thêm ứng viên
                                        </span>
                                    </Button>)
                                    :
                                    ''
                            }
                        </div>
                        <Button onClick={showRecruitmentProcess}
                            style={buttonRecruitmentProcess}>
                            Quy trình tuyển dụng <HelpIcon style={helpIconStyle} />
                        </Button>
                    </div>

                    <RecruitmentProcess setShowRecruitmentProcessModal={setShowRecruitmentProcessModal}
                        showRecruitmentProcessModal={showRecruitmentProcessModal} />
                    <InterviewList nameProp={nameSearch}
                        recruitmentPlanId={recruitmentPlanId}
                        schoolSearch={school}
                        cvTagSearch={cvTagId}
                        startDateSearch={formatDateSearch(dateRange[0])}
                        endDateSearch={formatDateSearch(dateRange[1])}
                        startInterviewDate={formatDateSearch(dateRangeInterview[0])}
                        endInterviewDate={formatDateSearch(dateRangeInterview[1])}
                        startInternshipDate={formatDateSearch(dateRangeInternship[0])}
                        endInternshipDate={formatDateSearch(dateRangeInternship[1])}
                        status={status} />
                    <AddInterviewModal
                        setShowAddRecruitmentManageModal={setShowAddRecruitmentManageModal}
                        showAddRecruitmentManageModal={showAddRecruitmentManageModal} />
                </div>
            </Container>
        </>
    );
}

export default Interview;
