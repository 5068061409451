import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_LMS_URL,
});
instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common['Accept'] = 'application/json';

instance.interceptors.request.use(config => {
        return config;
    },
    ({response}) => {
        return response;
    },
);

instance.interceptors.response.use((response) => {
    return response;
}, async function(error) {
    return Promise.reject(error);

});

export default instance;
