import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from '../../configs/axios';
import {setParamQueryString} from '../../services/queryStringService';

const initialState = {
  dataAnalysis: [],
}

export const fetchDataAnalysis = createAsyncThunk('dashboard/fetchDataAnalysis',
    async (data = {}, {rejectWithValue}) => {
      try {
        let paramString = setParamQueryString(data);
        return await axios.get(`/dashboard/analysis${paramString}`);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);


const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
  },
  extraReducers: {

    [fetchDataAnalysis.pending]: (state)=>{
      state.status = 'loading'
    },

    [fetchDataAnalysis.fulfilled]: (state, action)=>{
      state.dataAnalysis = action?.payload?.data?.data;
    },
  }
});

export const getDashboardAnalysis = state => state.dashboard.dataAnalysis;

export default dashboardSlice.reducer;
