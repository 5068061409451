class AuthorizeService {
  constructor(user) {
    this.user = user;
    this.roles = user?.roles ?? [];
    this.permissions = user?.permissions ?? [];
  }

  isAuthorize() {
    return +(Object.keys(this.user).length) > 0;
  }

  isSupperAdmin() {
    let supperAdmin = 'super-admin';
    return this.roles.length > 0 && this.roles.filter(a => a.name === supperAdmin).length > 0;
  }
  isGuest() {
    let guest = 'guest';
    return (this.roles.length === 0
        ||(this.roles.length === 1 && this.roles?.[0]?.name === guest))
        && !this.isSupperAdmin();
  }

  hasRole(role = null) {
    if (this.isSupperAdmin()) {
      return true;
    } else if (role !== '' && role !== 'undefined' && role !== null) {
      return this.roles.filter(a => {
        return a.name === role}).length > 0;
    }
    return true;
  }

  hasPermission(permission = null) {
    if (this.isSupperAdmin()) {
      return true;
    } else if (permission !== '' && permission !== 'undefined' && permission !==
        null) {
      return this.permissions.filter(a => {
        return a == permission
      }).length > 0;
    }
    return true;
  }

  hasRolePermission(role = null, permission = null) {
    return this.hasRole(role) && this.hasPermission(permission);
  }

}

export default AuthorizeService;