import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from '../../configs/axios';
import {setParamQueryString} from '../../services/queryStringService';
import _, {isEmpty} from 'lodash';

const initialState = {
  recruitment_plans: [],
  recruitment_plan: {},
  status: '',
  errors: {},
  recruitment_plan_show: {},
  recruitment_plan_show_id: null,
  recruitment_request_for_create_id: null,

};

export const fetchRecruitmentPlan = createAsyncThunk(
    'recruitmentPlan/fetchRecruitmentPlan',
    async (data = {}, {rejectWithValue}) => {
      try {
        let dataCondition = data;
        if (isEmpty(data)) {
          dataCondition = {page: 1};
        }
        let paramString = setParamQueryString(dataCondition);
        return await axios.get(`/recruitment-plan${paramString}`);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);

export const createRecruitmentPlan = createAsyncThunk(
    'recruitmentPlan/createRecruitmentPlan',
    async (data = {}, {rejectWithValue}) => {
      try {
        return await axios.post(`/recruitment-plan/store`, data);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);

export const updateRecruitmentPlan = createAsyncThunk(
    'recruitmentPlan/updateRecruitmentPlan',
    async (data = {}, {rejectWithValue}) => {
      try {
        return await axios.post(`/recruitment-plan/update/${data.id}`, data);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);

export const approveRecruitmentPlan = createAsyncThunk(
    'recruitmentPlan/approveRecruitmentPlan',
    async (data = {}, {rejectWithValue}) => {
      try {
        return await axios.post(`/recruitment-plan/approved-plan/${data.id}`,
            data);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);

export const rejectRecruitmentPlan = createAsyncThunk(
    'recruitmentPlan/rejectRecruitmentPlan',
    async (data = {}, {rejectWithValue}) => {
      try {
        return await axios.post(`/recruitment-plan/reject-plan/${data.id}`,
            data);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);

export const getRecruitmentPlanShow = createAsyncThunk(
    'recruitmentPlan/getRecruitmentPlanShow',
    async (data = {}, {rejectWithValue}) => {
      try {
        return await axios.get(`/recruitment-plan/${data.id}/show`);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);

const recruitmentPlanSlice = createSlice({
  name: 'recruitmentPlan',
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.errors = {};
    },
    resetRecruitmentPlanShow: (state) => {
      state.recruitment_plan_show = {};
    },
    resetRecruitmentPlanShowID: (state) => {
      state.recruitment_plan_show_id = null;
    },

    setRecruitmentPlanShowID: (state, action) => {
      state.recruitment_plan_show_id = action.payload;
    },

    setRecruitmentRequestIdForCreate: (state, action) => {
      state.recruitment_request_for_create_id = action.payload;
    },
    resetRecruitmentRequestIdForCreate: (state) => {
      state.recruitment_request_for_create_id = null;
    },
  },
  extraReducers: {

    [fetchRecruitmentPlan.pending]: (state) => {
      state.status = 'loading';
    },

    [fetchRecruitmentPlan.fulfilled]: (state, action) => {
      state.recruitment_plans = action?.payload?.data?.data;
    },

    [createRecruitmentPlan.pending]: (state) => {
      state.status = 'loading';
    },

    [createRecruitmentPlan.fulfilled]: (state, action) => {
      state.status = 'success';

      state.recruitment_plans = action?.payload?.data?.data;
    },

    [createRecruitmentPlan.rejected]: (state, action) => {
      let status = parseInt(action.payload?.status);
      if (status === 422) {
        state.errors = action.payload.data.errors;
      }
    },

    [updateRecruitmentPlan.pending]: (state) => {
      state.status = 'loading';
    },

    [updateRecruitmentPlan.fulfilled]: (state, action) => {
      let recruitmentUpdate = action?.payload?.data?.data;
      let index = _.findIndex(state.recruitment_plans.data, (item) => {
        return item.id == recruitmentUpdate.id;
      });
      if (index > -1) {
        let recruitmentPlans = state.recruitment_plans;
        recruitmentPlans.data[index] = recruitmentUpdate;
        state.recruitment_plans = recruitmentPlans;
      }
    },

    [updateRecruitmentPlan.rejected]: (state, action) => {
      state.errors = action.payload.data.errors;
    },

    [getRecruitmentPlanShow.pending]: (state) => {
      state.status = 'loading';
    },

    [getRecruitmentPlanShow.fulfilled]: (state, action) => {
      let recruitment = action?.payload?.data?.data;
      if (!isEmpty(recruitment)) {
        state.recruitment_plan_show = recruitment;
      }
    },

    [approveRecruitmentPlan.pending]: (state) => {
      state.status = 'loading';
    },

    [approveRecruitmentPlan.fulfilled]: (state, action) => {
      let recruitmentUpdate = action?.payload?.data?.data;
      let index = _.findIndex(state.recruitment_plans.data, (item) => {
        return item.id == recruitmentUpdate.id;
      });
      if (index > -1) {
        let recruitmentPlans = state.recruitment_plans;
        recruitmentPlans.data[index] = recruitmentUpdate;
        state.recruitment_plans = recruitmentPlans;
      }
    },

    [rejectRecruitmentPlan.pending]: (state) => {
      state.status = 'loading';
    },

    [rejectRecruitmentPlan.fulfilled]: (state, action) => {
      let recruitmentUpdate = action?.payload?.data?.data;
      let index = _.findIndex(state.recruitment_plans.data, (item) => {
        return item.id == recruitmentUpdate.id;
      });
      if (index > -1) {
        let recruitmentPlans = state.recruitment_plans;
        recruitmentPlans.data[index] = recruitmentUpdate;
        state.recruitment_plans = recruitmentPlans;
      }
    },

  },
});

export const getRecruitmentPlans = state => state.recruitmentPlan.recruitment_plans;
export const getRecruitmentPlanErrors = state => state.recruitmentPlan?.errors;
export const getRecruitmentPlan = state => state.recruitmentPlan?.recruitment_plan_show;
export const getRecruitmentPlanShowID = state => state.recruitmentPlan?.recruitment_plan_show_id;
export const getRecruitmentRequestIDForCreate = state => state.recruitmentPlan?.recruitment_request_for_create_id;
export const {
  resetErrors,
  resetRecruitmentPlanShow,
  setRecruitmentPlanShowID,
  resetRecruitmentPlanShowID,
  setRecruitmentRequestIdForCreate,
  resetRecruitmentRequestIdForCreate,
} = recruitmentPlanSlice.actions;
export default recruitmentPlanSlice.reducer;
