import React, {useEffect} from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import componentStyles from "assets/theme/views/admin/dashboard.js";

const useStyles = makeStyles(componentStyles);

function NotFound() {
  const classes = useStyles();
  useEffect(()=>{
    document.title = "Không tìm thấy trang này."
  },[])
  return (
      <>
        {/* Page content */}
        <Container
            maxWidth={false}
            classes={{ root: classes.containerRoot }}
            className={classes.flexGrow_1}
        >
          <div className={classes.notfoundContainer}>
              <h1>Không tìm thấy trang này.</h1>
          </div>
        </Container>
      </>
  );
}

export default NotFound;
