import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from '../../configs/axiosLms';

const initialState = {
  courses: [],
    loading:false,
};

export const fetchCoursesLmsRequest = createAsyncThunk('courseLms/getCourse',
    async (userId, {rejectWithValue}) => {
      try {
        return await axios.get(`/api/courses/intern-course-for-position/${userId}`);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);

const courseLmsReduce = createSlice({
  name: 'courseLms',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCoursesLmsRequest.pending]: (state) => {
      state.loading = true;
    },

    [fetchCoursesLmsRequest.fulfilled]: (state, action) => {
      state.courses = action?.payload?.data?.data;
        state.loading = false;
    },

      [fetchCoursesLmsRequest.rejected]: (state) => {
          state.loading = false;
      },

  },
});

export const getCoursesLms = state => state.courseLms.courses;
export const getLoading = state => state.courseLms.loading;
export default courseLmsReduce.reducer;
