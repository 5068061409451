import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  makeStyles,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditInformation from '../../../../components/Interview/EditInformation';
import moment from 'moment';
import EditPoint from '../../../../components/Interview/EditPoint';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {CalendarToday} from '@material-ui/icons';
import ShowInformation from '../../../../components/Interview/ShowInformation';
import {useAuth} from '../../../../contexts/AuthContext';
import ShowPoint from '../../../../components/Interview/ShowPoint';
import '../../../../assets/css/custom/interview.css';
import {isEmpty as objectIsEmpty} from 'lodash';
import {
  getInterviewErrors,
  resetErrors,
  updateInterview,
} from '../../../../features/processes/interviewSlice';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {useDispatch, useSelector} from 'react-redux';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  hrStyle: {
    marginTop: 'none',
    marginBottom: 'none',
    width: '16rem',
    position: 'absolute',
    top: '0',
    borderTop: '5px solid orange',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '15.5rem',
  },
  validate: {
    color: 'red',
  },
  buttonAdd: {
    backgroundColor: '#2DCE89',
    color: 'white',
    fontSize: '12px',
    marginTop: '0.3rem',
    '&&:hover': {
      backgroundColor: '#2DCE89',
      color: 'white',
      fontSize: '12px',
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1000,
    color: '#525f7f',
    right: '7px',
    top: '4px',
  },
  divButtonAddStyle: {
    marginTop: '0.9rem',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  closeIconStyle: {
    width: '24px',
    height: '24px',
  },
  marginTitleRecruitment: {
    marginBottom: '0.4rem',
    fontSize: '1.2rem',
  },
  inputStyle: {
    border: '1px solid #ced4da',
    borderRadius: 4,
    width: '18.75rem',
  },
  selectStyle: {
    width: '15rem',
  },

  selectInternshipDate:{
    width: '15rem',

  }
}));

export default function UpdateInterviewModal(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [linkCv, setLinkCv] = useState('');
  const [status, setStatus] = useState(' ');
  const [isCome, setIsCome] = useState(' ');
  const [technicalScore, setTechnicalScore] = useState(50);
  const [softskillScore, setSoftskillScore] = useState(50);
  const [comment, setComment] = useState('');
  const [note, setNote] = useState('');
  const [isPass, setIsPass] = useState('Pass');
  const [school, setSchool] = useState(' ');
  const [position, setPosition] = useState(' ');
  const [recruitmentPlanId, setRecruitmentPlanId] = useState(' ');
  // const dateEndDefault = moment().format('YYYY-MM-DD H:mm');
  const [interviewDate, setInterviewDate] = useState(null);
  const dateOfEmploymentDefault = moment().format('YYYY-MM-DD');
  const [recruitmentPlanName, setRecruitmentPlanName] = useState(' ');
  const [internshipDate, setInternshipDate] = useState(dateOfEmploymentDefault);
  const [cvTag, setCVTag] = useState([]);
  const {authorize} = useAuth();
  const errors = useSelector(getInterviewErrors);

  const dispatch = useDispatch();
  useEffect(() => {
    if(!objectIsEmpty(props.interviewUpdate))
    {
      let interview = props.interviewUpdate;
      setName(interview.name)
      setIsCome(interview.is_come_interview ?? ' ')
      setTechnicalScore(interview.technical_score)
      setSoftskillScore(interview.softskill_score)
      setIsPass(interview.is_pass ?? ' ')
      setSchool(interview.school ?? ' ')
      setPosition(interview.position ?? ' ')
      setRecruitmentPlanId(interview.recruitment_plan_id ?? ' ')
      setRecruitmentPlanName(interview.recruitment_plan_name)
      let interviewDateFormat = interview.interview_date ? moment(interview.interview_date, "DD-MM-YYYY H:mm").format('YYYY-MM-DD H:mm') : null;
      setInterviewDate(interviewDateFormat)
      setComment(interview.comment )
      setPhone(interview.phone)
      setLinkCv(interview.cv_link)
      setEmail(interview.email)
      setStatus(interview.status)
      setNote(interview.note)
      let cvTags = interview.cv_tags;
      let cvTagsResult = {};
      if (cvTags.length > 0) {
        [].forEach.call(cvTags, function(item) {
          let obj = {};
          obj[`${item.id}`] = true;
          cvTagsResult = {...cvTagsResult, ...obj};
        });
      }
      setCVTag(cvTagsResult);

      if (authorize.hasRole('hr') && interview.status === "Đã có kết quả")
      {
        let intershipDateFonat = interview.internship_date
        ?moment(interview.interview_date, "DD-MM-YY").format('YYYY-MM-DD')
            :dateOfEmploymentDefault

        setInternshipDate(intershipDateFonat)
      }
    }

  }, [props.interviewUpdate]);


  useEffect(()=>{
    if (!props.showUpdateModal)
    {
      props.setInterviewUpdate({});
    }
  },[props.showUpdateModal])


  const handleClose = (event) => {
    event.preventDefault();
    props.setShowUpdateModal(false);
  };

  const formatInterviewDate = () => {
    return interviewDate ? moment(interviewDate, 'YYYY-MM-DD H:mm').format('YYYY-MM-DD H:mm') : null;
  };

  function getDataForUpdate()
  {
    let dataUpdateHr = {};
    let dataUpdateScore = {}
    let dataUpdate = {
      status:status,
      note:note,
      id: props.interviewUpdate?.id
    }

    if(authorize.hasRole('hr'))
    {
      let cvTagData = [];
      for (let tag in cvTag)
      {
        if (cvTag?.[`${tag}`] === true)
        {
          cvTagData.push(tag)
        }
      }
      let internshipDateFormat = status === "Đã hẹn ngày thực tập"
          ? moment(internshipDate, 'YYYY-MM-DD')
              .format('YYYY-MM-DD')
          : null
      dataUpdateHr = {
        name,
        email,
        phone,
        school: school?.value ?? "",
        position: position?.value ?? "",
        interview_date: formatInterviewDate(),
        cv_link: linkCv,
        recruitment_plan_id: recruitmentPlanId === " " ? null : recruitmentPlanId,
        cv_tags: cvTagData,
      };

      if(status === "Đã hẹn ngày thực tập")
      {
        dataUpdateHr.internship_date = internshipDateFormat;
      }
    }

    if(authorize.hasRole('training-management') || authorize.hasRole('quality-control'))
    {
      dataUpdateScore = {
        is_come_interview: isCome === " " ? null : isCome,
        technical_score: technicalScore,
        softskill_score: softskillScore,
        is_pass: isPass === " " ? null : isPass,
        comment: comment,
      }
    }
    return {...dataUpdate,...dataUpdateHr,...dataUpdateScore};
  }

  const handleUpdateInterview = async () => {

    let data = getDataForUpdate();
    let response = await dispatch(updateInterview(data))
    setLoading(true);

    if(response?.payload?.status === 200)
    {
      props.setShowUpdateModal(false)
      NotificationManager.success('Thành công!', '', 1500);
    }
    setLoading(false);

  };

  useEffect(() => {
    return () => {
      dispatch(resetErrors())
    };
  }, [props.showUpdateModal]);


  function isEditIntershipDate() {
    return isCome !== " "
        && isPass !== " "
        && !objectIsEmpty(technicalScore)
        && !objectIsEmpty(softskillScore);
  }

  return (
      <div>
        <Dialog
            fullScreen={fullScreen}
            open={props.showUpdateModal}
            onClose={handleClose}
            maxWidth={'lg'}
            style={{width: '46rem'}}
            className={'m-auto updateInterviewModal'}
            aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <IconButton onClick={handleClose} size={'medium'}
                        className={classes.closeButton}>
              <CloseIcon fontSize={'large'}
                         className={classes.closeIconStyle}/>
            </IconButton>
            <h2>Thông tin ứng viên</h2>
            <Grid className={'pb-2'}>
              {authorize.hasRole('hr')

              ?(<EditInformation
                      name={name}
                      setName={setName}
                      phone={phone}
                      setPhone={setPhone}
                      email={email}
                      setEmail={setEmail}
                      linkCv={linkCv}
                      school={school}
                      setSchool={setSchool}
                      setLinkCv={setLinkCv}
                      recruitmentPlanId={recruitmentPlanId}
                      setRecruitmentPlanId={setRecruitmentPlanId}
                      interviewDate={interviewDate}
                      setInterviewDate={setInterviewDate}
                      cvTag={cvTag}
                      setCVTag={setCVTag}
                      position={position}
                      setPosition={setPosition}
                  />)
              :(<ShowInformation
                      name={name}
                      phone={phone}
                      email={email}
                      linkCv={linkCv}
                      school={school}
                      recruitmentPlanName={recruitmentPlanName}
                      recruitmentPlanId={recruitmentPlanId}
                      interviewDate={interviewDate}
                      cvTag={cvTag}
                      position={position}
                  />)
              }

            </Grid>
            <Grid className={'pb-2'}>

              {authorize.hasRole('training-management') || authorize.hasRole('quality-control')

              ?(
                      <EditPoint
                          isCome={isCome}
                          setIsCome={setIsCome}
                          technicalScore={technicalScore}
                          setTechnicalScore={setTechnicalScore}
                          softskillScore={softskillScore}
                          setSoftskillScore={setSoftskillScore}
                          comment={comment}
                          setComment={setComment}
                          isPass={isPass}
                          setIsPass={setIsPass}
                      />
                  )
              :(
                      <ShowPoint
                          isCome={isCome}
                          technicalScore={technicalScore}
                          softskillScore={softskillScore}
                          comment={comment}
                          isPass={isPass}
                      />
                  )}

            </Grid>
            <Grid >
              <h3 className={'position-relative'}>Trạng thái
                <hr className={classes.hrStyle}/>
              </h3>

              <Grid className={'d-flex flex-row justify-content-between pt-2'}>
                <Grid>
                  <p className={'mb-0'}>Cập nhật trạng thái
                  </p>
                  <FormControl className={classes.selectStyle}>
                    <Select
                        input={<BootstrapInput/>}
                        defaultValue={' '}
                        value={status}
                        onChange={e => setStatus(e.target.value)}
                    >
                      <MenuItem value={' '}>Trạng thái...</MenuItem>
                      <MenuItem value={'Chưa hẹn thời gian phỏng vấn'}>Chưa hẹn thời gian phỏng vấn</MenuItem>
                      <MenuItem value={'Chưa có kết quả'}>Chưa có kết quả</MenuItem>
                      <MenuItem disabled={!isEditIntershipDate()} value={'Đã có kết quả'}>Đã có kết quả</MenuItem>
                      <MenuItem value={'Đã gửi email cảm ơn'}>Đã gửi email cảm ơn</MenuItem>
                      <MenuItem value={'Đã hẹn ngày thực tập'}>Đã hẹn ngày thực tập</MenuItem>
                      <MenuItem value={'Không nhận việc'}>Không nhận việc</MenuItem>
                      <MenuItem value={'Đã nhận việc'}>Đã nhận việc</MenuItem>

                    </Select>
                  </FormControl>
                </Grid>

                <Grid>
                  <p className={'mb-0'}>Lưu ý (nếu có)
                  </p>
                  <TextField type="text"
                             placeholder={'Nhập lưu ý nếu có...'}
                             variant="filled"
                             value={note}
                             onChange={event => setNote(event.target.value)}
                             className={classes.inputStyle}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid className={'d-flex pt-2'}>
              <Grid className={'w-50'}>
                {authorize.hasRole('hr') && status === "Đã hẹn ngày thực tập"
                    ?
                    (<>  <p className={'mb-0'}>Ngày nhận thực tập</p>
                      <form className={classes.container} noValidate>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            noValidate
                        >
                          <DatePicker
                              value={internshipDate}
                              fullWidth
                              onChange={event => setInternshipDate(event)}
                              cancelLabel={'CANCEL'}
                              format="dd-MM-yyyy"
                              className={`date-picker-custom ${classes.selectInternshipDate}`}
                              InputProps={{
                                endAdornment: (
                                    <IconButton>
                                      <CalendarToday/>
                                    </IconButton>
                                ),
                              }}
                          />
                        </MuiPickersUtilsProvider>
                      </form>
                      <span className={'text-danger errors-internship_date errors'}>{errors?.internship_date?.[0]}</span>

                    </>)

                    : ''}

              </Grid>
              <Grid className={'w-50  d-flex justify-content-end align-items-end'}>
                <Button onClick={() => handleUpdateInterview()} disabled={loading}
                        className={classes.buttonAdd} align={'right'}>
                  <span>{loading ? 'Đang xử lý' : 'Cập nhật'}</span>
                </Button>
              </Grid>
            </Grid>
          </DialogContent>

        </Dialog>
        <NotificationContainer/>
      </div>
  );
}

UpdateInterviewModal.propTypes = {
  setShowUpdateModal: PropTypes.func,
  setInterviewUpdate: PropTypes.func,
  showUpdateModal: PropTypes.bool,
  interviewUpdate: PropTypes.object,
};

