import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import '../../../../../assets/css/process/process-detail.css';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Grid,
    makeStyles,
    Table, TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import {TableCell} from 'semantic-ui-react';
import ProcessDetailStepper from './ProcessDetailStepper';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useAuth} from '../../../../../contexts/AuthContext';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from 'react-redux';
import {
    approveRecruitmentPlan,
    getRecruitmentPlan,
    resetRecruitmentPlanShow, resetRecruitmentPlanShowID,
} from '../../../../../features/processes/recruitmentPlanSlice';
import {isEmpty} from 'lodash';
import RejectRecruitmentPlanModal from './RejectRecruitmentPlanModal';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    closeModalIcon: {
        width: '24px',
        height: '24px',
    },
    expandMoreIcon: {
        color: "white"
    },
    buttonShowProcess: {
        backgroundColor: '#00BCD4',
        color: 'white',
        marginTop: "0.35rem",
        borderRadius: "3px",
        width: "7rem",
        fontSize: '16px',
        '&&:hover': {
            backgroundColor: '#03a6bb',
            color: 'white',
            fontSize: '16px',
        },
    },
    titleProcess: {
        marginLeft: "0.5rem"
    },
    closeButton: {
        position: 'absolute',
        zIndex: 1000,
        color: '#525f7f',
        right: '7px',
        top: '4px',
    },
    w_50: {
        width: '50%',
    },
    border_td_1: {
        borderRight: '1px solid #e9ecef !important',
    },
    p_05: {
        padding: '0.5rem',
    },
    boxshadow_0: {
        boxShadow: 'none',
    },
}));

export default function RecruitmentPlanDetailModal(props) {
    const theme = useTheme();
    const classes = useStyles();
    const [showProcess, setShowProcess] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rejectRecruitmentPlanId, setRejectRecruitmentPlanId] = useState();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const recruitmentPlanShow = useSelector(getRecruitmentPlan);
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(resetRecruitmentPlanShow());
            setShowProcess(false)
        };
    }, []);

    useEffect(() => {

            if(!props.showRecruitmentPlanDetailModal)
            {
                dispatch(resetRecruitmentPlanShowID())
                setShowProcess(false)
            }
    }, [props.showRecruitmentPlanDetailModal]);

    const {authorize} = useAuth();
    const handleClose = (event) => {
        event.preventDefault();
        props.setShowRecruitmentPlanDetailModal(false);
    };


    const handleShowProcess = (panel) => (event, isExpanded) => {
        setShowProcess(isExpanded ? panel : false);
    };
    const closeModalRecruitmentPlan = () => {
        props.setShowRecruitmentPlanDetailModal(false);
    };

    const renderDetail = (recruitmentPlanShow) => {
        if(!isEmpty(recruitmentPlanShow) && recruitmentPlanShow.details.length > 0)
        {
            let details = recruitmentPlanShow.details;
            return details.map((item, index)=>{
                return (
                    <TableRow key={`plan-detail-show-${index}`}>
                        <TableCell align={'center'}
                                   className={`${classes.border_td_1}  ${classes.p_05}`}>
                            {item.type}
                        </TableCell>
                        <TableCell align={'center'}
                                   className={`${classes.border_td_1}  ${classes.p_05}`}>
                            {item.estimate_quantity}
                        </TableCell>
                        <TableCell align={'center'}
                                   className={`${classes.border_td_1}  ${classes.p_05}`}>
                            {item.quantity}
                        </TableCell>
                    </TableRow>
                )
            });
        }

    };

    const handleReject = (id) => {
         setShowRejectModal(true)
        setRejectRecruitmentPlanId(id)
    }

    const handleApprove = async (id) => {

        setLoading(true)
        await dispatch(approveRecruitmentPlan({id}))
        setLoading(false)
        NotificationManager.success('Thành công!', '', 1500);
        props.setShowRecruitmentPlanDetailModal(false);

    }

    const isShowConfirmButton = (recruitmentPlan) => {
       return  authorize.hasRole('hr') && recruitmentPlan?.status === "Đang chờ"
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.showRecruitmentPlanDetailModal}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                        <IconButton onClick={handleClose} size={'medium'}
                                    className={classes.closeButton}>
                            <CloseIcon fontSize={'large'} className={classes.closeModalIcon}/>
                        </IconButton>
                        <h2>Kế hoạch tuyển dụng</h2>
                        <p className={'mb-0'}> Từ nhu cầu nhân sự: {recruitmentPlanShow?.recruitment_request?.full_name}</p>
                        <p className={'mb-0'}> Tên kế hoạch tuyển dụng: {`${recruitmentPlanShow.division} - ${recruitmentPlanShow.name}`}</p>
                        <TableContainer className={'d-flex justify-content-center'}>
                            <Table aria-label="table"
                                   style={{marginTop: "0.8rem"}}
                                   className={`table table-hover table-bordered ${classes.w_50}`}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align={'center'}
                                                   className={`${classes.border_td_1}  ${classes.p_05}`}>
                                            Công nghệ
                                        </TableCell>
                                        <TableCell align={'center'}
                                                   className={`${classes.border_td_1}  ${classes.p_05}`}>
                                            Số lượng nhân sự cần tuyển
                                        </TableCell>
                                        <TableCell align={'center'}
                                                   className={`${classes.border_td_1}  ${classes.p_05}`}>
                                            Số lượng nhân sự đầu ra
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        renderDetail(recruitmentPlanShow)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <p className={'mb-0'}>Thời hạn tuyển dụng: {recruitmentPlanShow?.date_recruitment_end}</p>
                        <p className={'mb-0'}>Thời hạn bàn giao: {recruitmentPlanShow?.date_end}</p>
                        <p className={'mb-0'}>Trạng thái: {recruitmentPlanShow?.status}</p>
                        {
                            isShowConfirmButton(recruitmentPlanShow)
                                ? (<Grid className={'pt-1 d-flex justify-content-between'}>
                                    <Button variant="contained"
                                            onClick={()=>handleReject(recruitmentPlanShow?.id)}
                                            style={{backgroundColor: "#ea4846", border: 'none'}}>
                                        Từ chối
                                    </Button>
                                    <Button
                                        disabled={loading}
                                        onClick={()=>handleApprove(recruitmentPlanShow?.id)}
                                        variant="contained" style={{backgroundColor: "#539a04", border: 'none'}}>
                                        {loading?'Đang xử lý ' : 'Phê duyệt'}
                                    </Button>
                                </Grid>)
                                : (<Grid>
                                    <Accordion expanded={showProcess}
                                               className={`${classes.boxshadow_0} process-details`}
                                               onChange={handleShowProcess('panel1')}
                                    >
                                        <Button className={classes.buttonShowProcess}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
                                                className={`${classes.boxshadow_0} height_one p-0 d-flex justify-content-start`}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <span className={classes.titleProcess}>Quy trình</span>
                                            </AccordionSummary>
                                        </Button>
                                        <AccordionDetails>
                                            <ProcessDetailStepper
                                                closeModalRecruitmentPlan={closeModalRecruitmentPlan}/>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>)
                        }
                    </DialogContent>
            </Dialog>
            <RejectRecruitmentPlanModal
            rejectRecruitmentPlanId={rejectRecruitmentPlanId}
            showRejectModal={showRejectModal}
            setShowRejectModal={setShowRejectModal}
            closeModalShowRecruitmentPlan={closeModalRecruitmentPlan}
            />
            <NotificationContainer />

        </div>
    );
}

RecruitmentPlanDetailModal.propTypes = {
    setShowRecruitmentPlanDetailModal: PropTypes.func,
    showRecruitmentPlanDetailModal: PropTypes.bool,
};
