import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {green} from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import {useDispatch} from 'react-redux';
import {updateRoleUser} from '../../../features/user/userSlice';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: '#525F7F',
    paddingRight:"0 !important",
  },
  buttonSave: {
    backgroundColor: '#2DCE89',
    color: 'white',
    fontSize: '12px',
    '&&:hover': {
      backgroundColor: '#2DCE89',
      color: 'white',
      fontSize: '12px',
    },
  },
  informationUserStyle: {
    marginLeft: '1rem',
    color: '#525F7F',
  },
  lineHeight: {
    marginTop: '-0.72rem',
  },
  listCheckboxStyle: {
  },
}));
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function InternScoreModal(props) {
  const user = props.userEdit;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const roles = props.roles;

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [userRoles, setUserRoles] = React.useState({});
  const [error, setError] = React.useState('');
  useEffect(() => {
    let rs = roles.filter(a => a.name != 'guest');
    let result = {};
    if (rs && rs.length > 0) {
      rs.forEach(item => {
        let roleUser = user?.roles;
        let isCheck = roleUser && roleUser.length > 0 ?
            roleUser.filter(a => a.name == item.name).length > 0 :
            false;
        result[`${item.name}`] = isCheck;
      });
      setUserRoles(result);
    }
  }, [user]);
  const handleClose = (event) => {
    event.preventDefault();
    props.setShowEditModal(false);
  };

  useEffect(() => {
    setError('');
  }, [props.showEditModal]);

  const labelStyle = {
    fontSize: '1rem',
    color: '#525F7F',
    fontWeight: 'lighter',
  };


  const widthUpdateRoleModal = {
    width: '29rem',
  };

  const handleChange = (event) => {
    setUserRoles({...userRoles, [event.target.name]: event.target.checked});
  };
  const renderRole = (roles) => {
    let result = '';
    if (roles && roles.length > 0) {
      result = roles.filter(item => item.name != 'guest').map((item, index) => {

        return (<FormControlLabel
            key={`${item.id}${index}`}
            className={'m-0 d-flex'}
            value={item.name}
            control={
              <GreenCheckbox
                  style={{padding:"3px"}}
                  checked={userRoles?.[`${item.name}`]}
                  onChange={handleChange}
                  value={item.name}
                  name={item.name}/>
            }
            label={
              <Typography style={labelStyle}>{item.display_name}</Typography>
            }
        />);
      });
    }
    return result;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let rolesData = [];
    for (let role in userRoles)
    {
      if (userRoles?.[`${role}`] == true)
      {
          rolesData.push(role)
      }
    }
    let data = {
      user_id: user.id,
      roles: rolesData,
    };
    if (rolesData.length < 1)
    {
      setError('Chưa chọn vai trò.')
    }else {
      setError('')
      setLoading(true);
      await dispatch(updateRoleUser(data));
      setLoading(false);
      NotificationManager.success("Thành công!","",1500);
      props.setShowEditModal(false);
    }
  };

  return (
      <div>
        <Dialog
            fullScreen={fullScreen}
            className={'m-auto'}
            style={widthUpdateRoleModal}
            open={props.showEditModal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <h2>Cập nhật thông tin</h2>
              <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
              >
                <div className={classes.informationUserStyle}>
                  <p>Họ tên: {user.name}</p>
                  <p className={classes.lineHeight}>Email: {user.email}</p>
                  <p className={`${classes.lineHeight} mb-1`}>Trạng thái tài khoản:
                    {user?.roles && user.roles.length > 0 && user.roles.filter(a=>a.name!='guest').length >0
                      ? ' Đã xác nhận ' :' Đang chờ duyệt'
                    }
                     </p>
                </div>
                  <Grid className={'d-flex'} >
                    <Grid item xs={3} className={'pr-0'} style={{width:"100px"}}>
                      <p >Vai trò:</p>
                    </Grid>
                    <Grid item  className={`${classes.listCheckboxStyle} w-100`}>
                      {renderRole(roles)}
                    <p className={'text-danger'}>{error}</p>
                    </Grid>
                  </Grid>
              </Grid>
              <div className={'d-flex justify-content-end'} >
                <Button
                    disabled={loading}
                    type={'submit'}
                    className={classes.buttonSave}>
                  {loading ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
        <NotificationContainer/>

      </div>
  );
}

InternScoreModal.propTypes = {
  setShowEditModal: PropTypes.func,
  showEditModal: PropTypes.bool,
  userEdit: PropTypes.object,
  roles: PropTypes.array,
};