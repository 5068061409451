import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {
    makeStyles,
    MenuItem,
    Select,
    withStyles,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {CalendarToday} from '@material-ui/icons';
import '../../../../assets/css/process/datepicker_custom.css';
import {useDispatch, useSelector} from 'react-redux';
import {
    createRecruitmentRequest, fetchRecruitmentRequest,
    getRecruitmentRequestsErrors, resetErrors,
} from '../../../../features/processes/processSlice';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {dehaDivisions} from '../../../../configs/school';
import { dehaTechnologies } from "../../../../configs/app";

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    FormControl: {
        width: '16rem',
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '15.5rem',
    },
    marginSendIcon: {
        marginRight: "5px",
    },
    buttonSave: {
        backgroundColor: '#2DCE89',
        color: 'white',
        fontSize: '12px',
        '&&:hover': {
            backgroundColor: '#2DCE89',
            color: 'white',
            fontSize: '12px',
        },
    },
    searchRecruitment: {
        marginTop: '-0.6rem',
        marginBottom: '-0.83rem',
    },
    validate: {
        color: 'red',
    },
    inputSearchStyle: {
        border: '1px solid #ced4da',
        borderRadius: 4,
        width: '25.68rem',
    },
    technologyAndQuantityNumber: {
        marginBottom: '-1.45rem',
    },
    deliveryTimeTitle: {
        marginBottom: '1.7rem',
    },
    GridChooseDateDelivery: {
        marginTop: '-2rem', marginBottom: '-0.7rem',
    },
    formControl: {
        width: '15.5rem',
    },
    nameRecruitmentRequest: {
        marginTop: "0.75rem",
        marginLeft: "0.5rem"
    },
    gridFormTechnologyAndQuantity: {
        paddingTop: '-1.45rem', marginBottom: '-0.7rem',
    },
    inputQuantity: {
        border: '1px solid #cad1d7',
        paddingLeft: ' 11px',
        width: '3rem',
        height: '2.7rem',
        borderRadius: '5px',
    },
    formControlDivision: {
        width: "7rem",
        marginRight: "5px"
    },
    buttonClose: {
        position: 'absolute',
        right: '2rem',
        backgroundColor: '#fff',
    },
    closeIcon: {
        color: 'red',
        height: '20px',
        width: '20px',
    },
    addIcon: {
        color: 'green',
    },
    buttonAddIconStyle: {
        marginTop: '0.6rem',
    },
    divAddIcon: {
        marginBottom: '-1.3rem',
    },
    sendIcon: {
        marginBottom: '0.16rem',
    },
    closeButton: {
        position: 'absolute',
        zIndex: 1000,
        color: '#525f7f',
        right: '7px',
        top: '4px',
    },
}));

export default function AddRecruitmentModal(props) {
    const theme = useTheme();
    const classes = useStyles();

    const dateEndDefault = moment().add(2, 'months').format('YYYY-MM-DD');
    const [dateEnd, setDateEnd] = useState(dateEndDefault);
    const [loading, setLoading] = useState(false);
    const [division, setDivision] = useState(' ');
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const internsInformationDefault = [
        {
            time: (Date).now(),
            type: ' ',
            quantity: 1,
        },
    ];

    const [internsInformation, setInternsInformation] = useState(internsInformationDefault);
    const errors = useSelector(getRecruitmentRequestsErrors);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        if (props.showAddRecruitmentModal) {
            const currentDate = moment().format('DDMMYY');
            const timeStamp = moment().unix();
            const recruitmentNameCustom = `NC${timeStamp}${currentDate}`
            setName(recruitmentNameCustom)
        } else {
            setName('')
        }
        setDateEnd(dateEndDefault);
        setDivision(' ')
        setInternsInformation(internsInformationDefault)
        dispatch(resetErrors());

        return () => {
        };
    }, [props.showAddRecruitmentModal]);

    const handleClose = (event) => {
        event.preventDefault();
        props.setShowAddRecruitmentModal(false);
    };


    const addNewInternsInformation = () => {
        let newInternsInformation = {
            time: (Date).now(),
            type: ' ',
            quantity: 1,
        };
        setInternsInformation(prevState => [...prevState, newInternsInformation]);
    };
    const divInternsInformation = (time) => {
        setInternsInformation((prevState) =>
            prevState.filter((i) =>
                i.time != time));
    };

    function handleChangeSelect(e, index) {
        let dataDetails = internsInformation;
        dataDetails[index].type = e.target.value;
        setInternsInformation([...dataDetails]);
    }

    function handleChangeNumber(e, index) {
        let dataDetails = internsInformation;
        dataDetails[index].quantity = e.target.value;
        setInternsInformation([...dataDetails]);
    }

    const handleChange = (e) => {
        setDateEnd(e);
    };

    const createNewRecruitmentRequest = async () => {
        let data = {
            name: name,
            date_end: moment(dateEnd, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            details: internsInformation,
            division: division,
        };
        setLoading(true);
        let response = await dispatch(createRecruitmentRequest(data));
        if (response?.payload?.status === 200) {
            NotificationManager.success('Thành công!', '', 1500);
            props.setShowAddRecruitmentModal(false);
            await dispatch(fetchRecruitmentRequest());
        }
        setLoading(false);
    };


    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.showAddRecruitmentModal}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <IconButton onClick={handleClose} size={'medium'}
                                className={classes.closeButton}>
                        <CloseIcon fontSize={'large'}
                                   style={{width: '24px', height: '24px'}}/>
                    </IconButton>
                    <h2>Thêm nhu cầu nhân sự</h2>
                    <p>Division <span className={classes.validate}>*</span></p>
                    <div className={classes.searchRecruitment}>
                        <FormControl className={classes.formControlDivision}>
                            <Select
                                input={<BootstrapInput/>}
                                value={division}
                                onChange={e => setDivision(e.target.value)}
                                defaultValue={' '}
                            >
                                <MenuItem value={' '}>Division...</MenuItem>
                                {dehaDivisions.map((division) => {
                                    return (
                                        <MenuItem key={`add-recruitment${division}`}
                                                  value={division}>{division}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <span className={classes.nameRecruitmentRequest}>{name}</span>
                        </FormControl>
                        <span
                            className={'text-danger errors-name errors'}>{errors?.name?.[0]}</span>
                        <br/>
                        <span className={'text-danger errors-division errors'}>{errors?.division?.[0]}</span>
                    </div>
                    <br/>
                    <Grid container spacing={3}
                          className={classes.technologyAndQuantityNumber}>
                        <Grid item xs={6}>
                            <p>Công nghệ <span className={classes.validate}>*</span></p>
                        </Grid>
                        <Grid item xs={6} className={'text-center'}>
                            <p>Số lượng nhân sự <span className={classes.validate}>*</span>
                            </p>
                        </Grid>
                    </Grid>
                    {internsInformation.map((item, index) => {
                        return (
                            <Grid container key={index} spacing={3}
                                  className={classes.gridFormTechnologyAndQuantity}>
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            value={item.type}
                                            input={<BootstrapInput/>}
                                            defaultValue={' '}
                                            onChange={(e) => handleChangeSelect(e, index)}
                                        >
                                            <MenuItem value={' '}>Chọn công nghệ...</MenuItem>
                                           
                                            {dehaTechnologies.map((tech, i)=>{
                                                return (
                                                  <MenuItem key={'tech'+i} value={tech}>{tech}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} className={'text-center'}>
                                    <input type="number"
                                           value={item.quantity}
                                           className={classes.inputQuantity}
                                           onChange={event => handleChangeNumber(event,
                                               index)}
                                           max={100} min={0}/>
                                    {internsInformation.length > 1 ?
                                        (<Button className={classes.buttonClose}
                                                 onClick={() => divInternsInformation(
                                                     item.time)}>
                                            <CloseIcon className={classes.closeIcon}/>
                                        </Button>) : null}
                                </Grid>
                            </Grid>
                        );
                    })}
                    {internsInformation.length < 7 ?
                        (<div className={classes.divAddIcon}>
                            <Button className={classes.buttonAddIconStyle}
                                    onClick={() => addNewInternsInformation()}>
                                Thêm công nghệ<AddIcon className={classes.addIcon}/>
                            </Button>
                        </div>) : null}
                    <br/>
                    <span
                        className={'text-danger errors-details errors'}>{errors?.['details.0.type']?.[0]}</span>
                    <br/>
                    <span
                        className={'text-danger errors-details errors'}>{errors?.['details.0.quantity']?.[0]}</span>
                    <p className={classes.deliveryTimeTitle}>Thời hạn bàn giao <span
                        className={classes.validate}>*</span></p>
                    <Grid container spacing={3}
                          className={classes.GridChooseDateDelivery}>
                        <Grid item xs={6}>
                            <form className={classes.container} noValidate>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    noValidate
                                >
                                    <DatePicker
                                        value={dateEnd}
                                        fullWidth
                                        onChange={event => handleChange(event)}
                                        cancelLabel={'CANCEL'}
                                        format="dd-MM-yyyy"
                                        className={'date-picker-custom'}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton>
                                                    <CalendarToday/>
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </form>
                            <span
                                className={'text-danger error errors-date_end'}>{errors?.date_end?.[0]}</span>

                        </Grid>
                        <Grid item xs={6}>
                            <div style={{marginTop: '0.18rem'}}
                                 className={'d-flex justify-content-end'}>
                                <Button
                                    onClick={() => createNewRecruitmentRequest()}
                                    type={'submit'}
                                    disabled={loading}
                                    className={classes.buttonSave}>
                                    <span className={classes.marginSendIcon}>{loading ? ' Đang gửi' : 'Gửi'}</span>
                                    <SendIcon className={classes.sendIcon}/>
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <NotificationContainer/>
        </div>
    );
}

AddRecruitmentModal.propTypes = {
    setShowAddRecruitmentModal: PropTypes.func,
    showAddRecruitmentModal: PropTypes.bool,
    createRecruitment: PropTypes.object,
};
