import React from 'react';
// plugins styles from node_modules
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'quill/dist/quill.core.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
// plugins styles downloaded
import 'assets/plugins/nucleo/css/nucleo.css';
// core styles
import './assets/css/main.css';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import store from './app/store';
import ReactDOM from 'react-dom/client';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

