import axios from '../configs/axios';
import LocalStorageService from './localStorageService';

const localStorageService = LocalStorageService.getService();

export const loginRequest = async (data) => {
  try {
    const response = await axios.post('auth/login', data);
    return response;
  } catch ({response}) {
    return response;
  }
};

export const getUserRequest = async () => {
  try {
    const response = await axios.get('/user');
    return response;
  } catch ({response}) {
    return response;
  }
};

export const logoutRequest = async (data = {}) => {
  try {
    const response = await axios.post('auth/logout', data);
    return response;
  } catch ({response}) {
    return response;
  }
};

function compareRoles(rolesCurrent, rolesFresh) {
  return rolesCurrent.filter(({name: id1}) => !rolesFresh.some(
      ({name: id2}) => id2 === id1)).length > 0;
}

export const updateUserLocal = (userFresh) => {

  let userCurrent = localStorageService.getUser();
  let rolesCurrent = userCurrent?.roles;
  let rolesFresh = userFresh?.roles;
  let userIsExit = rolesCurrent && rolesCurrent.length > 0
      && rolesFresh && rolesFresh.length > 0;
  return new Promise(((resolve, reject) => {
    if (userIsExit && (compareRoles(rolesFresh, rolesCurrent) ||
        compareRoles(rolesCurrent, rolesFresh))) {
      const user = {...userFresh, avatar: userCurrent?.avatar};
      localStorageService.setUser(user);
      resolve(true)
    }else {
      reject(null)
    }
  }))
};