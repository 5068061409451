import React, {useState} from 'react';
// @material-ui/core components
// @material-ui/icons components

// core components

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import {
    fetchInterviewStatisticChart,
    getInterviewStatisticChart
} from "../../features/statistic/interviewStatisticSlice";
import {useDispatch, useSelector} from "react-redux";

require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/map')(Highcharts);

const statisticsStyle = makeStyles(() => ({

    selectYear: {
        width: '60px',
        height: '22px',
        fontSize: '1rem',
        border: 'none',
        backgroundColor: 'unset',
        cursor: 'pointer'
    },

    chartTitle: {
        position: 'absolute',
        zIndex: '10000',
        top: '-25px',
        display: 'flex',
        alignItems: 'baseline',
        fontWeight: 400,
        left: '40%',
    }
}));

function InterviewChartStatistic() {
    const staticsClasses = statisticsStyle();
    const dataChart = useSelector(getInterviewStatisticChart)
    const [year, setYear] = useState(parseInt((new Date()).getFullYear()));
    const [loading, setLoading] = useState(false);

    const getDataOptionForChart = (dataChart) => {
        return {
            title: {
                text: '',
            },
            xAxis: {
                categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                title: {
                    text: 'Tháng ',
                    font: 'bolid 26px "Trebuchet MS", Verdana, sans-serif',
                },
                labels: {
                    style: {
                        fontSize: '1rem',
                        color: '#000',
                    },
                },

            },
            tooltip: {
                headerFormat: '<b>Tháng {point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>'
            },
            yAxis: {
                tickInterval: 2,
                title: {
                    text: 'Số lượng',
                },
                labels: {
                    style: {
                        fontSize: '1rem',
                        color: '#000',
                    },
                },

            },
            legend: {
                itemStyle: {
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: '#000',
                },
            },
            series: [
                {
                    name: 'Số CV mới',
                    data: dataChart.new_interview,
                },
                {
                    name: 'Số CV phỏng vấn',
                    data: dataChart.interview_count,
                },
                {
                    name: 'Ứng viên không đến phỏng vấn',
                    data: dataChart.not_come_interview,
                },
                {
                    name: 'Ứng viên Pass',
                    data: dataChart.passed,
                }
                , {
                    name: 'Ứng viên Fail',
                    data: dataChart.failed,
                },
                {
                    name: 'Ứng viên nhận việc',
                    data: dataChart.apply_intern
                },
                {
                    name: 'Ứng viên đã phỏng vấn',
                    data: dataChart.interviewed
                },
            ],
        };
    };
    const getDataOptionForColumnChartInterviewProcess = (dataChart) => {

        return  {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: [1,2,3,4,5,6,7,8,9,10,11,12],
                title: {
                    text: 'Tháng ',
                    font: 'bolid 26px "Trebuchet MS", Verdana, sans-serif',
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Số lượng'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                          Highcharts.defaultOptions.title.style &&
                          Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                itemStyle: {
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: '#000',
                },
            },
            tooltip: {
                headerFormat: '<b>Tháng {point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Tổng cộng: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: [
                {
                    name: 'Ứng viên đã phỏng vấn',
                    data: dataChart.interviewed
                },
                {
                    name: 'Ứng viên không đến phỏng vấn',
                    data: dataChart.not_come_interview,
                }
            ]
        };
    }
    const getDataOptionForColumnChartInterviewPass = (dataChart) => {

        return  {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: [1,2,3,4,5,6,7,8,9,10,11,12],
                title: {
                    text: 'Tháng ',
                    font: 'bolid 26px "Trebuchet MS", Verdana, sans-serif',
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Số lượng'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                          Highcharts.defaultOptions.title.style &&
                          Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                itemStyle: {
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: '#000',
                },
            },
            tooltip: {
                headerFormat: '<b>Tháng {point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Tổng cộng: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: [
                {
                    name: 'Ứng viên Pass',
                    data: dataChart.passed,
                }
                , {
                    name: 'Ứng viên Fail',
                    data: dataChart.failed,
                },
            ]
        };
    }
    const getDataOptionForColumnChartInterviewReceiveJob = (dataChart) => {

        return  {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: [1,2,3,4,5,6,7,8,9,10,11,12],
                title: {
                    text: 'Tháng ',
                    font: 'bolid 26px "Trebuchet MS", Verdana, sans-serif',
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Số lượng'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: ( // theme
                          Highcharts.defaultOptions.title.style &&
                          Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                    }
                }
            },
            legend: {
                itemStyle: {
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: '#000',
                },
            },
            tooltip: {
                headerFormat: '<b>Tháng {point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Tổng cộng: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: [
                {
                    name: 'Ứng viên nhận việc',
                    data: dataChart.apply_intern
                },
                {
                    name: 'Ứng viên không nhận việc',
                    data: dataChart.not_apply_intern,
                },

            ]
        };
    }

    const dispatch = useDispatch();
    const changeYear = async (e) => {
        let value = e.target.value;
        setYear(value)
        setLoading(true)
        await dispatch(fetchInterviewStatisticChart({
            year: value
        }))

        setLoading(false)
    }

    const renderOptionYear = () => {
        let currentYear = parseInt((new Date()).getFullYear());
        let result = [];
        for (let year = 2020; year <= currentYear; year++) {
            result.push(<option key={`year-${year}`} value={year}>{year}</option>)
        }
        return result;
    }

    return (
        <>
            <Grid className={'position-relative'}>
                {loading ? 'Loading...'
                    :
                    (
                        <>
                            <Grid className={staticsClasses.chartTitle}>
                                <span>{'Biểu đồ tuyển dụng thực tập sinh trong năm '}</span>
                                <select className={staticsClasses.selectYear}
                                        value={year}
                                        onChange={(e) => changeYear(e)}
                                        name="year" title="Choose year">
                                    <option key={'year-2019'} value={2019}>2019</option>
                                    {renderOptionYear()}
                                </select>
                            </Grid>
                            <div className={'mt-4'}></div>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={getDataOptionForChart(dataChart)}
                            />
                            <br/>
                            <br/>
                            <h2>Biểu đồ cột số lượng CV đã xử lý</h2>
                            <br/>

                            <HighchartsReact
                              highcharts={Highcharts}
                              constructorType={'chart'}
                              options={getDataOptionForColumnChartInterviewProcess(dataChart)}
                            />

                            <br/>
                            <br/>
                            <h2>Biểu đồ cột số lượng CV PASS/ FAIL</h2>
                            <br/>

                            <HighchartsReact
                              highcharts={Highcharts}
                              constructorType={'chart'}
                              options={getDataOptionForColumnChartInterviewPass(dataChart)}
                            />

                            <br/>
                            <br/>
                            <h2>Biểu đồ cột số lượng CV nhận việc/ không nhận việc</h2>
                            <br/>

                            <HighchartsReact
                              highcharts={Highcharts}
                              constructorType={'chart'}
                              options={getDataOptionForColumnChartInterviewReceiveJob(dataChart)}
                            />
                        </>
                    )

                }
            </Grid>
        </>
    );
}

export default InterviewChartStatistic;
