const componentStyles = (theme) => ({
  mainContent: {
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '60px',
      width: `calc(100% - 62px)`,
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '0px',
      width: `calc(100% - 62px)`,
    },
  },
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  },

  mainContentCustom: {
    display: 'flex',
    flexDirection: 'column',
  },

  flexGrow_1: {
    flexGrow: 1,
  },
  content: {},
});

export default componentStyles;
