import _ from 'lodash';

export const internScoreTypeNormal = {
    practice: 'practice',
    theory: 'theory',
    attitude: 'attitude',
};

export const internScoreTypeHasComment = {
    hard_work: 'hard_work',
    communicate: 'communicate',
    honest: 'honest',
    discipline: 'discipline',
};

export const internScoreType = {
    ...internScoreTypeNormal,
    ...internScoreTypeHasComment,
};


export const getScoreOfType = (scores, type) => {
    if (scores && scores.length > 0) {
        let index = _.findIndex(scores, (score) => score.type == type);
        return index > -1 ? scores[index] : null;
    }
    return null;
};