import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  hrStyle: {
    marginTop: 'none',
    marginBottom: 'none',
    width: '16rem',
    position: 'absolute',
    top: '0',
    borderTop: '5px solid orange',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '15.5rem',
  },
  buttonAdd: {
    backgroundColor: '#2DCE89',
    color: 'white',
    fontSize: '12px',
    marginTop: '0.3rem',
    '&&:hover': {
      backgroundColor: '#2DCE89',
      color: 'white',
      fontSize: '12px',
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1000,
    color: '#525f7f',
    right: '7px',
    top: '4px',
  },
  divButtonAddStyle: {
    marginTop: '0.9rem',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  closeIconStyle: {
    width: '24px',
    height: '24px',
  },
  marginTitleRecruitment: {
    marginBottom: '0.4rem',
    fontSize: '1.2rem',
  },
  inputStyle: {
    border: '1px solid #ced4da',
    borderRadius: 2,
    width:'4rem',
  },
  selectStyle: {
    border: '1px solid #ced4da',
    width:"8rem",
    height:'2rem'
  },

  textAreaEvaluate: {
    resize:'none',
    display:'-webkit-box',
    WebkitBoxOrient:'vertical',
    overflow: 'hidden',
    whiteSpace: 'normal',
    borderRadius:'3px',
    borderColor:'#c0c0c0',
    color:'#838383',
    transition:'all 0.5s ease',
    height:'95px',
    WebkitLineClamp:'6',
    width:'100%',
    '&::-webkit-input-placeholder': {
      color: '#83838385',
    },

    '&:-moz-placeholder': { /* Firefox 18- */
      color: '#83838385',
    },

    '&::-moz-placeholder': {  /* Firefox 19+ */
      color: '#83838385',
    },

    '&:-ms-input-placeholder': {
      color: '#83838385',
    },

    '&::placeholder': {
      color: '#83838385',
    },
  },

}));

function ShowPoint(props) {
  const classes = useStyles();
  return (
      <>
        <h3 className={'position-relative'}>Kết quả phỏng vấn
          <hr className={classes.hrStyle}/>
        </h3>

        <Grid className={'d-flex flex-column w-100 pt-2'}>
          <Grid className={'d-flex flex-row justify-content-between w-100 pb-2'}>
            <Grid>
              <p className={'mb-0 font-weight-600'}>Có đến phỏng vấn?
              </p>
              <p>{props.isCome == null  || props.isPass === " "  ?'NA' : ( props.isCome ? 'Có':'Không' )  }</p>
            </Grid>

            <Grid>
              <p className={'mb-0 font-weight-600'}>Điểm kiểm tra (%)
              </p>
              <p className={'text-center'}>{props.technicalScore ?? 'NA'}</p>
            </Grid>
            <Grid>
              <p className={'mb-0 font-weight-600'}>Điểm phỏng vấn trực tiếp </p>
              <p className={'text-center'}>{props.softskillScore ?? 'NA'}</p>

            </Grid>
          </Grid>
          <Grid className={'d-flex flex-column w-100'}>
            <p className={'mb-0 font-weight-600'}>Nhận xét (nếu có):</p>
            <p>{props.comment ?? 'NA'}</p>
          </Grid>

          <Grid className={'d-flex flex-row w-100 justify-content-center align-items-center pt-2'}>
            <p className={'mb-0 pr-3 font-weight-600'}>Kết quả cuối cùng:</p>
            <p className={'mb-0 font-weight-bold'}>
              {props.isPass == null || props.isPass === " " ? 'NA':
                  (
                    props.isPass
                        ? <span className={'text-success'}>Pass</span>
                        : <span className={'text-danger'}>Fail</span>
                   )
              }


            </p>
          </Grid>

        </Grid>

      </>
  );
}

ShowPoint.propTypes = {
  isCome: PropTypes.string,
  technicalScore: PropTypes.number,
  softskillScore: PropTypes.number,
  comment: PropTypes.string,
  isPass: PropTypes.string,
};

export default ShowPoint;