import _ from 'lodash';

const LocalStorageService = (function() {
  var _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setToken(tokenObj) {
    window.localStorage.setItem('access_token', tokenObj?.access_token);
    window.localStorage.setItem('refresh_token', tokenObj?.refresh_token);
  }

  function _getAccessToken() {
    return window.localStorage.getItem('access_token');
  }

  function _getRefreshToken() {
    return window.localStorage.getItem('refresh_token');
  }

  function _checkValueLocalStorage(key) {
    let localValue = window.localStorage.getItem(key);

    return !_.isEmpty(localValue)
        && localValue!=='undefined'
        && localValue !== ""
        && localValue !== null
        && localValue !== 'null';
  }

  function _setUser(user) {
    return window.localStorage.setItem('user', JSON.stringify(user));
  }

  function _getUser() {
    return _checkValueLocalStorage('user') ?
        JSON.parse(window.localStorage.getItem('user')) :
        {};
  }

  function _isHasToken() {
    return _checkValueLocalStorage('refresh_token')
        && _checkValueLocalStorage('access_token');
  }

  function _clearToken() {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('refresh_token');
    window.localStorage.removeItem('user');
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    getUser: _getUser,
    clearToken: _clearToken,
    setUser: _setUser,
    isHasToken: _isHasToken,
  };
})();
export default LocalStorageService;
