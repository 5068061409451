import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import axios from '../../configs/axios';
import _ from 'lodash';

const initialState = {
    notifications: [],
    notification_count: 0,
}

export const fetchNotifications = createAsyncThunk('notification/fetchNotification',
    async (data = {}, {rejectWithValue}) => {
        try {
            let paramString = new URLSearchParams(data).toString();
            return await axios.get(`/notifications?${paramString}`);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);


export const fetchNotificationUnReadCount = createAsyncThunk('notification/fetchNotificationUnReadCount',
    async (data = {}, {rejectWithValue}) => {
        try {
            return await axios.get(`/notifications/unread-quantity`, data);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const readNotification = createAsyncThunk('notification/readNotification',
    async (data = {}, {rejectWithValue}) => {
        try {
            return await axios.post(`/notifications/is-read`, data);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const readAllNotification = createAsyncThunk('notification/readAllNotification',
    async (data = {}, {rejectWithValue}) => {
        try {
            return await axios.post(`/notifications/mark-all-read`, data);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);


const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        resetNotifications :(state)=>{
            state.notifications = [];
        }
    },
    extraReducers: {
        [fetchNotifications.pending]: (state) => {
            state.status = 'loading'
        },

        [fetchNotifications.fulfilled]: (state, action) => {
            let stateNotification = state.notifications;
            let notiFresh = action?.payload?.data?.data;
            let data = current(stateNotification).data;
            if (data) {
                notiFresh.data = [...data, ...notiFresh.data]
            }
            state.notifications = notiFresh;
        },

        [fetchNotificationUnReadCount.pending]: (state) => {
            state.status = 'loading'
        },

        [fetchNotificationUnReadCount.fulfilled]: (state, action) => {
            state.notification_count = action?.payload?.data?.data.total;
        },

        [readNotification.pending]: (state) => {
            state.status = 'loading';
        },

        [readNotification.fulfilled]: (state, action) => {
            let notiEdit = action?.payload?.data?.data;
            let index = _.findIndex(state.notifications.data, (item) => {
                return item.id == notiEdit.id;
            })
            if (index > -1) {
                let notifications = state.notifications;
                notifications.data[index] = notiEdit;
                state.notifications = notifications;
                let notiCount = state.notification_count;
                state.notification_count = parseInt(notiCount) - 1;
            }
        },

        [readAllNotification.pending]: (state) => {
            state.status = 'loading';
        },

        [readAllNotification.fulfilled]: (state, action) => {
            let IdsUnRead = action?.payload?.data?.data.ids;
            let notifications = state.notifications;
            let data = [...current(notifications).data];
            for (let key in data) {
                if (IdsUnRead.includes(data[key].id)) {
                    let index = _.findIndex(data, (item) => {
                        return item.id == data[key].id;
                    })
                    let dataNotiEdit = {...data[index]}
                    dataNotiEdit.is_read = true;
                    data[index] = dataNotiEdit;
                }
            }
            notifications.data = [...data]
            state.notifications = notifications;
            state.notification_count = 0;
        },
    }
});

export const getNotifications = state => state.notification.notifications;
export const getNotificationCount = state => state.notification?.notification_count;

export const {resetNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
