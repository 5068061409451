import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from '../../configs/axios';
import {setParamQueryString} from '../../services/queryStringService';

const initialState = {
  intern_statistic_index: {},
  intern_statistic_chart: [],
  intern_statistic_growth: {},
}

export const fetchInternStatisticIndex = createAsyncThunk('internStatistic/fetchInternStatisticIndex',
    async (data = {}, {rejectWithValue}) => {
      try {

        let paramString = setParamQueryString(data);
        return await axios.get(`/interns/training-statistical${paramString}`);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);

export const fetchInternStatisticChart = createAsyncThunk('internStatistic/fetchInternStatisticChart',
    async (data = {}, {rejectWithValue}) => {
      try {
        let paramString = setParamQueryString(data);
        return await axios.get(`/interns/chart-training${paramString}`);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);


export const fetchInternStatisticGrowth = createAsyncThunk('internStatistic/fetchInternStatisticGrowth',
    async (data = {}, {rejectWithValue}) => {
      try {
        let paramString = setParamQueryString(data);
        return await axios.get(`/interns/training-growth-result-statistics-monthly${paramString}`);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);

const internStatisticSlice = createSlice({
  name: 'internStatistic',
  initialState,
  reducers: {

  },
  extraReducers: {

    [fetchInternStatisticIndex.pending]: (state) => {
      state.status = 'loading'
    },

    [fetchInternStatisticIndex.fulfilled]: (state, action) => {
      state.intern_statistic_index = action?.payload?.data?.data;
    },


    [fetchInternStatisticChart.pending]: (state) => {
      state.status = 'loading'
    },

    [fetchInternStatisticChart.fulfilled]: (state, action) => {
      state.intern_statistic_chart = action?.payload?.data?.data;
    },

    [fetchInternStatisticGrowth.pending]: (state) => {
      state.status = 'loading'
    },

    [fetchInternStatisticGrowth.fulfilled]: (state, action) => {
      state.intern_statistic_growth = action?.payload?.data?.data;
    },


  }
});

export const getInternStatisticIndex = state => state.internStatistic.intern_statistic_index;
export const getInternStatisticChart = state => state.internStatistic.intern_statistic_chart;
export const getInternStatisticGrowth = state => state.internStatistic.intern_statistic_growth;


export default internStatisticSlice.reducer;
