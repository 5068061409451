import React, {useEffect} from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// @material-ui/icons components
import componentStyles from 'assets/theme/views/admin/dashboard.js';
import componentStylesCardDeck
  from 'assets/theme/components/cards/card-deck.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import House from '@material-ui/icons/House';
import Grid from '@material-ui/core/Grid';
import {Card, CardContent} from '@material-ui/core';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);
import ReceiptIcon from '@material-ui/icons/Receipt';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import CountUp from 'react-countup';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  fetchDataAnalysis,
  getDashboardAnalysis,
} from '../../../features/dashboard/dashboardSlice';

import '../../../assets/css/custom/dashboard.css';
const dashboardStyle = makeStyles((theme) => ({
  recruitment_request: {
    backgroundColor: 'rgb(0, 175,208)',
    color: 'white',
    width:"100%",
    height:"100%"
  },
  recruitment_plan: {
    backgroundColor: 'rgb(155, 62,186)',
    color: 'white',
    width:"100%",
    height:"100%"
  },

  interview: {
    backgroundColor: 'rgb(227, 167, 73)',
    color: 'white',
    width:"100%",
    height:"100%"
  },

  intern: {
    backgroundColor: 'rgb(0, 151, 55)',
    color: 'white',
    width:"100%",
    height:"100%"
  },

  card: {
    width: '50%',
    margin: '0',
    padding:'10px',
    minHeight:"280px",
    [theme.breakpoints.up('lg')]: {},
  },

  card_mb: {
    [theme.breakpoints.up('lg')]: {
    },
  },

  link: {
    color: 'white',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textDecoration: 'none',
    position: 'relative',

    '&:hover': {
      color: 'white',
      textDecoration: 'underline',
    },

    [theme.breakpoints.only('md')]: {
      fontSize: '1.5rem',

    },

    [theme.breakpoints.only('sm')]: {
      fontSize: '1.3rem',

    },

  },
  icon: {
    width: '5rem',
    height: '5rem',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    position:'absolute',
    bottom:'14px',
    right:'20px'
  },

  counter: {
    fontSize: '1rem',
    display: 'flex',
    marginBottom: 0,
    [theme.breakpoints.only('md')]: {
      fontSize: '1rem',

    },

    [theme.breakpoints.only('sm')]: {
      fontSize: '1rem',

    },

  },
  analysisContainer:{
    display:'flex',
    flexWrap:"wrap",
    justifyContent:'space-between',
    width:"100%",
    height:"calc(100% - 46px)"
  },
  container: {
    height:"auto",
    flexGrow:'1'
  }
}));

// eslint-disable-next-line react/prop-types
const CountUpCustom = ({count = 0}) => {
  return <CountUp className={'text-right font-weight-bold'} style={{width: '50px'}} end={count}
                  duration={1} start={0}/>;
};

function Dashboard() {
  const dashboardClasses = dashboardStyle();
  const dispatch = useDispatch();
  const dataAnalysis = useSelector(getDashboardAnalysis);

  useEffect( () => {
    async function getDataForAnalysis(){
      document.title = 'Dashboard';
      await dispatch(fetchDataAnalysis())
    }
   getDataForAnalysis().then(() => {})
  }, []);

  const classes = {...useStyles(), ...useStylesCardDeck()};
  return (
      <>
        {/* Page content */}
        <Container
            maxWidth={false}
            classes={{root: classes.containerRoot}}
            className={` ${dashboardClasses.container}`}
        >
          <Breadcrumbs aria-label="breadcrumb" className={'m-0'}>
            <h2 className={'d-flex justify-content-center align-items-center font-weight-600 m-0'}>
              <House style={{width: '20px', height: '20px'}} className={`m-1`}/>
              <span>Dashboard</span>
            </h2>
          </Breadcrumbs>
          <Grid  className={dashboardClasses.analysisContainer}>
            <Grid className={`${dashboardClasses.card} pt-0`}>
            <Card
                className={`${dashboardClasses.recruitment_request} `}>
              <CardContent>
                <Link className={dashboardClasses.link}
                      title={'Nhu cầu nhân sự'}
                      to={'/recruitments'}>Nhu cầu nhân sự
                </Link>
                <Grid className={`mt-3`}>
                  <p className={dashboardClasses.counter}>
                    <CountUpCustom
                        count={dataAnalysis?.recruitment_request?.total ?? 0}/>
                    <span className={'ml-2'}>
                    Tổng số nhu cầu nhân sự
                  </span>
                  </p>
                  <p className={dashboardClasses.counter}>
                    <CountUpCustom
                        count={dataAnalysis?.recruitment_request?.pending_approval ??
                        0}/>
                    <span className={'ml-2'}>
                    Đang chờ duyệt
                    </span>
                  </p>
                  <p className={dashboardClasses.counter}>
                    <CountUpCustom
                        count={dataAnalysis?.recruitment_request?.approved ?? 0}/>
                    <span className={'ml-2'}>
                                  Đã duyệt
                    </span>
                  </p>
                  <p className={dashboardClasses.counter}>
                    <CountUpCustom
                        count={dataAnalysis?.recruitment_request?.handed_over ?? 0}/>
                    <span className={'ml-2'}>
                                Đã bàn giao
                    </span>
                  </p>
                </Grid>
                <Grid className={dashboardClasses.iconBox}>
                  <RecordVoiceOverIcon className={dashboardClasses.icon}/>
                </Grid>
              </CardContent>
            </Card>
            </Grid>
            <Grid className={`${dashboardClasses.card} pt-0`}>

            <Card
                className={` ${dashboardClasses.recruitment_plan} `}>
              <CardContent>
                <Link className={dashboardClasses.link}
                      title={'Kế hoạch tuyển dụng'}
                      to={'/recruitment-plan'}>Kế hoạch tuyển dụng
                </Link>
                <Grid className={`mt-3`}>
                  <p className={dashboardClasses.counter}>

                    <CountUpCustom
                        count={dataAnalysis?.recruitment_plan?.total ??
                        0}/>
                    <span className={'ml-2'}>
                                        Tổng số kế hoạch tuyển dụng</span>
                  </p>
                  <p className={dashboardClasses.counter}>

                    <CountUpCustom
                        count={dataAnalysis?.recruitment_plan?.pending_approval ??
                        0}/>
                    <span className={'ml-2'}>
                                        Đang chờ duyệt</span>
                  </p>
                  <p className={dashboardClasses.counter}>

                    <CountUpCustom
                        count={dataAnalysis?.recruitment_plan?.approved ??
                        0}/>
                    <span className={'ml-2'}>
                                        Đã duyệt</span>
                  </p>
                  <p className={dashboardClasses.counter}>

                    <CountUpCustom
                        count={dataAnalysis?.recruitment_plan?.done ??
                        0}/> <span className={'ml-2'}>
                                      Đã hoàn thành</span>
                  </p>
                </Grid>
                <Grid className={dashboardClasses.iconBox}>
                  <ReceiptIcon className={dashboardClasses.icon}/>
                </Grid>
              </CardContent>
            </Card>
            </Grid>

            <Grid className={dashboardClasses.card}>

            <Card
                className={`${dashboardClasses.interview} `}>
              <CardContent>
                <Link className={dashboardClasses.link}
                      title={'ứng viên'}
                      to={'/candidate-manage'}>Ứng viên
                </Link>
                <Grid className={`mt-3`}>
                  <p className={dashboardClasses.counter}>
            
                    <CountUpCustom
                        count={dataAnalysis?.interview?.total ?? 0}/> <span
                      className={'ml-2'}>
                                      Tổng số ứng viên </span></p>
                  <p className={dashboardClasses.counter}>
            
                    <CountUpCustom
                        count={dataAnalysis?.interview?.no_interview ?? 0}/>
                    <span className={'ml-2'}>
                                      Chưa phỏng vấn </span></p>
                  <p className={dashboardClasses.counter}>
            
                    <CountUpCustom
                        count={dataAnalysis?.interview?.pass ?? 0}/>
                    <span className={'ml-2'}>Pass</span>
                  </p>
                  <p className={dashboardClasses.counter}>
            
                    <CountUpCustom
                        count={dataAnalysis?.interview?.fail ?? 0}/> <span
                      className={'ml-2'}>Fail</span></p>
                </Grid>
                <Grid className={dashboardClasses.iconBox}>
                  <ContactMailIcon className={dashboardClasses.icon}/>
                </Grid>
              </CardContent>
            </Card>
            </Grid>

            <Grid className={dashboardClasses.card}>

                <Card
                className={` ${dashboardClasses.intern}`}>
              <CardContent>
                <Link className={dashboardClasses.link}
                      title={'Kế hoạch tuyển dụng'}
                      to={'/educates'}>Thực tập sinh
                </Link>
                <Grid className={`mt-3`}>
                  <p className={dashboardClasses.counter}>
            
                    <CountUpCustom
                        count={dataAnalysis?.intern?.total ?? 0}/> <span
                      className={'ml-2'}>Tổng số thực tập sinh </span></p>
                  <p className={dashboardClasses.counter}>
            
                    <CountUpCustom
                        count={dataAnalysis?.intern?.practicing ?? 0}/> <span
                      className={'ml-2'}>Đang thực
                                      tập </span></p>
                  <p className={dashboardClasses.counter}>
            
                    <CountUpCustom
                        count={dataAnalysis?.intern?.pass ?? 0}/><span
                      className={'ml-2'}> Pass</span></p>
                  <p className={dashboardClasses.counter}>
            
                    <CountUpCustom
                        count={dataAnalysis?.intern?.fail ?? 0}/><span
                      className={'ml-2'}> Fail</span></p>
                </Grid>
                <Grid className={dashboardClasses.iconBox}>
                  <LocalLibraryIcon className={dashboardClasses.icon}/>
                </Grid>
              </CardContent>
            </Card>
            </Grid>

          </Grid>
        </Container>
      </>
  );
}

export default Dashboard;
