import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import '../../../../../assets/css/process/show-process-step.css';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
    getRecruitmentRequestShow,
    setRecruitmentRequestShowId,
} from '../../../../../features/processes/processSlice';
import PropTypes from 'prop-types';
import {setRecruitmentPlanShowID} from '../../../../../features/processes/recruitmentPlanSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    link: {
        color: '#6b7dd8',
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: '0.9rem !important'
    },
    fz_9: {
        fontSize: '0.9 rem !important'
    },
    reason: {
        fontSize: '0.85rem'
    }

}));

function getSteps() {
    return ['Khởi tạo nhu cầu nhân sự', 'DET xác nhận', 'DCAN xác nhận', 'Tuyển dụng', 'Đào tạo', 'Bàn giao nhân sự'];
}


export default function ProcessDetailStepper(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function getStepContent(step, recruitment) {

        let isCancel = recruitment.processes_status === 'cancel' && parseInt(recruitment.status_active_order) - 1 === step;

        switch (step) {
            case 0:
                return (
                    <>
                        <p className={`mb-0  ${classes.fz_9}`}>{recruitment.demand_originator_name} khởi tạo nhu cầu
                            nhân sự:</p>
                        <a className={`${classes.link}  ${classes.fz_9}`} onClick={() => {
                            props.closeModalRecruitment()
                            dispatch(setRecruitmentRequestShowId(recruitment.id))
                            navigate('/recruitments/')
                        }}>{recruitment.division} - {recruitment.name}</a>
                    </>
                );
            case 1:
                return (
                    <>
                        {
                            isCancel
                                ? (
                                    <>
                                        <p className={'mb-0'}>{recruitment.demand_confirm_name + ' '}
                                            đã từ chối nhu cầu nhân sự {recruitment.name}
                                        </p>
                                        <p className={`${classes.reason}`}>Lý do: {recruitment.reason}</p>
                                    </>

                                )
                                : (<>
                                        <p className={'mb-0'}>
                                            {recruitment.plan_originator_name} khởi tạo kế hoạch tuyển
                                            dụng:
                                        </p>
                                        <a className={`${classes.link}  ${classes.fz_9}`}
                                           onClick={() => {
                                               props.closeModalRecruitment();
                                               dispatch(setRecruitmentPlanShowID(
                                                   recruitment.recruitment_plan_id));
                                               navigate('/recruitment-plan/')
                                           }}>{recruitment.division} - {recruitment.recruitment_plan_name}</a>
                                    </>

                                )
                        }
                    </>

                );
            case 2:
                return (
                    <>
                        {
                            isCancel
                                ? (
                                    <>
                                        <p className={'mb-0'}>{recruitment.demand_confirm_name + ' '}
                                            đã từ chối kế hoạch tuyển dụng {recruitment.recruitment_plan_name}
                                        </p>
                                        <p className={`${classes.reason}`}>Lý do: {recruitment.reason}</p>
                                    </>

                                )
                                : (<>
                                        <p className={'mb-0'}>
                                            {recruitment.demand_confirm_name} đã xác nhận kế hoạch tuyển dụng:
                                        </p>
                                        <a className={`${classes.link}  ${classes.fz_9}`} onClick={() => {
                                            props.closeModalRecruitment()
                                            dispatch(setRecruitmentPlanShowID(recruitment.recruitment_plan_id))
                                            navigate('/recruitment-plan/')
                                        }}>{recruitment.division} - {recruitment.recruitment_plan_name}</a>
                                    </>

                                )
                        }
                    </>
                );
            case 3:
                return (
                    <>
                        <p className={'mb-0'}>Số lượng ứng viên ứng tuyển: {recruitment?.amount_interview ?? 0}</p>
                        <p className={'mb-0'}>Số lượng ứng viên PASS: {recruitment?.amount_interview_pass ?? 0}</p>
                        <p className={'mb-0'}>Số lượng ứng viên nhận việc: {recruitment?.amount_interview_receive_job ?? 0}</p>
                        <a className={`${classes.link}  ${classes.fz_9}`} onClick={() => {
                            props.closeModalRecruitment()
                           navigate('/candidate-manage/',{
                                recruitment_plan_id: recruitment.recruitment_plan_id
                            })
                        }}>Xem kết quả tuyển dụng</a>
                    </>
                );

            case 4:
                return (
                    <>
                        <p className={'mb-0'}>Số lượng thực tập sinh tham gia đào
                            tạo: {recruitment?.amount_intern ?? 0}</p>
                        <a className={`${classes.link}  ${classes.fz_9}`} onClick={() => {
                            props.closeModalRecruitment()
                            navigate('/educates/',{
                                recruitment_plan_id: recruitment.recruitment_plan_id
                            })
                        }}>Xem kết quả đào tạo</a>
                    </>
                );

            case 5:
                return (
                    <>
                        <p className={'mb-0'}>Đã bào giao {recruitment?.amount_intern_pass ?? 0} nhân sự
                            ({recruitment?.amount_intern_pass ?? 0}/{recruitment?.quantity_type_detail ?? 0})</p>
                    </>
                );
            default:
                return 'Unknown step';
        }
    }

    const recruitment = useSelector(getRecruitmentRequestShow);
    const [activeStep] = React.useState(parseInt(recruitment.status_active_order) - 1);
    const steps = getSteps();

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step active={index <= activeStep} key={label}>
                        {/* eslint-disable-next-line no-constant-condition */}
                        <StepLabel
                            className={(recruitment.processes_status == 'cancel' && index == activeStep) ? 'errors-step' : ''}><b>{label} </b></StepLabel>
                        <StepContent>
                            <Typography component={'div'}>{getStepContent(index, recruitment)}</Typography>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>

        </div>
    );
}

ProcessDetailStepper.propTypes = {
    closeModalRecruitment: PropTypes.func,
};