import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import GoogleLogin from "react-google-login";

import Grid from "@material-ui/core/Grid";
// @material-ui/icons components

// core components
import componentStyles from "assets/theme/views/auth/login.js";
import { CardContent } from "@material-ui/core";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(componentStyles);

function Login() {

  useEffect(() => {
    document.title = "Login";
  }, []);

  const classes = useStyles();
  const navigate = useNavigate();
  const { login } = useAuth();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const responseGoogle = async (response) => {
    if (response?.profileObj) {
      let status = await login(response?.profileObj);
      if (status === 200) {
        navigate(from, { replace: true });
      }
    }
  };

  return (
    <>
      <Grid item className={classes.boxLogin} xs={12} lg={5} md={7}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardContent className={`text-center`}>
            <h2>Login</h2>
            <Box textAlign="center">
              <GoogleLogin
                clientId={googleClientId}
                render={renderProps => (
                  <Button
                    variant="contained"
                    classes={{ root: classes.buttonRoot }}
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <Box component="span" marginRight="4px">
                      <Box
                        alt="..."
                        component="img"
                        width="20px"
                        className={classes.buttonImg}
                        src={
                          require(
                            "assets/img/icons/common/google.svg").default
                        }
                      ></Box>
                    </Box>
                    <Box component="span" marginLeft=".75rem">
                      Continue With Google
                    </Box>
                  </Button>
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default Login;
