import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from '../../configs/axios';
import _ from 'lodash';

const initialState = {
    interns: [],
    user: {},
    users: {},
    status: '',
    intern: {},
    userEdit: {},
    loading: false,
    courses: [],
};

export const fetchInterns = createAsyncThunk('user/fetchInterns',
    async (data = {}, {rejectWithValue}) => {
        try {
            let paramString = new URLSearchParams(data).toString();
            return await axios.get(`/interns?${paramString}`);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const showIntern = createAsyncThunk('user/show',
    async (id = {}, {rejectWithValue}) => {
        try {
            return await axios.get(`/interns/show/${id}`);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const fetchUsers = createAsyncThunk('user/fetchUsers',
    async (data = {}, {rejectWithValue}) => {
        try {
            let paramString = new URLSearchParams(data).toString();
            return await axios.get(`/users?${paramString}`);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const updatePointIntern = createAsyncThunk('user/updatePointIntern',
    async (data = {}, {rejectWithValue}) => {
        try {
            return await axios.post(`/scores/update-intern`, data);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const updateRoleUser = createAsyncThunk('user/updateRoleUser',
    async (data = {}, {rejectWithValue}) => {
        try {
            return await axios.post(`/users/assign-role/${data.user_id}`, data);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: {
        [showIntern.pending]: (state) => {
            state.loading = true;
        },
        [showIntern.fulfilled]: (state, action) => {
            state.loading = false;
            state.intern = action?.payload?.data?.data?.intern;
            state.courses = action?.payload?.data?.data?.courses;
        },

        [fetchInterns.pending]: (state) => {
            state.loading = true;
        },

        [fetchInterns.fulfilled]: (state, action) => {
            state.interns = action?.payload?.data?.data;
            state.loading = false;
        },

        [fetchUsers.pending]: (state) => {
            state.status = 'loading';
        },

        [fetchUsers.fulfilled]: (state, action) => {
            state.users = action?.payload?.data?.data;
        },

        [updatePointIntern.pending]: (state) => {
            state.status = 'loading';
        },

        [updatePointIntern.fulfilled]: (state, action) => {
            let internEdit = action?.payload?.data?.data;
            let index = _.findIndex(state.interns.data, (item) => {
                return item.id == internEdit.id;
            });
            if (index > -1) {
                let interns = state.interns;
                interns.data[index] = internEdit;
                state.interns = interns;
            }
        },

        [updateRoleUser.pending]: (state) => {
            state.status = 'loading';
        },
        [updateRoleUser.fulfilled]: (state, action) => {
            let userEdit = action?.payload?.data?.data;
            let indexUser = _.findIndex(state.users.data, (item) => {
                return item.id == userEdit.id;
            });
            if (indexUser > -1) {
                let users = state.users;
                users.data[indexUser] = userEdit;
                state.users = users;
            }
        },

    },
});

export const getInterns = state => state.user.interns;
export const getIntern = state => state.user.intern;
export const getInternCourses = state => state.user.courses;
export const getUsers = state => state.user.users;
export const getLoading = state => state.user.loading;

export default userSlice.reducer;
