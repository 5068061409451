import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import {useTheme} from '@material-ui/core/styles';
import RecruitmentProcessImage from "../../../../assets/img/brand/RecruitmentProcessImage.png";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles(() => ({
    closeButton: {
        position: 'absolute',
        zIndex: 1000,
        color: '#525f7f',
        right: "7px",
        top: "4px"
    }
}));

export default function RecruitmentProcess(props) {
    const classes = useStyle();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        props.setShowRecruitmentProcessModal(false);
    };

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.showRecruitmentProcessModal ?? false}
                className={'m-auto'}
                style={{width: "66rem"}}
                maxWidth={'lg'}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <IconButton onClick={handleClose} size={'medium'} className={classes.closeButton}>
                        <CloseIcon fontSize={'large'} style={{width: "24px", height: "24px"}}/>
                    </IconButton>
                    <img width="935" height="500" src={RecruitmentProcessImage} alt="RecruitmentProcessImage"/>
                </DialogContent>
            </Dialog>
        </div>
    );
}

RecruitmentProcess.propTypes = {
    setShowRecruitmentProcessModal: PropTypes.func,
    showRecruitmentProcessModal: PropTypes.bool,
};

