import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from '../../configs/axios';
import {setParamQueryString} from '../../services/queryStringService';
import _, {isEmpty} from 'lodash';

const initialState = {
  interviews: [],
  interview: {},
  status: '',
  errors: {},
  recruitment_plans: [],
  recruitment_plan_show_id:  null,
  cv_tags: [],
}

export const fetchInterview = createAsyncThunk('interview/fetchInterview',
    async (data = {}, {rejectWithValue}) => {
      try {
        let dataCondition = data;
        if (isEmpty(data)) {
          dataCondition = {page: 1};
        }
        let paramString = setParamQueryString(dataCondition);
        return await axios.get(`/interviews${paramString}`);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);


export const fetchCvTag = createAsyncThunk('interview/fetchCvTag',
    async (data = {}, {rejectWithValue}) => {
      try {
        let paramString = setParamQueryString(data);
        return await axios.get(`/cv-tags${paramString}`);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);



export const fetchRecruitmentPlanApprovedAndDone = createAsyncThunk('interview/fetchRecruitmentPlanApprovedAndDone',
    async (data = {}, {rejectWithValue}) => {
      try {
        let paramString = setParamQueryString(data);
        return await axios.get(`/recruitment-plan/status/approved-and-done${paramString}`);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);

export const createInterview = createAsyncThunk('interviews/createInterview',
    async (data = {}, {rejectWithValue}) => {
      try {
        return await axios.post(`/interviews/store`, data);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);


export const updateInterview = createAsyncThunk('interview/updateInterview',
    async (data = {}, {rejectWithValue}) => {
      try {
        return await axios.post(`/interviews/update/${data.id}`, data);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);
export const fetchCVTag = createAsyncThunk('interview/fetchCVTag',
    async (data = {}, {rejectWithValue}) => {
      try {
        let paramString = setParamQueryString(data);
        return await axios.get(`/cv-tags${paramString}`);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);


const interviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    resetErrors:(state)=>{
      state.errors = {};
    },
    resetRecruitmentPlanShow: (state) =>{
      state.recruitment_plan_show = {}
    },
    resetRecruitmentPlanShowID: (state) =>{
      state.recruitment_plan_show_id = null
    },

    setRecruitmentPlanShowID: (state, action) =>{
      state.recruitment_plan_show_id = action.payload
    }
  },
  extraReducers: {

    [fetchInterview.pending]: (state)=>{
      state.status = 'loading'
    },

    [fetchInterview.fulfilled]: (state, action)=>{
      state.interviews = action?.payload?.data?.data;
    },

    [fetchRecruitmentPlanApprovedAndDone.pending]: (state)=>{
      state.status = 'loading'
    },

    [fetchRecruitmentPlanApprovedAndDone.fulfilled]: (state, action)=>{
      state.recruitment_plans = action?.payload?.data?.data;
    },


    [createInterview.pending]: (state)=>{
      state.status = 'loading'
    },

    [createInterview.fulfilled]: (state, action)=>{
      state.status = 'success'

      state.interview = action?.payload?.data?.data;
    },

    [createInterview.rejected]: (state, action)=>{
      let status = parseInt(action.payload?.status)
      if(status === 422)
      {
        state.errors = action.payload.data.errors;
      }
    },

    [updateInterview.pending]: (state) => {
      state.status = 'loading';
    },

    [updateInterview.fulfilled]: (state, action) => {
      let interview = action?.payload?.data?.data;
      let index = _.findIndex(state.interviews.data, (item)=>{
        return item.id == interview.id;
      })
      if (index > -1)
      {
        let interviews = state.interviews;
        interviews.data[index] = interview;
        state.interviews = interviews;
      }
    },

    [updateInterview.rejected]: (state, action)=>{
      state.errors = action.payload.data.errors;
    },

    [fetchCvTag.pending]: (state)=>{
      state.status = 'loading'
    },

    [fetchCvTag.fulfilled]: (state, action)=>{
      state.cv_tags = action?.payload?.data?.data;
    },
  }
});

export const getInterviews = state => state.interview.interviews;

export const getInterviewErrors = state => state.interview?.errors;

export const getRecruitmentPlans = state => state.interview.recruitment_plans;

export const getRecruitmentPlan = state => state.interview?.recruitment_plan_show;

export const getRecruitmentPlanShowID = state => state.interview?.recruitment_plan_show_id;

export const getCvTags = state => state.interview?.cv_tags;

export const {
  resetErrors,
  resetRecruitmentPlanShow,
  setRecruitmentPlanShowID,
    resetRecruitmentPlanShowID
} = interviewSlice.actions;
export default interviewSlice.reducer;
