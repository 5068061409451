import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import EditIcon from '@material-ui/icons/Edit';
import TableContainer from '@material-ui/core/TableContainer';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {fetchUsers, getUsers} from '../../../features/user/userSlice';
import IconButton from '@material-ui/core/IconButton';
import {Card} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import UpdateRoleModal from './UpdateRoleModal';
import {getRoles} from '../../../features/user/roleSlice';

const useStyles = makeStyles({
    tableContainer: {
        minWidth: '650px',
    },
    table: {
        marginBottom: 0,
    },
    edit: {
        borderRadius: '50%',
        marginLeft: '5px',
        backgroundColor: '#ff9800',
        '&:hover': {
            backgroundColor: 'rgba(255,152,0,0.78)',
        },
    },
    show: {
        borderRadius: '50%',
        backgroundColor: '#00bcd4',
        '&:hover': {
            backgroundColor: 'rgba(0,188,212,0.85)',
        },
    },
    iconColor: {
        color: '#ffffff',
    },
    valignCenter: {
        verticalAlign: 'middle !important',
    },
});

export default function List(props) {
    const classes = useStyles();
    const [showEditModal, setShowEditModal] = useState(false);
    const [userEdit, setUserEdit] = useState({});
    const users = useSelector(getUsers);
    const dispatch = useDispatch();
    const roles = useSelector(getRoles);
    const editUser = (user) => {
        setShowEditModal(true);
        setUserEdit(user);
    };

    function renderUser(users) {
        let result = '';

        if (users && users?.data && users.data.length > 0) {
            let meta = users.meta;
            result = users.data.map((item, index) => {
                let iterator = (index + 1) + ((meta.current_page - 1) * meta.per_page);
                let rolesUser = item.roles.filter(a=>a.name!='guest');
                let roleName = 'NA';
                if (rolesUser.length > 1) {
                    roleName = "";
                    rolesUser.forEach((r, index)=>{
                        roleName+= index ==rolesUser.length-1 ? r.display_name: r.display_name+", " ;
                    })
                     ;
                } else if (rolesUser.length  == 1) {
                    roleName = rolesUser?.[0].display_name;
                }
                return (<TableRow key={`user-${item.id}`}>
                    <TableCell component="th" className={classes.valignCenter} scope="row"
                               align="center">{iterator}</TableCell>
                    <TableCell align="center" className={classes.valignCenter}
                               align-middle="center"> {item.name}</TableCell>
                    <TableCell className={classes.valignCenter}
                               align="center">{item.email}</TableCell>
                    <TableCell className={classes.valignCenter} align="center">
                        {roleName  }
                    </TableCell>
                    <TableCell className={classes.valignCenter}
                               align="center">{rolesUser.length>0 ? 'Đã xác nhận ' :'Đang chờ duyệt'}</TableCell>
                    <TableCell className={classes.valignCenter} align="center">
                        <IconButton className={classes.edit} title={'edit'} onClick={() => {
                            editUser(item);
                        }}>
                            <EditIcon className={classes.iconColor}/>
                        </IconButton>
                    </TableCell>
                </TableRow>);
            });
        } else {
            result = (<TableRow align="center">
                    <TableCell width="5rem">
                        <Typography variant="subtitle2">No data.</Typography>
                    </TableCell>
                </TableRow>
            );
        }

        return result;
    }

    const handleChange = async (event, value) => {
        let data = {
            page: value,
            name: props.nameProp,
            role_name:props.roleSearch,
            user_state:props.userState
        };
        await dispatch(fetchUsers(data));
    };
    const getPagination = (paginate) => {
        if (paginate) {
            return (
                <Pagination
                    count={paginate.last_page}
                    onChange={handleChange}
                    color="primary" variant="outlined"
                    boundaryCount={5}
                    size={'small'}
                    page={paginate.current_page}/>);
        }
    };
    return (
        <>
            <TableContainer component={Card} style={{margin: '20px 0'}}>
                <Table aria-label="table" className={'table table-hover'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">STT</TableCell>
                            <TableCell align="center">Tên</TableCell>
                            <TableCell align="center">Email&nbsp;</TableCell>
                            <TableCell align="center">Vai trò&nbsp;</TableCell>
                            <TableCell align="center">Trạng thái&nbsp;</TableCell>
                            <TableCell align="center">Hành động&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderUser(users)}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box justifyContent="flex-start" display="flex"
                 style={{margin: '20px 0'}}>
                {getPagination(users?.meta)}
            </Box>
            <UpdateRoleModal showEditModal={showEditModal}
                             setShowEditModal={setShowEditModal}
                             roles={roles}
                             userEdit={userEdit}/>
        </>
    );
}

List.propTypes = {
    nameProp: PropTypes.string.isRequired,
    userState: PropTypes.string.isRequired,
    roleSearch: PropTypes.string.isRequired,
};