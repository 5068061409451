import React from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// @material-ui/icons components

// core components

import {Paper, Tab, Tabs} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from "react-redux";
import {
  fetchInterviewStatisticIndex,
  getInterviewStatisticIndex
} from "../../features/statistic/interviewStatisticSlice";

const statisticsStyle = makeStyles(() => ({

  breadcrumbIcon: {
    width: '20px',
    height: '22px',
    paddingBottom: '2px',
    fontWeight: 'bold',
  },
  link: {
    color: '#7C7E7F',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  root: {
    flexGrow: 1,
    boxShadow:'none',
    width:'fit-content'
  },
  tabButton: {
    padding: "0 !important",
    minHeight:'30px',
    textTransform:'capitalize',
      "&.Mui-selected": {
        backgroundColor:"#5aceef !important",
      }
  },

  w_max_content: {
    width:'max-content'
  },
  tab_wrap: {
    minHeight:'310px'
  }
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box p={3}>
              <Typography component={'div'}>{children}</Typography>
            </Box>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function InterviewIndex(props)
{
  const interviewStatisticData = props.interviewttatisticindex;
  return (
      <>
        <Grid className={'d-flex flex-row'}>
          <div className={statisticsStyle().w_max_content}>
            <p>Số CV mới:</p>
            <p>Số CV phỏng vấn:</p>
            <p>Số ứng viên đã phỏng vấn:</p>
            <p>Số ứng viên không đến phỏng vấn:</p>
            <p>Số PASS:</p>
            <p>Số FAIL:</p>
            <p>Số ứng viên nhận việc:</p>
            <p>Số ứng viên Không nhận việc:</p>
            <p>Số ứng viên chưa nhận việc:</p>
          </div>
          <div className={`${statisticsStyle().w_max_content} pl-2`}>
            <p><b>{interviewStatisticData?.amount_new_interview}</b></p>
            <p><b>{interviewStatisticData?.interview_count}</b></p>
            <p><b>{interviewStatisticData?.amount_interviewed}</b></p>
            <p>
              <b>{interviewStatisticData?.amount_not_come_interview}</b>
              {(interviewStatisticData?.rate_not_come_interview != null && true)
                  ? (" (" + parseFloat(interviewStatisticData?.rate_not_come_interview).toFixed(2) + "% số ứng viên mới)")
                  : ''
              }
            </p>
            <p><b>{interviewStatisticData?.amount_pass}</b></p>
            <p><b>{interviewStatisticData?.amount_fail}</b></p>
            <p>
              <b>{interviewStatisticData?.amount_apply_job}</b>
              {(interviewStatisticData?.rate_apply_job != null && true)
                  ? (" (" + parseFloat(interviewStatisticData?.rate_apply_job).toFixed(2) + "% số lượng PASS)")
                  : ''
              }
            </p>
            <p><b>{interviewStatisticData?.amount_not_take_the_job}</b></p>
            <p><b>{interviewStatisticData?.amount_not_received_job}</b></p>
          </div>
        </Grid>
      </>
  )
}

InterviewIndex.propTypes = {
  interviewttatisticindex: PropTypes.object,
}

function InterviewIndexStatistic() {
  const staticsClasses = statisticsStyle();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const interviewStatistic = useSelector(getInterviewStatisticIndex);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeTab = async (type) => {
    setLoading(true);
    await dispatch(fetchInterviewStatisticIndex({type}));
    setLoading(false);
  };

  return (
      <>
        <Paper square className={staticsClasses.root}>
          <Tabs
              value={value}
              onChange={handleChange}
          >
            <Tab className={staticsClasses.tabButton}
                 onClick={() => changeTab('month')}
                 label="Theo tháng" {...a11yProps(0)} />
            <Tab className={staticsClasses.tabButton}
                 onClick={() => changeTab('quarter')}
                 label="Theo quý" {...a11yProps(1)} />
            <Tab className={staticsClasses.tabButton}
                 onClick={() => changeTab('year')}
                 label="Theo năm" {...a11yProps(2)} />
            <Tab className={staticsClasses.tabButton}
                 onClick={() => changeTab('all')} label="Tất cả" {...a11yProps(
                3)} />
          </Tabs>
        </Paper>
        <TabPanel className={staticsClasses.tab_wrap} value={value} index={0}>
          {loading ? (
              <div>
                Loading...
              </div>
          ) : (
              <InterviewIndex interviewttatisticindex={interviewStatistic}/>
          )}
        </TabPanel>
        <TabPanel className={staticsClasses.tab_wrap} value={value} index={1}>
          {loading ? (
              <div>
                Loading...
              </div>
          ) : (
              <InterviewIndex interviewttatisticindex={interviewStatistic}/>
          )}
        </TabPanel>
        <TabPanel className={staticsClasses.tab_wrap} value={value} interviewttatisticindex={interviewStatistic}
                  index={2}>
          {loading ? (
              <div>
                Loading...
              </div>
          ) : (
              <InterviewIndex interviewttatisticindex={interviewStatistic}/>
          )}        </TabPanel>
        <TabPanel className={staticsClasses.tab_wrap} value={value} interviewttatisticindex={interviewStatistic}
                  index={3}>
          {loading ? (
              <div>
                Loading...
              </div>
          ) : (
              <InterviewIndex interviewttatisticindex={interviewStatistic}/>
          )}          </TabPanel>
      </>
  );
}

export default InterviewIndexStatistic;
