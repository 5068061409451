import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import '../../../../../assets/css/process/process-detail.css';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import {TableCell} from 'semantic-ui-react';
import ProcessDetailStepper from './ProcessDetailStepper';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useDispatch, useSelector} from 'react-redux';
import {
    getRecruitmentRequestShow, resetRecruitmentRequestShowId,
    resetRecruitmentShow,
} from '../../../../../features/processes/processSlice';
import {useAuth} from '../../../../../contexts/AuthContext';
import Button from '@material-ui/core/Button';
import { useNavigate} from 'react-router-dom';
import RejectRecruitmentModal from './RejectRecruitmentModal';
import {setRecruitmentRequestIdForCreate,
} from '../../../../../features/processes/recruitmentPlanSlice';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    closeModalIcon: {
        width: '24px',
        height: '24px',
    },
    expandMoreIcon: {
        color: "white"
    },
    buttonShowProcess: {
        backgroundColor: '#00BCD4',
        color: '#fff',
        marginTop: "0.35rem",
        borderRadius: "3px",
        width: "7rem",
        fontSize: '16px',
        '&&:hover': {
            backgroundColor: '#03a6bb',
        },
    },
    titleProcess: {
        marginLeft: "0.5rem"
    },
    closeButton: {
        position: 'absolute',
        zIndex: 1000,
        color: '#525f7f',
        right: '7px',
        top: '4px',
    },
    w_50: {
        width: '50%',
    },
    border_td_1: {
        borderRight: '1px solid #e9ecef !important',
    },
    p_05: {
        padding: '0.5rem',
    },
    boxshadow_0: {
        boxShadow: 'none',
    },
}));

export default function RecruitmentDetailModal(props) {
    const theme = useTheme();
    const classes = useStyles();
    const [showProcess, setShowProcess] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [rejectRecruitmentId, setRejectRecruitmentId] = useState('');
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {authorize} = useAuth();
    const navigate = useNavigate();
    const handleClose = (event) => {
        event.preventDefault();
        props.setShowDetailModal(false);
    };
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetRecruitmentShow());
            setShowProcess(false)
        };
    }, []);

    useEffect(() => {
        if(!props.showDetailModal)
        {
            dispatch(resetRecruitmentRequestShowId())
            setShowProcess(false)
        }
    }, [props.showDetailModal]);

    const recruitment = useSelector(getRecruitmentRequestShow);
    const handleShowProcess = (panel) => (event, isExpanded) => {
        setShowProcess(isExpanded ? panel : false);
    };

    const closeModalRecruitment = () => {
        props.setShowDetailModal(false);
    };

    const rejectRecruitment = (recruitmentRequestId) => {
       setShowRejectModal(true)
        setRejectRecruitmentId(recruitmentRequestId)
    }

    const createRecruitmentPlanOf = (recruitmentRequestID) =>
    {
        dispatch(setRecruitmentRequestIdForCreate(recruitmentRequestID))
        navigate({
            pathname: '/recruitment-plan/',
        })
    }

    const renderDetail = (recruitment) => {
        let result = '';
        if (recruitment?.details && recruitment?.details.length > 0) {
            result = recruitment?.details.map((item, key) => {
                return (
                    <TableRow key={'adjkadkadb-detail' + key}>
                        <TableCell align={'center'}
                                   className={`${classes.border_td_1}  ${classes.p_05}`}>
                            {item.type}
                        </TableCell>
                        <TableCell align={'center'}
                                   className={`${classes.border_td_1}  ${classes.p_05}`}>
                            {item.quantity}
                        </TableCell>
                    </TableRow>
                );
            });
        } else {
            result = (<TableRow key={'adjkadkadb-detaildsakd'}>
                <TableCell align={'center'}
                           className={`${classes.p_05}`}>
                    No data.
                </TableCell>
            </TableRow>);
        }

        return result;

    };
    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.showDetailModal}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <IconButton onClick={handleClose} size={'medium'}
                                className={classes.closeButton}>
                        <CloseIcon fontSize={'large'} className={classes.closeModalIcon}/>
                    </IconButton>
                    <h2>Nhu cầu nhân sự</h2>
                    <p className={'mb-0'}> Tên: {recruitment.full_name}</p>
                    <p className={'mb-0'}> Thời gian khởi tạo: {recruitment.date_start}</p>
                    <p className={'mb-0'}> Người gửi: {recruitment.demand_originator_name}</p>
                    <TableContainer className={'d-flex justify-content-center'}>
                        <Table aria-label="table"
                               className={`table table-hover table-bordered ${classes.w_50}`}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'center'}
                                               className={`${classes.border_td_1}  ${classes.p_05}`}>
                                        Công nghệ
                                    </TableCell>
                                    <TableCell align={'center'}
                                               className={`${classes.border_td_1}  ${classes.p_05}`}>
                                        Số lượng nhân sự
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    renderDetail(recruitment)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <p className={'mb-0'}>Thời gian bàn giao: {recruitment.date_end}</p>
                    <p className={'mb-0'}>Trạng thái: {recruitment.status}</p>
                    {
                        authorize.hasPermission('show-recruitment-request')
                            ? (
                                ((recruitment.processes_status != 'cancel'&& recruitment.status_active_order <2)) && authorize.hasRole('training-management')
                                    ? (<Grid className={'pt-1 d-flex justify-content-between'}>
                                        <Button
                                            onClick={()=>rejectRecruitment(recruitment.id)}
                                            variant="contained" style={{backgroundColor: "#ea4846", border: 'none'}}>
                                            Từ chối
                                        </Button>
                                        <Button variant="contained" className={'btn text-white'}
                                              style={{backgroundColor: "#539a04", border: 'none'}}
                                              onClick={()=>createRecruitmentPlanOf(recruitment.id)}
                                        >
                                         Khởi tạo kế hoạch tuyển dụng
                                        </Button>
                                    </Grid>)
                            :(<Grid>
                                <Accordion expanded={showProcess}
                                           className={`${classes.boxshadow_0} process-details`}
                                           onChange={handleShowProcess('panel1')}
                                >
                                    <Button className={classes.buttonShowProcess}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
                                            className={`${classes.boxshadow_0} height_one p-0 d-flex justify-content-start`}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <span className={classes.titleProcess}>Quy trình</span>
                                        </AccordionSummary>
                                    </Button>
                                    <AccordionDetails>
                                        <ProcessDetailStepper
                                            closeModalRecruitment={closeModalRecruitment}/>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>)
                            )
                            :null
                    }

                </DialogContent>
            </Dialog>
            <RejectRecruitmentModal
                showRejectModal={showRejectModal}
                setShowRejectModal={setShowRejectModal}
                rejectRecruitmentId={rejectRecruitmentId}
                 closeModalShowRecruitment = {closeModalRecruitment}
            />
        </div>
    );
}

RecruitmentDetailModal.propTypes = {
    setShowDetailModal: PropTypes.func,
    showDetailModal: PropTypes.bool,
};
