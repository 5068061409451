import React, {useEffect, useState} from 'react';
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import '../../../../assets/css/custom/container.css';

import componentStyles from "assets/theme/views/admin/dashboard.js";
import {MenuItem, Select, withStyles} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AddIcon from '@material-ui/icons/Add';
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import RecruitmentList from "./RecruitmentList";
import HelpIcon from '@material-ui/icons/Help';
import Button from "@material-ui/core/Button";
import WorkIcon from "@material-ui/icons/Work";
import AddRecruitmentModal from "./AddRecruitmentModal";
import RecruitmentProcess from "./RecruitmentProcess";
import {useDispatch} from 'react-redux';
import {
    fetchRecruitmentRequest,
} from '../../../../features/processes/processSlice';
import useDebounce from '../../../../hooks/useDebounce';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../../../../contexts/AuthContext';
import {dehaDivisions} from '../../../../configs/school';

const useStyles = makeStyles(componentStyles);

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

function Recruitment() {
    const classes = useStyles();

    const inputSearchStyle = {
        border: '1px solid #ced4da',
        borderRadius: 4,
        width: '15rem',
    };
    const breadCrumbsStyle = {
        paddingTop: '0.5rem',
        marginBottom: '-0.55rem',
        marginLeft: '3px',
        fontWeight: 'bold',
    };
    const titleStyle = {
        marginTop: '-1.25rem',
    };
    const workIconStyle = {
        position: 'absolute',
        marginTop: '1.4rem',
    };
    const buttonAddRecruitment = {
        // position: 'absolute',
        // right: "2rem",
        // top: "10rem",
        backgroundColor: "#2DCE89",
        "&:hover": {
            backgroundColor: "2ade91"
        }
    };
    const breadcrumbStyle = {
        marginLeft: "-0.48rem",
    };
    const buttonRecruitmentProcess = {
        position: "absolute",
        width: "15rem",
        fontSize: "15px",
        color: "rgb(8 89 191)",
        top: "6rem",
        right: "0.4rem"
    };
    const addIconStyle = {
        top: "-0.1rem",
        position: "relative",
        right: "0.5rem",
        height: "23px",
        width: "23px",
    };
    const [showAddRecruitmentModal, setShowAddRecruitmentModal] = useState(false)
    const paramsSearch = useLocation();
    const [createRecruitment, setCreateRecruitment] = useState({});
    const [nameSearch, setNameSearch] = useState('');
    const [divisionSearch, setDivisionSearch] = useState(' ');
    const [status, setStatus] = useState(' ');
    const dispatch = useDispatch();
    const {authorize} = useAuth()
    useEffect(() => {
        document.title = 'Nhu cầu nhân sự';
    }, []);

    useEffect(() => {
       async function getRecruitmentRequest() {
            if (paramsSearch?.name) {
                dispatch(fetchRecruitmentRequest({
                    name: paramsSearch?.name,
                    page: 1,
                }));
                setNameSearch(paramsSearch.name);
            } else {
                dispatch(fetchRecruitmentRequest());
            }
            setShowAddRecruitmentModal(false);
        }

        getRecruitmentRequest();

    }, [paramsSearch?.name]);

    function searchWithName(e) {
        let nameSearch = e.target.value;
        setNameSearch(nameSearch);
        search(nameSearch, status, divisionSearch);
    }

    function searchWithStatus(e) {
        let value = e.target.value;
        setStatus(value);
        searchWithoutDebounce(nameSearch, value, divisionSearch)
    }

    function searchWithDivision(e) {
        let value = e.target.value;
        setDivisionSearch(value);
        searchWithoutDebounce(nameSearch,status, value)
    }

    const searchWithoutDebounce = (name, status, division) => {
        let data = {
            page: 1,
            name: name,
            status: status,
            division: division
        };
        dispatch(fetchRecruitmentRequest(data));
    }
    const search = useDebounce(function (...arg) {
        const [name, status, division] = arg;

        let data = {
            page: 1,
            name: name,
            status: status,
            division: division
        };
        dispatch(fetchRecruitmentRequest(data));
    });
    const addRecruitmentModal = () => {
        setShowAddRecruitmentModal(true);
        setCreateRecruitment({})
    }
    const [showRecruitmentProcessModal, setShowRecruitmentProcessModal] = useState();
    const showRecruitmentProcess = () => {
        setShowRecruitmentProcessModal(true);
    };
    const helpIconStyle = {
        height: "25px",
        width: "25px"
    }

    return (
        <>
            {/* Page content */}
            <Container
                maxWidth={false}
                className={`educate ${classes.flexGrow_1}`}
                classes={{root: classes.containerRoot}}
            >
                <div>
                    <WorkIcon style={workIconStyle}/>
                    <Breadcrumbs style={breadCrumbsStyle} aria-label="breadcrumb">
                        <Link color="inherit">
                            Tuyển dụng
                        </Link>
                        <Link color="inherit" href="/recruitments" style={breadcrumbStyle}>
                            Nhu cầu nhân sự
                        </Link>
                    </Breadcrumbs>
                    <h2 style={titleStyle}>Nhu cầu nhân sự</h2>
           <div className={'d-flex justify-content-between flex-wrap'}>
         <div className={'d-flex flex-wrap justify-content-around'}>
             <TextField id="filled-search" placeholder="Tìm kiếm..."
                        type="search"
                        variant="filled"
                        className={'mr-2 mt-2'}
                        value={nameSearch}
                        onChange={e => searchWithName(e)}
                        style={inputSearchStyle}
             />
             <FormControl  className={'mr-2 mt-2'} >
                 <Select
                     input={<BootstrapInput/>}
                     defaultValue={' '}
                     value={status}
                     onChange={e => searchWithStatus(e)}

                 >
                     <MenuItem value={' '}>Trạng thái...</MenuItem>
                     <MenuItem value={'Sent Request'}>Đang chờ</MenuItem>
                     <MenuItem value={'Confirmed Request By DET'}>Đã xác nhận</MenuItem>
                     <MenuItem value={'Reject Request By DET'}>Bị từ chối bởi DET</MenuItem>
                     <MenuItem value={'Reject Request By DECAN'}>Bị từ chối bởi DECAN</MenuItem>
                     <MenuItem value={'Confirmed Request By DECAN'}>Đang tuyển dụng</MenuItem>
                     <MenuItem value={'Handed Over'}>Đã bàn giao</MenuItem>
                     <MenuItem value={'Cancel Request'}>Đã huỷ</MenuItem>

                 </Select>
             </FormControl>
             <FormControl className={'mt-2'} >
                 <Select
                     input={<BootstrapInput/>}
                     defaultValue={' '}
                     value={divisionSearch}
                     onChange={e => searchWithDivision(e)}
                 >
                     <MenuItem value={' '}>Bộ phận...</MenuItem>
                     {dehaDivisions.map((division) => {
                         return (
                             <MenuItem key={`search-division${division}`}
                                       value={division}>{division}</MenuItem>
                         );
                     })}
                 </Select>
             </FormControl>
         </div>

               {
                   authorize.hasPermission('create-recruitment-request')
                       ?
                       (<Button style={buttonAddRecruitment}
                                className={'mt-2'}
                                onClick={addRecruitmentModal}>
                        <span style={{color: '#ffffff'}}>
                            <AddIcon style={addIconStyle}/>
                            Thêm nhu cầu nhân sự
                        </span>
                       </Button>)
                       :
                       ''
               }</div>

                    <Button onClick={showRecruitmentProcess}
                            style={buttonRecruitmentProcess}>
                        Quy trình tuyển dụng <HelpIcon style={helpIconStyle}/>
                    </Button>
                    <RecruitmentProcess setShowRecruitmentProcessModal={setShowRecruitmentProcessModal}
                                        showRecruitmentProcessModal={showRecruitmentProcessModal}/>
                    <RecruitmentList nameProp={nameSearch}
                                     divisionSearch={divisionSearch}
                                     status={status}/>
                    <AddRecruitmentModal createRecruitment={createRecruitment}
                                         setShowAddRecruitmentModal={setShowAddRecruitmentModal}
                                         showAddRecruitmentModal={showAddRecruitmentModal}/>
                </div>
            </Container>
        </>
    );
}

export default Recruitment;
