import React, {useEffect} from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import componentStyles from 'assets/theme/views/admin/dashboard.js';
import {useAuth} from '../../contexts/AuthContext';
import {Navigate} from 'react-router-dom';
import LocalStorageService from '../../services/localStorageService';
const localStorageService = LocalStorageService.getService();
const useStyles = makeStyles(componentStyles);

function NotActive() {
  const classes = useStyles();
  const {authorize} = useAuth();
  useEffect( ()=>{
    if(authorize.isGuest())
    {
      document.title = 'Tài khoản của bạn đang chờ duyệt...';
    }
  },[])
  if(authorize.isAuthorize() !== true && localStorageService.isHasToken())
  {
    return   <Navigate to="/login" /> ;
  }
  if(!authorize.isGuest())
  {
    return  <Navigate to="/" />;
  }
  return (
      <>
        {/* Page content */}
        <Container
            maxWidth={false}
            classes={{root: classes.containerRoot}}
            className={classes.flexGrow_1}
        >
          <div className={classes.notfoundContainer}>
            <h1>
              {authorize.isGuest()
                  ? 'Tài khoản của bạn đang chờ duyệt...'
                  : ''
              }
            </h1>
          </div>
        </Container>
      </>
  );
}

export default NotActive;
