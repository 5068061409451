import {
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import userReducer from "../features/user/userSlice";
import subjectReducer from "../features/subjectSlice";
import roleReducer from "../features/user/roleSlice";
import processReducer from "../features/processes/processSlice";
import recruitmentPlanReducer from "../features/processes/recruitmentPlanSlice";
import interviewReducer from "../features/processes/interviewSlice";
import notificationReducer from "../features/notification/notificationSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import internStatisticReducer from "../features/statistic/internStatisticSlice";
import interviewStatisticReducer from "../features/statistic/interviewStatisticSlice";
import courseLmsReduce from '../features/lms/courseSlice';

export default configureStore({
  reducer:{
    user: userReducer,
    subject: subjectReducer,
    role: roleReducer,
    process: processReducer,
    notification: notificationReducer,
    recruitmentPlan: recruitmentPlanReducer,
    interview: interviewReducer,
    dashboard: dashboardReducer,
    internStatistic: internStatisticReducer,
    interviewStatistic: interviewStatisticReducer,
    courseLms:courseLmsReduce
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
