import React from "react";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

// core components
import componentStyles from "assets/theme/components/footers/admin-footer.js";

const useStyles = makeStyles(componentStyles);

const Footer = () => {
    const classes = useStyles();
    return (
        <Box component="footer" width="100%" style={{padding: "20px 0"}}>
            <Grid classes={{root: classes.justifyContentCenter}}>
                <Box
                    item
                    xs={12}
                    xl={12}
                    component={Grid}
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                    className={classes.justifyContentCenter}
                >
                    <div className={classes.copyrightWrapper}>
                        © {new Date().getFullYear()}{" "}, made with favorite by
                        <span
                            className={classes.copyrightLink}
                            style={{marginRight: '5px'}}
                        >
              Deha Academy Team
            </span>
                        for a better web.
                    </div>
                </Box>
            </Grid>
        </Box>
    );
};

export default Footer;
