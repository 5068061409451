
const componentStyles = (theme) => ({
  bgDefault: {
    backgroundColor: theme.palette.dark.main,
  },

  authContainer:{
    height: '100%',
    display: 'flex',
    flexDirection:'column'
  },

  footerAuth:{
    overflowX:'unset',
    display:'flex',
    height: '50%',
    alignItems: 'flex-end'
  }
});

export default componentStyles;
