import React, {useState, useEffect} from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

// core components
import {useDispatch, useSelector} from 'react-redux';
import {fetchUsers} from '../../../features/user/userSlice';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import useDebounce from '../../../hooks/useDebounce';

import List from './List';
import PeopleIcon from '@material-ui/icons/People';
import FormControl from '@material-ui/core/FormControl';
import {MenuItem, Select, withStyles} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import {fetchRoles, getRoles} from '../../../features/user/roleSlice';

const useStyles = makeStyles({
    edit: {
        borderRadius: '50%',
        marginLeft: '5px',
        backgroundColor: '#ff9800',
        '&:hover': {
            backgroundColor: 'rgba(255,152,0,0.78)',
        },
    },
    valignCenter: {
        verticalAlign: 'middle !important',
    },
});

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

function Users() {
    useEffect(() => {
        async function setUp() {
            document.title = 'Người dùng';
            dispatch(fetchUsers());
            dispatch(fetchRoles());
        }

        setUp();

    }, []);
    const roles = useSelector(getRoles);
    const dispatch = useDispatch();
    const classes = useStyles();
    const inputSearchStyle = {
        border: '1px solid #ced4da',
        borderRadius: 4,
        width: '15rem',
    };
    const selectStyle = {
        marginLeft: '10px',
        width: '15rem',
    };
    const breadCrumbsStyle = {
        paddingTop: '0.5rem',
        marginBottom: '-0.55rem',
        marginLeft: '3px',
        fontWeight: 'bold',
    };
    const titleStyle = {
        marginTop: '-1.25rem',
    };
    const peopleIconStyle = {
        position: 'absolute',
        marginTop: '1.17rem',
    };
    var [name, setName] = useState('');
    var [userState, setUserState] = useState('');
    var [roleSearch, setRoleSearch] = useState('');

    function searchWithName(e) {
        let nameSearch = e.target.value;
        setName(nameSearch);
        search(nameSearch, userState, roleSearch);
    }

    function searchWithSate(e) {
        let value = e.target.value;
        setUserState(value);
        searchWithoutDebounce(name, roleSearch, value);
    }

    function searchWithRole(e) {
        let value = e.target.value;
        setRoleSearch(value);
        searchWithoutDebounce(name, value, userState);
    }

    const searchWithoutDebounce = (name, roleSearch, userState) => {
        let data = {
            page: 1,
            name: name,
            role_name: roleSearch,
            user_state: userState,
        };
        dispatch(fetchUsers(data));
    };

    const search = useDebounce(function(...arg) {
        const [nameSearch, userState, role_name] = arg;

        let data = {
            page: 1,
            name: nameSearch,
            role_name: role_name,
            user_state: userState,
        };
        dispatch(fetchUsers(data));
    });

    return (
        <>
            {/* Page content */}
            <Container
                maxWidth={false}
                className={`educate ${classes.flexGrow_1}`}
                classes={{root: classes.containerRoot}}
            >
                <div>
                    <PeopleIcon style={peopleIconStyle} />
                    <Breadcrumbs style={breadCrumbsStyle}
                                 aria-label="breadcrumb">
                        <Link color="inherit" href="/users">
                            Quản lý người dùng
                        </Link>
                    </Breadcrumbs>
                    <h2 style={titleStyle}>Quản lý người dùng</h2>
                    <TextField id="filled-search" placeholder="Tìm kiếm..."
                               type="text"
                               variant="filled"
                               style={inputSearchStyle}
                               onChange={(e) => {
                                   searchWithName(e);
                               }}
                    />
                    <FormControl style={selectStyle}>
                        <Select
                            input={<BootstrapInput />}
                            defaultValue={' '}
                            onChange={e => {searchWithSate(e);}}
                        >
                            <MenuItem value={' '}>Trạng thái...</MenuItem>
                            <MenuItem value={'active'}>Đã xác nhận</MenuItem>
                            <MenuItem value={'diActive'}>Đang chờ
                                duyệt</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl style={selectStyle}>
                        <Select
                            input={<BootstrapInput />}
                            defaultValue={' '}
                            onChange={e => {searchWithRole(e);}}
                        >
                            <MenuItem value={' '}>Vai trò...</MenuItem>
                            {roles.filter(a => a.name != 'guest').map(item => {
                                return <MenuItem key={`role-${item.name}`}
                                                 value={item.name}>{item.display_name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </div>
                <List nameProp={name}
                      userState={userState}
                      roleSearch={roleSearch}
                />
            </Container>
        </>
    );
}

export default Users;
