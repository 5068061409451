// // // // // admin dashboards
import React from 'react';
import Dashboard from 'views/admin/Dashboards/Dashboard';
import Educate from 'views/admin/Educates/Educate';
import Recruiment from '../views/admin/Recruitments/Recruitments/Recruitment';
import RecruitmentPlan
  from '../views/admin/Recruitments/RecruitmentPlan/RecruitmentPlan';

import House from '@material-ui/icons/House';
import WorkIcon from '@material-ui/icons/Work';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SchoolIcon from '@material-ui/icons/School';
import PeopleIcon from '@material-ui/icons/People';
import Login from '../views/auth/Login';
import Users from '../views/admin/Users/Users';
import Interview from '../views/admin/Recruitments/Interviews/Interview';
import EducateStatistic from '../views/admin/Statistical/EducateStatistic';
import InternviewStatistic
  from '../views/admin/Statistical/InternviewStatistic';
import NotActive from '../views/error/NotActive';
import Forbidden from '../views/error/Forbidden';
import NotFound from '../views/error/NotFound';

const authRoutes = [
  {
    path: '/login',
    name: 'Login',
    icon: House,
    iconColor: 'Success',
    exact: true,
    component: () => <Login />,
    layout: '/auth',
  },
];

const errorRoutes = [
  {
    path: '/error-not-active',
    name: 'Lỗi tài khoản chưa Active',
    icon: PeopleIcon,
    iconColor: '#00000080',
    component: () => <NotActive />,
    layout: '/admin',
    permission: '',
    role: '',
  },
  {
    path: '/error-forbidden',
    name: 'Lỗi tài khoản Không có quyền',
    icon: PeopleIcon,
    iconColor: '#00000080',
    component: () => <Forbidden />,
    layout: '/admin',
    permission: '',
    role: '',
  },
  {
    path: '*',
    name: 'Lỗi trang không tồn tại',
    icon: PeopleIcon,
    iconColor: '#00000080',
    component: () => <NotFound />,
    layout: '/admin',
    permission: '',
    role: '',
  },
];

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    icon: House,
    iconColor: '#00000080',
    component: () => <Dashboard />,
    layout: '/admin',
    permission: 'index-dashboard',
    role: '',
  },
  {
    path: '/educates',
    name: 'Đào tạo',
    icon: SchoolIcon,
    iconColor: '#00000080',
    component: () => <Educate />,
    layout: '/admin',
    permission: 'index-intern',
    role: '',
  },
  {
    collapse: true,
    name: 'Tuyển dụng',
    icon: WorkIcon,
    iconColor: '#00000080',
    state: 'recruimentsCollapse',
    permission: 'index-recruitment-request',
    views: [
      {
        path: '/recruitments',
        name: 'Nhu cầu nhân sự',
        miniName: 'N',
        component: () => <Recruiment />,
        layout: '/admin',
        permission: 'index-recruitment-request',
        role: '',
      },
      {
        path: '/recruitment-plan',
        name: 'Kế hoạch tuyển dụng',
        miniName: 'K',
        component: () => <RecruitmentPlan />,
        layout: '/admin',
        permission: 'index-recruitment-request',
        role: '',
      },
      {
        path: '/candidate-manage',
        name: 'Quản lý ứng viên',
        miniName: 'Q',
        component: () => <Interview />,
        layout: '/admin',
        permission: 'index-interview',
        role: '',
      },
    ],
  },
  {
    collapse: true,
    name: 'Thống kê',
    icon: EqualizerIcon,
    iconColor: '#00000080',
    state: 'statisticalCollapse',
    permission: 'index-statistical',
    views: [
      {
        path: '/training-result',
        name: 'Kết quả đào tạo',
        miniName: 'Đ',
        component: () => <EducateStatistic />,
        layout: '/admin',
        permission: 'index-statistical',
        role: '',
      },
      {
        path: '/interview-statistic',
        name: 'Kết quả tuyển dụng',
        miniName: 'T',
        component: () => <InternviewStatistic />,
        layout: '/admin',
        permission: 'index-statistical',
        role: '',
      },
      // {
      //     path: "/after-training",
      //     name: "Thống kê sau đào tạo",
      //     miniName: "S",
      //     component: Statistical,
      //     layout: "/admin",
      //     permission: "index-statistical",
      //     role: ""
      // }
    ],
  },
  {
    path: '/users',
    name: 'Quản lý người dùng',
    icon: PeopleIcon,
    iconColor: '#00000080',
    component: () => <Users />,
    layout: '/admin',
    permission: 'index-user',
    role: 'super-admin',
  },
];

export {authRoutes, errorRoutes};

export default routes;
