import axios from '../configs/axios';
import {setParamQueryString} from './queryStringService';

export function exportExcelBy (uri,fileName, data = []){
  let paramString = setParamQueryString(data);
  let url = process.env.REACT_APP_API_URL+uri+paramString

  axios({
    url: url,
    method: 'GET',
    data:data,
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName ?? 'file'}.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}