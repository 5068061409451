import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        top: 0,
        left: 0,
        zIndex: '9999999999',
        background: '#00000021',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        pointerEvents:'unset'
    },
    wrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    loading: {
        width:'60px',
        height:'60px'
    }
}));

export default function CircularIndeterminate() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.wrap}>
                <CircularProgress className={classes.loading} />
            </div>
        </div>
    );
}