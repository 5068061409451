export const dehaTechnologies = [
  'PHP',
  'Laravel',
  'React Native',
  'React JS',
  'Angular',
  'Python - Django',
  'Vue JS',
  'Android',
  'IOS',
  'Java',
  '.NET',
  'Node JS'
];

export const positions = [
  'Developer',
  'Product Owner',
  'Quality Assurance',
  'Team Coach'
];

export const progressBarColor = {
  safe: '#11cdef',
  warning: '#FFA500',
  danger: '#FF5349'
}