import React, {useContext, useState} from 'react';
import {
  loginRequest,
  logoutRequest,
} from '../services/authService';
import PropTypes from 'prop-types';
import LocalStorageService from '../services/localStorageService';
import AuthorizeService from '../services/authorizeService';

const AuthContext = React.createContext(undefined);

export function AuthProvider({children}) {
  const localStorageService = LocalStorageService.getService();

  let userLocal = localStorageService.getUser();

  const [currentUser, setCurrentUser] = useState(userLocal);

  async function login(data) {
    let response = await loginRequest(data);
    if (response?.status === 200) {
      let responseData =  response?.data?.data;
      let user = responseData?.user;
     localStorageService.setToken(responseData)
      if (user) {
        let userImage = {...user, avatar:responseData?.image};
        localStorageService.setUser(userImage);
        setCurrentUser(userImage);
      }
    }
    return response?.status;
  }

  async function logout() {
    let response = await logoutRequest();
    localStorageService.clearToken();
    return response?.status;
  }

  const value = {
    user: currentUser,
    setUser:setCurrentUser,
    login: login,
    logout: logout,
    authorize: new AuthorizeService(currentUser)
  };

  return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

AuthProvider.propTypes = {
  children: PropTypes.any,
}