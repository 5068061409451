import React, {useEffect, useState} from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import {useTheme} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
// @material-ui/icons components
import Notifications from '@material-ui/icons/Notifications';

// core components
import componentStyles
    from 'assets/theme/components/dropdowns/notifications-dropdown.js';
import WorkIcon from '@material-ui/icons/Work';
import LensIcon from '@material-ui/icons/Lens';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchNotifications, getNotificationCount,
    getNotifications, readAllNotification, readNotification,
    resetNotifications,
} from '../../features/notification/notificationSlice';
import {useNavigate} from 'react-router-dom';
import {isEmpty} from 'lodash';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import CheckIcon from "@material-ui/icons/Check";
import {Button} from "@material-ui/core";
import {setRecruitmentPlanShowID} from '../../features/processes/recruitmentPlanSlice';
import {setRecruitmentRequestShowId} from '../../features/processes/processSlice';

const useStyles = makeStyles({
    ...componentStyles,
    badge_notification: {
        position: 'absolute',
        top: '5px',
        right: '10px',
        fontSize: '9px',
        background: '#f44336',
        color: '#fff',
        minWidth: '20px',
        padding: '0px 5px',
        height: '20px',
        borderRadius: '10px',
        textAlign: 'center',
        lineHeight: '19px',
        verticalAlign: 'middle',
        display: 'block',
    },
    iconMarkReadAll: {
        marginTop: "-0.2rem"
    },
    notification_content: {
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        whiteSpace: 'normal',
    },
    buttonMarkReadAll: {
        position: "absolute",
        right: "0.2rem",
        fontSize: "0.62rem",
        top: "0.8rem"
    },
    notification_box: {
        width: '400px',
    },

    notification_wrap: {

        cursor: 'pointer',
    },
});

export default function NotificationsDropdown() {
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const isMenuOpen = Boolean(anchorEl);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const notifications = useSelector(getNotifications);
    const notificationCount = useSelector(getNotificationCount);
    const handleProfileMenuOpen = async (event) => {
        setAnchorEl(event.currentTarget);
        await dispatch(fetchNotifications({page: 1}));
    };
    useEffect(() => {
        return () => {
            dispatch(resetNotifications() )
            if(anchorEl ===null)
            {
                dispatch(resetNotifications() )
            }
        };
    }, [anchorEl]);


    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    async function redirectNotification(notification) {
        if (!notification.is_read) {
            await dispatch(readNotification({id: notification.id}));
        }
        setAnchorEl(null);

        if (notification.type === "recruitment_request") {
            dispatch(setRecruitmentRequestShowId(notification.recruitment_id))
          navigate({
                pathname: `/recruitments`,
            });
        } else if (notification.type === "recruitment_plan") {

            dispatch(setRecruitmentPlanShowID(notification.recruitment_id))
            navigate({
                pathname: '/recruitment-plan/',
            })
        }
    }

    const handleMarkAllRead = () => {
        dispatch(readAllNotification())
    };

    const [hasNextPage, setHasNextPage] = useState(false);
    useEffect(() => {
        let isNextPage = isEmpty(notifications) ? false : parseInt(notifications?.meta.current_page) <
            parseInt(notifications?.meta.last_page)
        setHasNextPage(isNextPage)
        return () => {
        };
    }, [notifications]);

    const [sentryRef, {rootRef}] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: handleLoadMore,
        rootMargin: '0px 0px 300px 0px',
    });

    const menuId = 'dropdowns-notifications-dropdown-id';
    const renderMenu = (notifications, notificationCount) => {
        if (!isEmpty(notifications)) {
            return (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    id={menuId}
                    keepMounted
                    ref={rootRef}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={isMenuOpen}
                    style={{height: '400px'}}
                    onClose={handleMenuClose}
                    classes={{
                        list: classes.menuList,
                        paper: classes.menuPaper,
                    }}
                >
                    <Box padding="1rem">
                        <Typography
                            variant="h6"
                            component="h6"
                            className={classes.typographyH6}
                        >
                            Bạn có{' '}
                            <Box component="strong" color={theme.palette.info.main}>
                                {parseInt(notificationCount) >= 0 ? notificationCount : 0}
                            </Box>{' '}
                            thông báo chưa đọc.
                        </Typography>
                    </Box>
                    {
                        notificationCount > 0
                            ? (<Button onClick={() => handleMarkAllRead()}
                                       className={classes.buttonMarkReadAll}><CheckIcon
                                className={classes.iconMarkReadAll}/> Đã đọc tất
                                cả</Button>)
                            : null
                    }
                    <List disablePadding>
                        {notifications.data.map((prop, key) => {
                            return (
                                <ListItem key={key}
                                          className={`pr-0 ${classes.listItemRoot} ${classes.notification_wrap}`}
                                          title={prop.content}
                                          onClick={() => redirectNotification(prop)}
                                          component="a">
                                    <Grid container alignItems="center"
                                          className={classes.notification_box}>
                                        <Box
                                            display={'flex'}
                                            width="auto"
                                            maxWidth="400px"
                                            minWidth="400px"
                                            position="relative"
                                            paddingRight="15px"
                                            paddingLeft="15px"
                                            minHeight="1px"
                                        >
                                            <Box width={'30px'}>
                                                <WorkIcon/>
                                            </Box>

                                            <Box width={'330px'}
                                                 className={classes.notification_content}
                                                 fontSize={'1rem'}>
                                                {prop.content}
                                            </Box>
                                            <Box width={'30px'}
                                                 textAlign={'end'}
                                                 fontSize={'0.9rem'}>

                                                {!prop.is_read ? <LensIcon/> : ''}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </ListItem>
                            );
                        })}

                        {(loading || hasNextPage) && (
                            <ListItem ref={sentryRef}>
                                Loading...
                            </ListItem>
                        )}
                    </List>
                </Menu>
            );
        } else {
            return (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    id={menuId}
                    keepMounted
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={isMenuOpen}
                    style={{height: '400px'}}
                    onClose={handleMenuClose}
                    classes={{
                        list: classes.menuList,
                        paper: classes.menuPaper,
                    }}
                >
                    <Box padding="1rem">
                        <Typography
                            variant="h4"
                            component="h4"
                            className={classes.typographyH6}
                        >
                            Bạn không có thông báo.
                        </Typography>
                    </Box>
                </Menu>
            );
        }
    };


    async function handleLoadMore() {
        let nextPage = notifications?.meta?.current_page ? parseInt(notifications?.meta?.current_page) + 1 : 1;
        setLoading(true)
        await dispatch(fetchNotifications({page: nextPage}));
        setLoading(false)
    }

    return (
        <>
            <IconButton
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={(e) => handleProfileMenuOpen(e)}
                classes={{
                    label: classes.buttonLabel,
                }}
            >
                {
                    parseInt(notificationCount) > 0
                        ? (<span className={classes.badge_notification}>
                        {notificationCount ?
                        (parseInt(notificationCount) >= 0 ? notificationCount : 0)
                        : 0}
                        </span>)
                        : ''
                }
                <Box
                    component={Notifications}
                    width="1.7rem!important"
                    height="1.7rem!important"
                />
            </IconButton>
            {renderMenu(notifications, notificationCount)}
        </>
    );
}
