import React, {useEffect, useState} from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// @material-ui/icons components

// core components
import componentStyles from 'assets/theme/views/admin/dashboard.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import {Paper, Grid, Tab, Tabs, Card} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import moment from 'moment';
import InterviewIndexStatistic
    from '../../../components/StatisticInterview/InterviewIndexStatistic';
import InterviewChartStatistic
    from '../../../components/StatisticInterview/InterviewChartStatistic';
import InterviewTableStatistic
    from '../../../components/StatisticInterview/InterviewTableStatistic';
import {
    fetchInterviewStatisticChart, fetchInterviewStatisticGrowth,
    fetchInterviewStatisticIndex,
} from '../../../features/statistic/interviewStatisticSlice';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles(componentStyles);
const statisticsStyle = makeStyles(() => ({

    breadcrumbIcon: {
        width: '20px',
        height: '22px',
        paddingBottom: '2px',
        fontWeight: 'bold',
    },
    link: {
        color: '#7C7E7F',
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: '.875rem',
        marginLeft: '-8px',
    },
    root: {
        flexGrow: 1,
        boxShadow: 'none',
        // backgroundColor: theme.palette.background.paper,
    },
    tabCustom: {
        '&.Mui-selected': {
            backgroundColor: '#5aceef !important',
        },
    },

    tabButton: {
        '&.Mui-selected': {
            backgroundColor: '#5aceef !important',
        },
    },
    breadCrumb: {
        fontSize: '.875rem',
    },
}));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function InternviewStatistic() {
    const classes = useStyles();
    const staticsClasses = statisticsStyle();
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        async function setUp() {
            dispatch(fetchInterviewStatisticIndex({type: 'month'}));
            document.title = 'Thống kê kết quả tuyển dụng';
        }
        setUp();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const changeTab = async (value) => {
        setLoading(true);
        if (value === 'index') {
            await dispatch(fetchInterviewStatisticIndex({type: 'month'}));
        } else if (value === 'chart') {
            let currentYear = parseInt((new Date()).getFullYear());
            await dispatch(fetchInterviewStatisticChart({year: currentYear}));
        } else if (value === 'growth') {
            await dispatch(fetchInterviewStatisticGrowth({type: 'month'}));
        }
        setLoading(false);
    };

    return (
        <>
            {/* Page content */}
            <Container
                maxWidth={false}
                className={classes.flexGrow_1}
                classes={{root: classes.containerRoot}}
            >
                <Grid>
                    <Breadcrumbs aria-label="breadcrumb" className={'m-0'}>
                        <p className={'d-flex justify-content-center align-items-center m-0'}>
                            <EqualizerIcon
                                className={staticsClasses.breadcrumbIcon} />
                            <b className={staticsClasses.breadCrumb}>Thống
                                kê</b>
                        </p>
                        <p className={'d-flex justify-content-center align-items-center m-0'}>
                            <Link to={'/interview-statistic'}
                                  className={staticsClasses.link}>Kết quả tuyển
                                dụng</Link>
                        </p>
                    </Breadcrumbs>
                </Grid>

                <Card>
                    <Paper square className={staticsClasses.root}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label="icon tabs example"
                        >
                            <Tab className={staticsClasses.tabButton}
                                 onClick={() => changeTab('index')}
                                 label="Chỉ số" {...a11yProps(0)} />
                            <Tab className={staticsClasses.tabButton}
                                 onClick={() => changeTab('chart')}
                                 label="Biểu đồ" {...a11yProps(1)} />
                            <Tab className={staticsClasses.tabButton}
                                 onClick={() => changeTab('growth')}
                                 label="Thống kê tăng trưởng" {...a11yProps(
                                2)} />
                        </Tabs>
                    </Paper>
                    <TabPanel value={value} index={0}>
                        <h2>Kết quả tuyển dụng tháng {moment().
                            format('MM-YYYY')}</h2>
                        {loading ? (
                            <div>
                                Loading...
                            </div>
                        ) : (
                            <InterviewIndexStatistic />
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <h2>Biểu đồ đường</h2>
                        <InterviewChartStatistic />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <h2>Thống kê tăng trưởng</h2>
                        {loading ? (
                            <div>
                                Loading...
                            </div>
                        ) : (
                            <InterviewTableStatistic />
                        )}
                    </TabPanel>
                </Card>
            </Container>
        </>
    );
}

export default InternviewStatistic;
