import React from "react";
import PropTypes from "prop-types";

const ProgressBar = ({ bgcolor, percent }) => {

    const containerStyles = {
        height: 22,
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        display: 'flex',
        position: 'relative'
    }

    const fillerStyles = {
        height: '100%',
        width: `${percent}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        paddingTop: 2
    }

    const labelStyles = {
        color: '#000',
        position: 'absolute',
        fontWeight: 'bold',
        left:'70px'
    }

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${percent}%`}</span>
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    bgcolor: PropTypes.string,
    percent: PropTypes.number,
};

export default ProgressBar;