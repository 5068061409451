const componentStyles = (theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  },
  gridItemRoot: {
    [theme.breakpoints.up('xl')]: {
      marginBottom: '0!important',
    },
  },
  flexGrow_1: {
    flexGrow: 1,

  },

  notfoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },

  searchBoxWrap: {
    marginRight: '10px',
    marginBottom: '10px',
    width: '15rem',
  },

});

export default componentStyles;
