import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";

import UpdateInterviewModal from './UpdateInterviewModal';
import ShowInterviewModal from './ShowInterviewModal';
import { isEmpty } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchInterview,
    getInterviews,
} from '../../../../features/processes/interviewSlice';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    iconColor: {
        color: '#ffffff',
    },
    edit: {
        borderRadius: '50%',
        marginLeft: '5px',
        backgroundColor: '#ff9800',
        '&:hover': {
            backgroundColor: 'rgba(255,152,0,0.78)',
        },
    },
    cancel: {
        borderRadius: '50%',
        marginLeft: '5px',
        backgroundColor: '#F44336',
        '&:hover': {
            backgroundColor: '#f16156',
        },
    },
    show: {
        borderRadius: '50%',
        backgroundColor: '#00bcd4',
        '&:hover': {
            backgroundColor: 'rgba(0,188,212,0.85)',
        },
    },
    vertical_bl: {
        verticalAlign: 'middle',
    },
    buttonDisabled: {
        opacity: ".65",
        boxShadow: "none!important",
        cursor: 'not-allowed !important',
        pointerEvents: "none",
    },
});


export default function InterviewList(props) {
    const classes = useStyles();


    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [interviewUpdate, setInterviewUpdate] = useState({});
    const [interviewShow, setInterviewShow] = useState({});
    const interviews = useSelector(getInterviews);
    const dispatch = useDispatch();
    const handleUpdate = (item) => {
        setShowUpdateModal(true)
        setInterviewUpdate(item)
    }

    const handleShow = (item) => {

        setShowDetailModal(true)
        setInterviewShow(item)
    }

    const handleChange = async (event, value) => {
        let data = {
            page: value,
            name: props.nameProp,
            status: props.status,
            school: props.schoolSearch,
            cv_tag_id: props.cvTagSearch,
            recruitment_plan_id: props.recruitmentPlanId,
            created_from_date: props.startDateSearch,
            created_to_date: props.endDateSearch,
            interview_from_date: props.startInterviewDate,
            interview_to_date: props.endInterviewDate,
            internship_from_date: props.startInternshipDate,
            internship_to_date: props.endInternshipDate,
        };
        await dispatch(fetchInterview(data));
    };

    const getPagination = (paginate) => {
        if (paginate) {
            return (
                <Pagination
                    count={paginate.last_page}
                    onChange={handleChange}
                    color="primary" variant="outlined"
                    boundaryCount={5}
                    size={'small'}
                    page={paginate.current_page} />);
        }
    };


    const renderInterviews = (interviews) => {
        if (!isEmpty(interviews) && !isEmpty(interviews?.data) && interviews.data.length > 0) {
            let meta = interviews.meta;
            return interviews.data.map((item, index) => {
                let iterator = (index + 1) + ((meta.current_page - 1) * meta.per_page);
                let userCvTag = '';
                let cvTags = item.cv_tags;
                if (cvTags.length > 1) {
                    userCvTag = '';
                    cvTags.forEach((r, i) => {
                        userCvTag += i === cvTags.length - 1 ?
                            r.name_vn :
                            r.name_vn + ', ';
                    })
                        ;
                } else if (cvTags.length === 1) {
                    userCvTag = cvTags?.[0].name_vn;
                }
                return (
                    <TableRow key={`interview-${item.id}`}>
                        <TableCell align={"center"} className={`${classes.vertical_bl}`} component="th" scope="row">
                            {iterator}
                        </TableCell>
                        <TableCell align="center" className={`${classes.vertical_bl}`}>{item.name}</TableCell>
                        <TableCell align="center" className={`${classes.vertical_bl}`}>{item.position ?? 'NA'}</TableCell>
                        <TableCell align="center" className={`${classes.vertical_bl}`}>{item.email}</TableCell>
                        <TableCell align="center" className={`${classes.vertical_bl}`}>{item.phone}</TableCell>
                        <TableCell align="center" className={`${classes.vertical_bl}`}>
                            {item.is_pass == null || item.is_pass === " " ? 'NA' :
                                (
                                    (item.is_pass === 1 || item.is_pass === "1")
                                        ? <span className={'text-success'}>Pass</span>
                                        : <span className={'text-danger'}>Fail</span>
                                )
                            }
                        </TableCell>
                        <TableCell align="center" className={`${classes.vertical_bl}`}>
                            {
                                userCvTag
                            }
                        </TableCell>
                        <TableCell align="center" className={`${classes.vertical_bl}`}>{item.status}</TableCell>
                        <TableCell align="center">
                            <IconButton
                                onClick={() => handleShow(item)}
                                className={classes.show}>
                                <VisibilityIcon
                                    className={classes.iconColor} />
                            </IconButton>
                            <IconButton
                                onClick={() => handleUpdate(item)}
                                className={classes.edit}>
                                <EditIcon
                                    className={classes.iconColor} />
                            </IconButton>
                        </TableCell>
                    </TableRow>)
            })
        } else {
            return (
                <TableRow align="center">
                    <TableCell width="5rem">
                        <Typography variant="subtitle2">No data.</Typography>
                    </TableCell>
                </TableRow>
            );
        }
    }


    return (
        <>
            <TableContainer component={Paper} style={{ margin: '20px 0' }}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">STT</TableCell>
                            <TableCell align="center">Tên ứng viên</TableCell>
                            <TableCell align="center">Vị trí</TableCell>
                            <TableCell align="center">Email&nbsp;</TableCell>
                            <TableCell align="center">Số điện thoại&nbsp;</TableCell>
                            <TableCell align="center">Kết quả&nbsp;</TableCell>
                            <TableCell align="center">Cv tag&nbsp;</TableCell>
                            <TableCell align="center">Trạng thái&nbsp;</TableCell>
                            <TableCell align="center">Hành động&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderInterviews(interviews)}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box justifyContent="flex-start" display="flex"
                style={{ margin: '20px 0' }}>
                {getPagination(interviews?.meta)}
            </Box>
            <UpdateInterviewModal
                showUpdateModal={showUpdateModal}
                setShowUpdateModal={setShowUpdateModal}
                interviewUpdate={interviewUpdate}
                setInterviewUpdate={setInterviewUpdate}
            />

            <ShowInterviewModal
                showDetailModal={showDetailModal}
                setShowDetailModal={setShowDetailModal}
                interviewDetail={interviewShow}
            />
        </>
    );
}

InterviewList.propTypes = {
    nameProp: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    schoolSearch: PropTypes.string.isRequired,
    recruitmentPlanId: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]),
    cvTagSearch: PropTypes.string.isRequired,
    startDateSearch: PropTypes.string,
    startInterviewDate: PropTypes.string,
    startInternshipDate: PropTypes.string,
    endInterviewDate: PropTypes.string,
    endInternshipDate: PropTypes.string,
    endDateSearch: PropTypes.string,
};