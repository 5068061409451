import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  FormControlLabel,
  FormGroup,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { GreenCheckbox } from '../Forms/Checkboxs/GreenCheckbox';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { getCvTags } from '../../features/processes/interviewSlice';

const useStyles = makeStyles((theme) => ({
  hrStyle: {
    marginTop: 'none',
    marginBottom: 'none',
    width: '16rem',
    position: 'absolute',
    top: '0',
    borderTop: '5px solid orange',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '15.5rem',
  },
  validate: {
    color: 'red',
  },
  buttonAdd: {
    backgroundColor: '#2DCE89',
    color: 'white',
    fontSize: '12px',
    marginTop: '0.3rem',
    '&&:hover': {
      backgroundColor: '#2DCE89',
      color: 'white',
      fontSize: '12px',
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1000,
    color: '#525f7f',
    right: '7px',
    top: '4px',
  },
  divButtonAddStyle: {
    marginTop: '0.9rem',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '18.75rem',
  },
  linkCV: {
    '&&:hover': {
      textDecoration: 'underline',
    },
  },
  closeIconStyle: {
    width: '24px',
    height: '24px',
  },
  marginTitleRecruitment: {
    marginBottom: '0',
    fontSize: '1.2rem',
  },
  inputStyle: {
    border: '1px solid #ced4da',
    borderRadius: 4,
    width: '18.75rem',
  },
  selectStyle: {
    border: '1px solid #ced4da',
    borderRadius: 4,
    width: '18.75rem',
  },
  labelCheckbox: {
    color: '#525f7f',
  },

}));

function ShowInformation(props) {
  const classes = useStyles();
  const cvTags = useSelector(getCvTags);

  const renderCvTags = (cvTags) => {
    let result = '';

    if (cvTags && cvTags.length > 0) {
      result = cvTags.map(item => {
        return (
          <FormControlLabel
            key={`${item.id}-search`}
            className={'m-0 d-flex'}
            control={
              <GreenCheckbox
                style={{ padding: '3px' }}
                disabled={true}
                checked={props.cvTag[`${item.id}`]}
                value={item.id}
                name={item.name} />
            }
            label={
              <Typography
                className={classes.labelCheckbox}>{item.name_vn}</Typography>
            }
          />
        );
      });
    }

    return result;
  };

  return (
    <>
      <h3 className={'position-relative'}>Thông tin
        <hr className={classes.hrStyle} />
      </h3>

      <Grid className={'d-flex flex-row justify-content-between py-2'}>
        <Grid className={`w-50`}>
          <p className={'mb-0 font-weight-600'}>Họ và tên ứng
            viên: </p>
          <p>{props.name ?? 'NA'}</p>

        </Grid>
        <Grid className={`w-50`}>
          <p className={'mb-0 font-weight-600'}>Email: </p>
          <p>{props.email ?? 'NA'}</p>
        </Grid>
      </Grid>


      <Grid className={'d-flex flex-row justify-content-between pb-2'}>
        <Grid className={`w-50`}>
          <p className={'mb-0 font-weight-600'}>Số điện thoại: </p>
          <p>{props.phone ?? 'NA'}</p>
        </Grid>
        <Grid className={`w-50`}>
          <p className={'mb-0 font-weight-600'}>Link CV:</p>
          <a target="_blank" rel="noreferrer noopener"
            href={props.linkCv} className={classes.linkCV}>Xem</a>
        </Grid>
      </Grid>

      <Grid className={'d-flex flex-row justify-content-between'}>
        <Grid className={`w-50`}>
          <p className={'mb-0 font-weight-600'}>Thời gian hẹn phỏng
            vấn: </p>
          <p>{props.interviewDate ?? 'NA'}</p>
        </Grid>
        <Grid className={`w-50`}>
          <p className={'mb-0 font-weight-600'}>Kế hoạch tuyển
            dụng: </p>
          <p>{props.recruitmentPlanName ?? 'NA'}</p>
        </Grid>
      </Grid>
      <Grid className={'d-flex flex-row justify-content-between'}>
        <Grid className={`w-50`}>
          <p className={'mb-0 font-weight-600'}>Trường học: </p>
          <p>{props.school ?? 'NA'}</p>
        </Grid>
        <Grid className={`w-50`}>
          <p className={'mb-0'}><b>CV Tag:</b></p>
          <FormGroup className={'mb-0'}>
            {renderCvTags(cvTags)}
          </FormGroup>
        </Grid>
      </Grid>
      <Grid className={'d-flex flex-row justify-content-between'}>
        <Grid className={`w-50`}>
          <p className={'mb-0 font-weight-600'}>Vị trí: </p>
          <p>{props.position ?? 'NA'}</p>
        </Grid>
        <Grid className={`w-50`}>
        </Grid>
      </Grid>
    </>
  );
}

ShowInformation.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  linkCv: PropTypes.string,
  school: PropTypes.string,
  position: PropTypes.string,
  recruitmentPlanId: PropTypes.string,
  interviewDate: PropTypes.string,
  recruitmentPlanName: PropTypes.string,
  cvTag: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default ShowInformation;