import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Admin from '../../layouts/Admin';
import routes, {errorRoutes} from '../routes';
import ProtectedRoute from './ProtectedRoute';

const AdminRouter = () => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return (
            <Route
                path={prop.path}
                index={prop.name === "Dashboard"}
                key={key}
                element={
                  <ProtectedRoute permission={prop.permission}
                                  role={prop.role}>
                    {prop.component()}
                  </ProtectedRoute>
                }
            />
        );
      } else {
        return null;
      }
    });
  };
  return (
      <Routes>
        <Route path={'/'} element={<Admin />}>
          {getRoutes(routes)}
          {getRoutes(errorRoutes)}
        </Route>
      </Routes>
  );
};

export default AdminRouter;
