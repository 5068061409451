import React, {useEffect} from 'react';
import {useLocation, Outlet} from 'react-router-dom';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
// @material-ui/icons components

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import routes from '../routers/routes';

import componentStyles from 'assets/theme/layouts/admin.js';
import PropTypes from 'prop-types';
import {getUserRequest, updateUserLocal} from '../services/authService';
import {useAuth} from '../contexts/AuthContext';

const useStyles = makeStyles(componentStyles);
//
const Admin = () => {
    const {setUser} = useAuth();

    useEffect(() => {
        async function updateUserInformationLogin() {
            let response = await getUserRequest();
            let user = response?.data?.data;
            updateUserLocal(user).then(() => {
                setUser(user);
            }).catch(() => {});
        }

        updateUserInformationLogin();

    }, []);

    const classes = useStyles();
    const location = useLocation();

    const [sidebarOpenResponsive, setSidebarOpenResponsive] = React.useState(
        false,
    );

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        // mainContent.current.scrollTop = 0;
    }, [location]);

    return (
        <>
            <Box display="flex" className={'full-height'}>
                <Sidebar
                    routes={routes}
                    openResponsive={sidebarOpenResponsive}
                    closeSidebarResponsive={() => setSidebarOpenResponsive(
                        false)}
                    logo={{
                        innerLink: '/',
                        imgSrc: require(
                            '../assets/img/brand/deha-academy.png').default,
                        imgAlt: '...',
                    }}
                />
                <Box position="relative" flex="1"
                     className={classes.mainContent + ' ' +
                         classes.mainContentCustom}>
                    <AdminNavbar
                        openSidebarResponsive={() => setSidebarOpenResponsive(
                            true)}
                    />
                    <Outlet />
                    <Container
                        maxWidth={false}
                        component={Box}
                        classes={{root: classes.containerRoot}}
                    >
                        <AdminFooter />
                    </Container>
                </Box>
            </Box>
        </>
    );
};

export default Admin;

Admin.propTypes = {
    children: PropTypes.any,
};