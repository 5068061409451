import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditInformation from '../../../../components/Interview/EditInformation';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
    createInterview, fetchInterview, resetErrors,
} from '../../../../features/processes/interviewSlice';
import { NotificationContainer, NotificationManager } from 'react-notifications';


const useStyles = makeStyles((theme) => ({
    hrStyle: {
        marginTop: "none",
        marginBottom: "none",
        width: "16rem",
        position: "absolute",
        top: "4rem",
        borderTop: "5px solid orange"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '15.5rem',
    },
    validate: {
        color: 'red',
    },
    buttonAdd: {
        backgroundColor: '#2DCE89',
        color: 'white',
        fontSize: '12px',
        marginTop: "0.3rem",
        '&&:hover': {
            backgroundColor: '#2DCE89',
            color: 'white',
            fontSize: '12px',
        },
    },
    closeButton: {
        position: 'absolute',
        zIndex: 1000,
        color: '#525f7f',
        right: '7px',
        top: '4px',
    },
    divButtonAddStyle: {
        marginTop: "0.9rem"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    closeIconStyle: {
        width: '24px',
        height: '24px'
    },
    marginTitleRecruitment: {
        marginBottom: "0.4rem",
        fontSize: "1.2rem"
    },
    inputStyle: {
        border: '1px solid #ced4da',
        borderRadius: 4,
        width: '18.75rem',
    },
    selectStyle: {
        border: '1px solid #ced4da',
        borderRadius: 4,
        width: '18.75rem',
    },
}));

export default function AddInterviewModal(props) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [linkCv, setLinkCv] = useState('');
    const [school, setSchool] = useState('');
    const [position, setPosition] = useState('');
    const [recruitmentPlanId, setRecruitmentPlanId] = useState(' ');
    // const dateEndDefault = moment().add(1, 'days').format('YYYY-MM-DD H:mm');
    const [interviewDate, setInterviewDate] = useState(null);
    const [cvTag, setCVTag] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            setName('')
            setEmail(''),
                setInterviewDate(null)
            setRecruitmentPlanId(' ')
            setLinkCv('')
            setSchool('')
            setPhone('')
            setCVTag({})
            setPosition('')
            dispatch(resetErrors())
        };
    }, [props.showAddRecruitmentManageModal]);

    const handleClose = (event) => {
        event.preventDefault()
        props.setShowAddRecruitmentManageModal(false)
    };

    const formatInterviewDate = () => {
        return interviewDate ? moment(interviewDate, 'YYYY-MM-DD H:mm').format('YYYY-MM-DD H:mm')
            : null;
    }

    const handleCreateInterview = async () => {
        let cvTagData = [];
        for (let tag in cvTag) {
            if (cvTag?.[`${tag}`] === true) {
                cvTagData.push(tag)
            }
        }
        let data = {
            name,
            email,
            phone,
            school: school?.value ?? '',
            interview_date: formatInterviewDate(),
            cv_link: linkCv,
            recruitment_plan_id: recruitmentPlanId === " " ? null : recruitmentPlanId,
            cv_tags: cvTagData,
            position: position?.value ?? '',
        }

        setLoading(true)
        let response = await dispatch(createInterview(data))
        if (response?.payload?.status === 200) {
            props.setShowAddRecruitmentManageModal(false);
            NotificationManager.success('Thành công!', '', 1500);
            dispatch(resetErrors())
            await dispatch(fetchInterview())

        }
        setLoading(false)
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.showAddRecruitmentManageModal}
                onClose={handleClose}
                maxWidth={'lg'}
                style={{ width: "46rem" }}
                className={'m-auto createInterviewModal'}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <IconButton onClick={handleClose} size={'medium'}
                        className={classes.closeButton}>
                        <CloseIcon fontSize={'large'}
                            className={classes.closeIconStyle} />
                    </IconButton>
                    <h2>Thông tin ứng viên</h2>
                    <EditInformation
                        name={name}
                        setName={setName}
                        phone={phone}
                        setPhone={setPhone}
                        email={email}
                        setEmail={setEmail}
                        linkCv={linkCv}
                        setLinkCv={setLinkCv}
                        school={school}
                        setSchool={setSchool}
                        recruitmentPlanId={recruitmentPlanId}
                        setRecruitmentPlanId={setRecruitmentPlanId}
                        interviewDate={interviewDate}
                        setInterviewDate={setInterviewDate}
                        cvTag={cvTag}
                        setCVTag={setCVTag}
                        position={position}
                        setPosition={setPosition}
                    />

                    <div align={'right'} className={classes.divButtonAddStyle}>
                        <Button onClick={() => handleCreateInterview()} disabled={loading} className={classes.buttonAdd} align={'right'}>
                            <span>{loading ? 'Đang xử lý' : 'Thêm'}</span>
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            <NotificationContainer />
        </div>
    );
}

AddInterviewModal.propTypes = {
    setShowAddRecruitmentManageModal: PropTypes.func,
    showAddRecruitmentManageModal: PropTypes.bool,
    recruitmentManageCreate: PropTypes.object,
};

