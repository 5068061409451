import React from 'react';
// @material-ui/core components
// @material-ui/icons components

// core components

import {Table} from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {makeStyles} from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {useDispatch} from 'react-redux';
import _ from 'lodash';
import {fetchInterviewStatisticGrowth} from '../../features/statistic/interviewStatisticSlice';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  tableContainer: {
    minWidth: '650px',
  },
  table: {
    marginBottom: 0,
  },
  edit: {
    borderRadius: '50%',
    marginLeft: '5px',
    backgroundColor: '#ff9800',
    '&:hover': {
      backgroundColor: 'rgba(255,152,0,0.78)',
    },
  },
  show: {
    borderRadius: '50%',
    backgroundColor: '#00bcd4',
    '&:hover': {
      backgroundColor: 'rgba(0,188,212,0.85)',
    },
  },
  iconColor: {
    color: '#ffffff',
  },
  valignBt: {
    verticalAlign: 'bottom !important',
  },

  statisticChartWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },

  statisticChartItem: {
    width: '10px',
    height: '30px',
    backgroundColor: '#5aceef',
    border: '1px solid #5aceef',
    margin: '0 3px',
  },

  btnWrap: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  prev: {
    '&:hover': {
      fontWeight: 'bold',
      '& $prevIcon': {
        backgroundColor: 'rgba(0,188,212,0.85)',
      },
    },
  },

  next: {
    '&:hover': {
      fontWeight: 'bold',
      '& $nextIcon': {
        backgroundColor: 'rgba(0,188,212,0.85)',
      },
    },
  },

  prevIcon: {
    marginBottom: '2px',
  },

  nextIcon: {
    marginBottom: '2px',
  },

  button: {
    borderRadius: '50%',
    backgroundColor: '#aaa',
    margin: '0 6px',
    marginBottom: '2px',
    padding: 0,
  },

  iconNavigate: {
    width: '20px',
    height: '20px',
    color: '#fff',
  },
  buttonGroup: {
    display: 'flex',
    width: '190px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  indexTitle: {
    width: '90px',
    marginBottom: 0,
  },
  title: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginBottom: '0',
  },

  disable:{
    opacity: 0.8,
    pointerEvents:'all !important',
    cursor:'not-allowed !important'
  },
  disableIcon :{
    opacity:0.8,
    '&:hover':{
      backgroundColor:'#aaa',
      cursor:'not-allowed !important'
    }
  },
  titleColumn: {
    width:'20%'
  }
});

function InterviewTableStatisticItem(props) {
  const classes = useStyles();
  const statisticGrowth = props.interviewStatisticGrow;
  const MAX_HEIGHT_GROWTH_CHART = 40;
  const dispatch = useDispatch();
  const getHeightCharItem = (height = 10) => {
    return {
      height: `${height}px`,
    };
  };


  function getGrowthCell(dataGrowth, growProperty) {

    let arrProperties = dataGrowth.map(item => item[`${growProperty}`]);
    let maxPro = _.max(arrProperties);
    let time = (Date).now();
    return (<TableCell key={growProperty} className={classes.valignBt}
                       align="center">
      <div className={classes.statisticChartWrap}>
        {
          statisticGrowth.data.map(item => {
            time += 10;
            let heightChart = parseFloat(
                ((+(item[`${growProperty}`]) / maxPro) *
                    MAX_HEIGHT_GROWTH_CHART).toFixed(2));

            return (
                <div key={`item-growth-${growProperty}-${time}`}
                     className={`${classes.statisticChartItem}`}
                     style={getHeightCharItem(heightChart)}>
                </div>);
          })
        }
      </div>
    </TableCell>);
  }

  const renderTitle = (statisticGrowth, type) => {
    if (statisticGrowth && statisticGrowth?.data &&
        statisticGrowth.data.length > 0) {
      return statisticGrowth.data.map(item => {
        let title = `${item.type_vn} ${item.month}-${item.year}`;
        if (type === "quarter")
        {
          title = `${item.type_vn} ${item.quarter}-${item.year}`;
        }else if(type === "year"){
          title = `${item.type_vn} ${item.year}`;

        }
        return (
            <TableCell key={`title-${item.type}-${item.id}`} align="center"><p
                className={classes.title}>{title}</p>
            </TableCell>
        );
      });
    }
  };

  const renderInternStatistic = (statisticGrowth, propertyName) => {
    let result = '';
    if (statisticGrowth && statisticGrowth?.data &&
        statisticGrowth.data.length > 0) {
      let growCell = getGrowthCell(statisticGrowth.data,
          propertyName);
      result = statisticGrowth.data.map(item => {
        return (
            <TableCell key={`enroll-${item.type}-${item.id}`}
                       className={classes.valignBt}
                       align="center"> {item[`${propertyName}`]}</TableCell>
        );
      });

      result.push(growCell);
    }
    return result;
  };

  const changePage = async(start_date, type, flag) =>{
    if(!flag)
    {
      await dispatch(fetchInterviewStatisticGrowth({
        start_date,
        type
      }))

    }

  }

  const renderAction = (statistic) => {
    let result = '';
    if (statistic ) {
      result = (<div className={`${classes.buttonGroup}`}>
        <div className={`${classes.btnWrap} ${statistic.is_prev ? classes.prev: classes.disable}`}
             onClick={()=>changePage(statistic.prev_date, props.type, !statistic.is_prev)}
        >
          <IconButton className={`${classes.button} ${classes.prevIcon} ${statistic.is_prev ?'': classes.disableIcon}`}>
            <ChevronLeftIcon className={classes.iconNavigate}/>
          </IconButton>
          <span className={classes.title}>Trước</span>
        </div>

        <div className={`${classes.btnWrap} ${statistic.is_next ? classes.next: classes.disable}`}
             onClick={()=>changePage(statistic.next_date, props.type, !statistic.is_next)}
        >
          <span className={classes.title}>Sau</span>
          <IconButton
              className={`${classes.button} ${classes.nextIcon} ${statistic.is_next ? '': classes.disableIcon}`}>
            <ChevronRightIcon className={classes.iconNavigate}/>
          </IconButton>
        </div>
      </div>);
    }

    return result;

  };

  return (
      <>

        <TableContainer component={'div'} style={{margin: '20px 0'}}
                        className={classes.tableContainer}>
          {statisticGrowth && statisticGrowth?.data && statisticGrowth?.data.length < 1
              ? 'No data.'
              :(
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className={classes.titleColumn}>
                          <div className={classes.btnWrap}>
                            <p className={`${classes.indexTitle} ${classes.title}`}>
                              Chỉ tiêu</p>
                            {renderAction(statisticGrowth)}
                          </div>
                        </TableCell>

                        {renderTitle(statisticGrowth, props.type)}
                        <TableCell align="center"><p className={classes.title}>Tăng
                          trưởng</p>
                        </TableCell>
                      </TableRow>

                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={`${classes.valignBt} ${classes.titleColumn}`} align="left">
                          Số CV mới
                        </TableCell>
                        {renderInternStatistic(statisticGrowth,
                            'amount_new_interview')}
                      </TableRow>
  
                      <TableRow>
                        <TableCell className={`${classes.valignBt} ${classes.titleColumn}`} align="left">
                          Số CV phỏng vấn
                        </TableCell>
                        {renderInternStatistic(statisticGrowth,
                          'amount_interview_count')}
                      </TableRow>
                         <TableRow>
                        <TableCell className={`${classes.valignBt} ${classes.titleColumn}`} align="left">
                          Số ứng viên đã phỏng vấn
                        </TableCell>
                        {renderInternStatistic(statisticGrowth,
                            'amount_interviewed')}
                      </TableRow>

                      <TableRow>
                        <TableCell className={`${classes.valignBt} ${classes.titleColumn}`} align="left">
                          Số ứng không đến phỏng vấn
                        </TableCell>
                        {renderInternStatistic(statisticGrowth, 'amount_not_come_interview')}

                      </TableRow>

                      <TableRow>
                        <TableCell className={`${classes.valignBt} ${classes.titleColumn}`} align="left">
                          Số ứng viên PASS
                        </TableCell>
                        {renderInternStatistic(statisticGrowth, 'amount_passed_interview')}
                      </TableRow>

                      <TableRow>
                        <TableCell className={`${classes.valignBt} ${classes.titleColumn}`} align="left">
                          Số ứng viên FAIL
                        </TableCell>
                        {renderInternStatistic(statisticGrowth,
                            'amount_failed_interview')}
                      </TableRow>

                      <TableRow>
                        <TableCell className={`${classes.valignBt} ${classes.titleColumn}`} align="left">
                          Số ứng nhận việc
                        </TableCell>
                        {renderInternStatistic(statisticGrowth, 'amount_apply_intern')}
                      </TableRow>

                      <TableRow>
                        <TableCell className={`${classes.valignBt} ${classes.titleColumn}`} align="left">
                          Số ứng Không nhận việc
                        </TableCell>
                        {renderInternStatistic(statisticGrowth, 'amount_not_take_the_job')}
                      </TableRow>

                      <TableRow>
                        <TableCell className={`${classes.valignBt} ${classes.titleColumn}`} align="left">
                          Số ứng Chưa nhận việc
                        </TableCell>
                        {renderInternStatistic(statisticGrowth, 'amount_not_received_job')}
                      </TableRow>

                    </TableBody>
                  </Table>
              )
          }
        </TableContainer>
      </>
  );
}

InterviewTableStatisticItem.propTypes = {
  interviewStatisticGrow: PropTypes.object,
  type: PropTypes.string,
};

export default InterviewTableStatisticItem;
