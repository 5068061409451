import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useAuth} from '../../contexts/AuthContext';
import Proptypes from 'prop-types';
import LocalStorageService from '../../services/localStorageService';

const localStorageService = LocalStorageService.getService();

function ProtectedRoute({children , ...rest}) {
  const {authorize} = useAuth();
  const location = useLocation();
  const props = {...rest};
  const role = props.role;
  const permission = props.permission;
  if (authorize.isAuthorize() !== true && !localStorageService.isHasToken()) {
    localStorageService.clearToken();
    return <Navigate to={'/login'} state={{from: location}} />;
  } else if (authorize.isGuest()) {
    return <Navigate to={'/error-not-active'} state={{from: location}} />;
  } else if (!authorize.hasRolePermission(role, permission)) {
    return <Navigate to={'/error-forbidden'} state={{from: location}} />;
  } else {
    return children;
  }
}

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  children: Proptypes.any,
  role: Proptypes.string,
  permission: Proptypes.string,
};