import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core';
import PropTypes from "prop-types";
import {useDispatch} from 'react-redux';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {TextArea} from 'semantic-ui-react';
import {rejectRecruitmentPlan} from '../../../../../features/processes/recruitmentPlanSlice';

const useStyles = makeStyles({
  buttonYes: {
    backgroundColor: '#2DCE89',
    color: 'white',
    marginLeft: "1rem",
    fontSize: '12px',
    '&&:hover': {
      backgroundColor: '#2DCE89',
      color: 'white',
      fontSize: '12px',
    },
  },
  buttonNo: {
    backgroundColor: '#F44336',
    color: '#fff',
    marginRight: "1rem",
    fontSize: '12px',
    '&&:hover': {
      backgroundColor: '#F44336',
    },
  },
  textAreaEvaluate: {
    resize:'none',
    display:'-webkit-box',
    WebkitBoxOrient:'vertical',
    overflow: 'hidden',
    whiteSpace: 'normal',
    borderRadius:'3px',
    borderColor:'#c0c0c0',
    color:'#838383',
    transition:'all 0.5s ease',
    height:'95px',
    WebkitLineClamp:'6',
    width:'100%',
    '&::-webkit-input-placeholder': {
      color: '#83838385',
    },

    '&:-moz-placeholder': { /* Firefox 18- */
      color: '#83838385',
    },

    '&::-moz-placeholder': {  /* Firefox 19+ */
      color: '#83838385',
    },

    '&:-ms-input-placeholder': {
      color: '#83838385',
    },

    '&::placeholder': {
      color: '#83838385',
    },
  },

});

export default function RejectRecruitmentPlanModal(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setReason('')
    setError('')
    return () => {
    };
  }, []);

  const dispatch = useDispatch();
  const handleClose = (event) => {
    event.preventDefault();
    props.setShowRejectModal(false)
  };

  const handleSetReason = function(event) {
    setReason(event.target.value);
  }

  const reject = async ()=>{
    let data = {
      id: props.rejectRecruitmentPlanId,
      reason: reason
    }
    setLoading(true)
    if(reason.length >0)
    {
      await dispatch(rejectRecruitmentPlan(data))
      NotificationManager.success('Thành công!', '', 1500);
      props.setShowRejectModal(false)
      props.closeModalShowRecruitmentPlan()
    }else{
      setError('Lý do không được để trống')
    }
    setLoading(false)

  }
  return (
      <div>
        <Dialog
            fullScreen={fullScreen}
            open={props.showRejectModal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <h2 className={'text-center'}>Từ chối kế hoạch tuyển dụng?</h2>
            <p>Lý do <span className={'text-danger'}>*</span></p>
            <TextArea className={`${classes.textAreaEvaluate} `}
                      maxLength={100}
                      onFocus={event => event.target.select()}
                      placeholder={'Nhập lý do từ chối...'}
                      value={reason}
                      onChange={event => handleSetReason(event)}
                      rows={1}/>
            <span className={'text-danger'}>{error}</span>
            <div className={'text-center'} style={{marginTop: "2rem"}}>

              <Button disabled={loading} onClick={handleClose} className={classes.buttonNo}>
                Huỷ
              </Button>
              <Button disabled={loading} onClick={()=>reject()} className={classes.buttonYes}>
                {loading?'Đang xử lý':'Đồng ý'}
              </Button>
            </div>
          </DialogContent>

        </Dialog>
        <NotificationContainer />
      </div>
  );
}

RejectRecruitmentPlanModal.propTypes = {
  setShowRejectModal: PropTypes.func,
  closeModalShowRecruitmentPlan: PropTypes.func,
  showRejectModal: PropTypes.bool,
  rejectRecruitmentPlanId: PropTypes.object,
};