import React from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// @material-ui/icons components

// core components

import {Paper, Tab, Tabs} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchInternStatisticIndex,
  getInternStatisticIndex,
} from '../../features/statistic/internStatisticSlice';

const statisticsStyle = makeStyles(() => ({

  breadcrumbIcon: {
    width: '20px',
    height: '22px',
    paddingBottom: '2px',
    fontWeight: 'bold',
  },
  link: {
    color: '#7C7E7F',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  root: {
    flexGrow: 1,
    boxShadow: 'none',
    width: 'fit-content',
  },
  tabButton: {
    padding: '0 !important',
    minHeight: '30px',
    textTransform: 'capitalize',
    '&.Mui-selected': {
      backgroundColor: '#5aceef !important',
    },
  },

  w_max_content: {
    width: 'max-content',
  },

  tab_wrap: {
    minHeight:'310px'
  }
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box p={3}>
              <Typography component={'div'}>{children}</Typography>
            </Box>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function EducateIndex(props) {
  const educateStatisticData = props.internstatisticindex;

  return (
        <Grid className={'d-flex flex-row'} component={'div'}>
          <div className={statisticsStyle().w_max_content}>
            <p>Số thực tập sinh nhập học:</p>
            <p>Số thực tập sinh tốt nghiệp:</p>
            <p>Số thực tập sinh fail:</p>
            <p>Tỷ lệ Pass/Fail:</p>
            <p>Số thực tập sinh nghỉ thực tập:</p>
            <p>Điểm tốt nghiệp trung bình:</p>
            { props.type == 'month'
              ? (<p>Dự đoán số thực tập sinh lên team trong tháng:</p>)
              : ''
            }


          </div>
          <div className={`${statisticsStyle().w_max_content} pl-2`}>
            <p><b>{educateStatisticData?.amount_enrolled_interns}</b></p>
            <p><b>{educateStatisticData?.amount_pass}</b></p>
            <p><b>{educateStatisticData?.amount_fail}</b></p>
            <p><b>{educateStatisticData?.rate_pass_fail}</b></p>
            <p><b>{educateStatisticData?.amount_stopped_interns}</b></p>
            <p><b>{educateStatisticData?.average_pass_score}</b></p>
            { props.type == 'month'
              ? (<p><b>{educateStatisticData?.predict_join_team}</b></p>)
              : ''
            }
          </div>
        </Grid>
  );
}

EducateIndex.propTypes = {
  internstatisticindex: PropTypes.object,
  type: PropTypes.string,
};

function EducateIndexStatistic() {
  const staticsClasses = statisticsStyle();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('month');
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const internStatisticIndex = useSelector(getInternStatisticIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeTab = async (type) => {
    setLoading(true);
    setType(type)
    await dispatch(fetchInternStatisticIndex({
      type,
    }));
    setLoading(false);
  };
  return (
      <>
        <Paper square className={staticsClasses.root}>
          <Tabs
              value={value}
              onChange={handleChange}
          >
            <Tab className={staticsClasses.tabButton}
                 onClick={() => changeTab('month')}
                 label="Theo tháng" {...a11yProps(0)} />
            <Tab className={staticsClasses.tabButton}
                 onClick={() => changeTab('quarter')}
                 label="Theo quý" {...a11yProps(1)} />
            <Tab className={staticsClasses.tabButton}
                 onClick={() => changeTab('year')}
                 label="Theo năm" {...a11yProps(2)} />
            <Tab className={staticsClasses.tabButton}
                 onClick={() => changeTab('all')} label="Tất cả" {...a11yProps(
                3)} />
          </Tabs>
        </Paper>
        <TabPanel className={staticsClasses.tab_wrap} value={value} index={0}>
          {loading ? (
              <div>
                Loading...
              </div>
          ) : (
              <EducateIndex internstatisticindex={internStatisticIndex} type={type} />
          )}
        </TabPanel>
        <TabPanel className={staticsClasses.tab_wrap} value={value} index={1}>
          {loading ? (
              <div>
                Loading...
              </div>
          ) : (
              <EducateIndex internstatisticindex={internStatisticIndex}  type={type} />
          )}
        </TabPanel>
        <TabPanel className={staticsClasses.tab_wrap} value={value} internstatisticindex={internStatisticIndex}

                  index={2}>
          {loading ? (
              <div>
                Loading...
              </div>
          ) : (
              <EducateIndex internstatisticindex={internStatisticIndex}  type={type} />
          )}        </TabPanel>
        <TabPanel className={staticsClasses.tab_wrap} value={value} internstatisticindex={internStatisticIndex}
                  index={3}>
          {loading ? (
              <div>
                Loading...
              </div>
          ) : (
              <EducateIndex internstatisticindex={internStatisticIndex}  type={type} />
          )}          </TabPanel>
      </>
  );
}

export default EducateIndexStatistic;
