import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  makeStyles,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import ShowInformation from '../../../../components/Interview/ShowInformation';
import ShowPoint from '../../../../components/Interview/ShowPoint';
import {isEmpty} from 'lodash';


const useStyles = makeStyles((theme) => ({
  hrStyle: {
    marginTop: 'none',
    marginBottom: 'none',
    width: '16rem',
    position: 'absolute',
    top: '0',
    borderTop: '5px solid orange',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '15.5rem',
  },
  validate: {
    color: 'red',
  },
  buttonAdd: {
    backgroundColor: '#2DCE89',
    color: 'white',
    fontSize: '12px',
    marginTop: '0.3rem',
    '&&:hover': {
      backgroundColor: '#2DCE89',
      color: 'white',
      fontSize: '12px',
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1000,
    color: '#525f7f',
    right: '7px',
    top: '4px',
  },
  divButtonAddStyle: {
    marginTop: '0.9rem',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  closeIconStyle: {
    width: '24px',
    height: '24px',
  },
  marginTitleRecruitment: {
    marginBottom: '0.4rem',
    fontSize: '1.2rem',
  },
  inputStyle: {
    border: '1px solid #ced4da',
    borderRadius: 4,
    width: '18.75rem',
  },
  selectStyle: {
    width: '15rem',
  },
}));

export default function ShowInterviewModal(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [linkCv, setLinkCv] = useState('');
  const [school, setSchool] = useState('');

  const [status, setStatus] = useState(' ');
  const [isCome, setIsCome] = useState(' ');
  const [technicalScore, setTechnicalScore] = useState(50);
  const [softskillScore, setSoftskillScore] = useState(50);
  const [comment, setComment] = useState('');
  const [note, setNote] = useState('');
  const [isPass, setIsPass] = useState('Pass');

  const [recruitmentPlanId, setRecruitmentPlanId] = useState(' ');
  const [recruitmentPlanName, setRecruitmentPlanName] = useState(' ');
  // const dateEndDefault = moment().format('YYYY-MM-DD H:mm');
  const [interviewDate, setInterviewDate] = useState();
  const [internshipDate, setInternshipDate] = useState(' ');
  const [cvTag, setCVTag] = useState({});

  useEffect(() => {
    if(!isEmpty(props.interviewDetail))
    {
      let interview = props.interviewDetail;
      setName(interview.name)
      setIsCome(interview.is_come_interview)
      setTechnicalScore(interview.technical_score)
      setSoftskillScore(interview.softskill_score)
      setIsPass(interview.is_pass)
      setRecruitmentPlanId(interview.recruitment_plan_id)
      setSchool(interview.school)
      setRecruitmentPlanName(interview.recruitment_plan_name)
      setInterviewDate(interview.interview_date)
      setComment(interview.comment)
      setPhone(interview.phone)
      setLinkCv(interview.cv_link)
      setEmail(interview.email)
      setStatus(interview.status)
      setNote(interview.note)
      setInternshipDate(interview.internship_date)

      let cvTags = interview.cv_tags;
      let cvTagsResult = {};
      if (cvTags.length > 0) {
        [].forEach.call(cvTags, function(item) {
          let obj = {};
          obj[`${item.id}`] = true;
          cvTagsResult = {...cvTagsResult, ...obj};
        });
      }
      setCVTag(cvTagsResult);
    }

    return () => {

    };
  }, [props.interviewDetail]);

  const handleClose = (event) => {
    event.preventDefault();
    props.setShowDetailModal(false);
  };


  return (
      <div>
        <Dialog
            fullScreen={fullScreen}
            open={props.showDetailModal}
            onClose={handleClose}
            maxWidth={'lg'}
            style={{width: '46rem'}}
            className={'m-auto updateInterviewModal'}
            aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <IconButton onClick={handleClose} size={'medium'}
                        className={classes.closeButton}>
              <CloseIcon fontSize={'large'}
                         className={classes.closeIconStyle}/>
            </IconButton>
            <h2>Thông tin ứng viên</h2>
            <Grid className={'pb-2'}>
              <ShowInformation
                  name={name}
                  phone={phone}
                  email={email}
                  school={school}
                  linkCv={linkCv}
                  recruitmentPlanName={recruitmentPlanName}
                  recruitmentPlanId={recruitmentPlanId}
                  interviewDate={interviewDate}
                  cvTag={cvTag}
              />
            </Grid>
            <Grid className={'pb-2'}>
              <ShowPoint
                  isCome={isCome}
                  technicalScore={technicalScore}
                  softskillScore={softskillScore}
                  comment={comment}
                  isPass={isPass}
              />
            </Grid>
            <Grid >
              <h3 className={'position-relative'}>Trạng thái
                <hr className={classes.hrStyle}/>
              </h3>

              <Grid className={'d-flex flex-row justify-content-between pt-2'}>
                <Grid className={'w-50'}>
                  <p className={'mb-0 font-weight-600'}>Trạng thái:
                  </p>
                  <p>{status ?? 'NA'}</p>
                </Grid>

                <Grid className={'w-50'}>
                  <p className={'mb-0 font-weight-600'}>Lưu ý (nếu có):
                  </p>
                  <p>{note ?? 'NA'}</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={'d-flex pt-2'}>
              <Grid className={'w-50'}>
                {
                  status === "Đã hẹn ngày thực tập"
                    ?(<><p className={'mb-0 font-weight-600'}>Ngày nhận thực tập: </p>
                        <p>{internshipDate}</p> </>)
                      :''
                }
              </Grid>
              <Grid className={'w-50  d-flex justify-content-end align-items-end'}>

              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
  );
}

ShowInterviewModal.propTypes = {
  setShowDetailModal: PropTypes.func,
  showDetailModal: PropTypes.bool,
  interviewDetail: PropTypes.object,
};

