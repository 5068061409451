import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {useDispatch} from 'react-redux';
import {cancelRecruitmentRequest} from '../../../../features/processes/processSlice';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const useStyles = makeStyles({
    buttonYes: {
        backgroundColor: '#2DCE89',
        color: 'white',
        marginLeft: "1rem",
        fontSize: '12px',
        '&&:hover': {
            backgroundColor: '#2DCE89',
            color: 'white',
            fontSize: '12px',
        },
    },
    buttonNo: {
        backgroundColor: '#F44336',
        color: 'white',
        marginRight: "1rem",
        fontSize: '12px',
        '&&:hover': {
            backgroundColor: '#F44336',
            color: 'white',
            fontSize: '12px',
        },
    }
});

export default function CancelRecruitmentModal(props) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const handleClose = (event) => {
        event.preventDefault();
        props.setShowModalConfirmDelete(false)
    };

    const handleDelete = async ()=>{
        let data = {
            id: props.deleteRecruitment
        }
        setLoading(true)
        await dispatch(cancelRecruitmentRequest(data))
        NotificationManager.success('Thành công!', '', 1500);
        setLoading(false)

        props.setShowModalConfirmDelete(false)
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.showModalConfirmDelete}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <h2 className={'text-center'}>
                        {loading
                            ?'Đang hủy nhu cầu nhân sự xin chờ....'

                            :' Bạn có chắc chắn muốn huỷ nhu cầu nhân sự này?'
                        }

                    </h2>
                    <div className={'text-center'} style={{marginTop: "2rem"}}>
                        <Button disabled={loading} onClick={handleClose} className={classes.buttonNo}>
                            Huỷ
                        </Button>
                        <Button disabled={loading} onClick={()=>handleDelete()} className={classes.buttonYes}>
                            {loading ? 'Đang hủy': 'Đồng ý'}
                        </Button>
                    </div>
                </DialogContent>

            </Dialog>
            <NotificationContainer />
        </div>
    );
}

CancelRecruitmentModal.propTypes = {
    setShowModalConfirmDelete: PropTypes.func,
    showModalConfirmDelete: PropTypes.bool,
    deleteRecruitment: PropTypes.object,
};