import React from 'react';
import theme from './assets/theme/theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import {ThemeProvider} from '@material-ui/core/styles';
import {AuthProvider} from './contexts/AuthContext';
import Login from './views/auth/Login';
import AdminRouter from './routers/Custom/AdminRouter';
function App() {
  return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider>
            <Routes>
              <Route path={'/login'} element={<AuthLayout />}>
                <Route index element={<Login />} />
              </Route>
              <Route path={'/*'} element = {<AdminRouter />} />
            </Routes>
          </AuthProvider>

        </ThemeProvider>
      </BrowserRouter>
  );
}

export default App;
