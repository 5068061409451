import React from "react";
import { useLocation , Outlet } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import componentStyles from "assets/theme/layouts/auth.js";
import AdminFooter from '../components/Footers/AdminFooter';
import PropTypes from 'prop-types';

const useStyles = makeStyles(componentStyles);

const Auth = () => {
  const classes = useStyles();
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add(classes.bgDefault);
    return () => {
      document.body.classList.remove(classes.bgDefault);
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className={classes.authContainer} ref={mainContent} >
        <AuthHeader />
        {/* Page content */}
        <Container
          component={Box}
          maxWidth="xl"
          marginTop="-8rem"
          paddingBottom="3rem"
          position="relative"
          zIndex="101"
        >
          <Box component={Grid} container justifyContent="center">
            <Outlet />
          </Box>
        </Container>
        <div className={classes.footerAuth}>
          <AdminFooter  />
        </div>
      </div>

    </>
  );
};

export default Auth;

Auth.propTypes = {
  children: PropTypes.any,
}