import React from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// @material-ui/icons components

// core components

import {Paper, Tab, Tabs} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import EducateTableStatisticItem from './EducateTableStatisticItem';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchInternStatisticGrowth,
  getInternStatisticGrowth,
} from '../../features/statistic/internStatisticSlice';

const statisticsStyle = makeStyles(() => ({

  breadcrumbIcon: {
    width: '20px',
    height: '22px',
    paddingBottom: '2px',
    fontWeight: 'bold',
  },
  link: {
    color: '#7C7E7F',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  root: {
    flexGrow: 1,
    boxShadow:'none',
    width:'fit-content'
  },
  tabButton: {
    padding: "0 !important",
    minHeight:'30px',
    textTransform:'capitalize',
    "&.Mui-selected": {
      backgroundColor:"#5aceef !important",
    }
  },

}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box p={3} component={'div'}>
              <Typography component={'div'}>{children}</Typography>
            </Box>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function EducateTableStatistic() {
  const staticsClasses = statisticsStyle();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('month');
  const internStatisticGrow = useSelector(getInternStatisticGrowth);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeTab = async (type) => {
    setType(type);
    await dispatch(fetchInternStatisticGrowth({
      type,
    }));
  };

  return (
      <>
        <Paper square className={staticsClasses.root}>
          <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="icon tabs example"
          >
            <Tab className={staticsClasses.tabButton } onClick={()=>changeTab('month')}  label="Theo tháng" {...a11yProps(0)} />
            <Tab className={staticsClasses.tabButton } onClick={()=>changeTab('quarter')} label="Theo quý" {...a11yProps(1)} />
            <Tab className={staticsClasses.tabButton } onClick={()=>changeTab('year')} label="Theo năm" {...a11yProps(2)} />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
        <EducateTableStatisticItem type={type}
                                            internStatisticGrow={internStatisticGrow}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
              <EducateTableStatisticItem type={type}
                                            internStatisticGrow={internStatisticGrow}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EducateTableStatisticItem type={type}
                                     internStatisticGrow={internStatisticGrow}/>
        </TabPanel>
      </>
  );
}

export default EducateTableStatistic;
