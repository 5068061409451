import React, {useState} from 'react';
// @material-ui/core components
// @material-ui/icons components

// core components

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchInternStatisticChart,
  getInternStatisticChart,
} from '../../features/statistic/internStatisticSlice';

require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/map')(Highcharts);

const statisticsStyle = makeStyles(() => ({

  selectYear: {
    width: '60px',
    height: '22px',
    fontSize:'1rem',
    border:'none',
    backgroundColor:'unset',
    cursor:'pointer'
  },

  chartTitle: {
    position:'absolute',
    zIndex:'10000',
    top:'-25px',
    display:'flex',
    alignItems:'baseline',
    fontWeight:400,
    left:'40%',
  }
}));

function EducateChartStatistic() {

  const staticsClasses = statisticsStyle();
  const [year, setYear] = useState(parseInt((new Date()).getFullYear()));
  const [loading, setLoading] = useState(false);
  const dataChart = useSelector(getInternStatisticChart);

  const getDataOptionForLineChar = (chartData) => {
    return  {
      title: {
        text: '',
      },
      xAxis: {
        categories: [1,2,3,4,5,6,7,8,9,10,11,12],
        title: {
          text: 'Tháng ',
          font: 'bolid 26px "Trebuchet MS", Verdana, sans-serif',
        },
        labels: {
          style: {
            fontSize: '1rem',
            color: '#000',
          },
        },

      },
      tooltip: {
        headerFormat: '<b>Tháng {point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>'
      },
      yAxis: {
        tickInterval: 2,
        title: {
          text: 'Số lượng',
        },
        labels: {
          style: {
            fontSize: '1rem',
            color: '#000',
          },
        },

      },
      legend: {
        itemStyle: {
          fontWeight: 'bold',
          fontSize: '1rem',
          color: '#000',
        },
      },
      series: [
        {
          name: 'Thực tập sinh nhập học',
          data: chartData.enroll,
        },
        {
          name: 'Thực tập sinh nghỉ',
          data: chartData.stopped,
        },
        {
          name: 'Thực tập sinh tốt nghiệp ',
          data: chartData.pass,
        }
        , {
          name: 'Thực tập sinh Fail',
          data: chartData.fail,
        },
      ],

    };
  }

  const dispatch = useDispatch();
  const changeYear = async (e)=>{
    let value = e.target.value;
    setYear(value)
    setLoading(true)
    await dispatch(fetchInternStatisticChart({
      year: value
    }))

    setLoading(false)
  }

  const renderOptionYear = ()=>{
    let currentYear = parseInt((new Date()).getFullYear());
    let result=[];
    for (let year = 2020; year<= currentYear; year ++ ){
      result.push(<option key={`year-${year}`} value={year}>{year}</option>)
    }
    return result;
  }

  return (
      <>
        <Grid className={'position-relative'}>
          {loading ? 'Loading...'
              :
              (
                  <>
                  <Grid className={staticsClasses.chartTitle}>
                    <span>{ 'Biểu đồ đào tạo thực tập sinh trong năm '}</span>
                    <select className={staticsClasses.selectYear}
                            value={year}
                            onChange={(e)=>changeYear(e)}
                            name="year" title="Choose year">
                      <option key={'year-2019'} value={2019}>2019</option>
                      {renderOptionYear()}
                    </select>
                  </Grid>
                  <div className={'mt-4'}></div>
                  <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'chart'}
                      options={getDataOptionForLineChar(dataChart)}
                  />
                  </>
              )

          }
        </Grid>
      </>
  );
}

export default EducateChartStatistic;
