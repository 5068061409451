import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from '../../configs/axios';
import _, {isEmpty} from 'lodash';

const initialState = {
    recruitment_requests: [],
    recruitment_request: {},
    status: '',
    errors: {},
    recruitment_request_show: {},
    recruitment_request_id: null,
    recruitment_request_of_create_plan: {},

}

export const fetchRecruitmentRequest = createAsyncThunk('process/fetchRecruitmentRequest',
    async (data = {}, {rejectWithValue}) => {
        try {
            let paramString = new URLSearchParams(data).toString();

            return await axios.get(`/recruitment-request?${paramString}`);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const getRecruitmentRequestEdit = createAsyncThunk('process/getRecruitmentRequestEdit',
    async (data = {}, {rejectWithValue}) => {
        try {
            return await axios.get(`/recruitment-request/${data.id}/show`);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);


export const getRecruitmentRequestForCreatePlan = createAsyncThunk('process/getRecruitmentRequestForCreatePlan',
    async (data = {}, {rejectWithValue}) => {
        try {
            return await axios.get(`/recruitment-request/${data.id}/show`);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const getRecruitmentRequestShowRequest = createAsyncThunk('process/getRecruitmentRequestShowRequest',
    async (data = {}, {rejectWithValue}) => {
        try {
            return await axios.get(`/recruitment-request/${data.id}/show`);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);




export const createRecruitmentRequest = createAsyncThunk('process/createRecruitmentRequest',
    async (data = {}, {rejectWithValue}) => {
        try {
            return await axios.post(`/recruitment-request/store`, data);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const cancelRecruitmentRequest = createAsyncThunk('process/cancelRecruitmentRequest',
    async (data = {}, {rejectWithValue}) => {
        try {
            return await axios.post(`/recruitment-request/cancel`, data);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const updateRecruitmentRequest = createAsyncThunk('process/cancelRecruitmentRequest',
    async (data = {}, {rejectWithValue}) => {
        try {
            return await axios.post(`/recruitment-request/update/${data.id}`, data);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const rejectRecruitmentRequest = createAsyncThunk('process/rejectRecruitmentRequest',
    async (data = {}, {rejectWithValue}) => {
        try {
            return await axios.post(`/recruitment-request/reject-demand/${data.id}`, data);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

const processSlice = createSlice({
    name: 'process',
    initialState,
    reducers: {
        resetErrors:(state)=>{
            state.errors = {};
        },
        setRecruitmentRequestShow:(state,action)=>{
            state.recruitment_request_show = action.payload;
        },
        resetRecruitmentShow:(state)=>{
            state.recruitment_request_show = {};
        },
        resetRecruitmentOfCreatPlan:(state)=>{
            state.recruitment_request_of_create_plan = {};
        },
        resetRecruitmentRequestShowId: (state) =>{
            state.recruitment_request_id = null
        },

        setRecruitmentRequestShowId: (state, action) =>{
            state.recruitment_request_id = action.payload
        }
    },
    extraReducers: {
        [fetchRecruitmentRequest.pending]: (state)=>{
            state.status = 'loading'
        },

        [fetchRecruitmentRequest.fulfilled]: (state, action)=>{
            state.recruitment_requests = action?.payload?.data?.data;
        },

        [getRecruitmentRequestEdit.pending]: (state)=>{
            state.status = 'loading'
        },

        [getRecruitmentRequestEdit.fulfilled]: (state, action)=>{
            let recruitment = action?.payload?.data?.data;
            if(!isEmpty(recruitment))
            {
                state.recruitment_request_show = recruitment;
            }
        },

        [getRecruitmentRequestForCreatePlan.pending]: (state) => {
            state.status = 'loading';
        },

        [getRecruitmentRequestForCreatePlan.fulfilled]: (state, action) => {
            let recruitment = action?.payload?.data?.data;
            console.log(recruitment);
            if (!isEmpty(recruitment)) {
                state.recruitment_request_of_create_plan = recruitment;
            }
        },

        [getRecruitmentRequestShowRequest.pending]: (state) => {
            state.status = 'loading';
        },

        [getRecruitmentRequestShowRequest.fulfilled]: (state, action) => {
            let recruitment = action?.payload?.data?.data;
            if (!isEmpty(recruitment)) {
                state.recruitment_request_show = recruitment;
            }
        },

        [createRecruitmentRequest.pending]: (state)=>{
            state.status = 'loading'
        },

        [createRecruitmentRequest.fulfilled]: (state, action)=>{
            state.recruitment_requests = action?.payload?.data?.data;
        },

        [createRecruitmentRequest.rejected]: (state, action)=>{
            state.errors = action.payload.data.errors;
        },


        [cancelRecruitmentRequest.pending]: (state) => {
            state.status = 'loading';
        },

        [cancelRecruitmentRequest.fulfilled]: (state, action) => {
            let recruitmentCancel = action?.payload?.data?.data;
            let index = _.findIndex(state.recruitment_requests.data, (item)=>{
                return item.id == recruitmentCancel.id;
            })
            if (index > -1)
            {
                let recruitmentRequests = state.recruitment_requests;
                recruitmentRequests.data[index] = recruitmentCancel;
                state.recruitment_requests = recruitmentRequests;
            }
        },

        [rejectRecruitmentRequest.pending]: (state) => {
            state.status = 'loading';
        },

        [rejectRecruitmentRequest.fulfilled]: (state, action) => {
            let recruitmentUpdate = action?.payload?.data?.data;
            let index = _.findIndex(state.recruitment_requests.data, (item)=>{
                return item.id == recruitmentUpdate.id;
            })
            if (index > -1)
            {
                let recruitmentRequests = state.recruitment_requests;
                recruitmentRequests.data[index] = recruitmentUpdate;
                state.recruitment_requests = recruitmentRequests;
            }
        },

        [updateRecruitmentRequest.pending]: (state) => {
            state.status = 'loading';
        },

        [updateRecruitmentRequest.fulfilled]: (state, action) => {
            let recruitmentUpdate = action?.payload?.data?.data;
            let index = _.findIndex(state.recruitment_requests.data, (item)=>{
                return item.id == recruitmentUpdate.id;
            })
            if (index > -1)
            {
                let recruitmentRequests = state.recruitment_requests;
                recruitmentRequests.data[index] = recruitmentUpdate;
                state.recruitment_requests = recruitmentRequests;
            }
        },

        [updateRecruitmentRequest.rejected]: (state, action)=>{
            state.errors = action.payload.data.errors;
        },

    }
});

export const getRecruitmentRequests = state => state.process.recruitment_requests;
export const getRecruitmentRequestsErrors = state => state.process?.errors;
export const getRecruitmentRequestShow = state => state.process?.recruitment_request_show;
export const getRecruitmentRequestShowId = state => state.process?.recruitment_request_id;
export const getRecruitmentRequestCreatePlan = state => state.process?.recruitment_request_of_create_plan;

export const {
    resetErrors,
    setRecruitmentRequestShow,
    resetRecruitmentShow,
    resetRecruitmentOfCreatPlan,
    resetRecruitmentRequestShowId,
    setRecruitmentRequestShowId
} = processSlice.actions;
export default processSlice.reducer;
