import React from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
// @material-ui/icons components
import DirectionsRun from '@material-ui/icons/DirectionsRun';

// core components
import componentStyles
  from 'assets/theme/components/dropdowns/user-dropdown.js';
import {useAuth} from '../../contexts/AuthContext';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles(componentStyles);

export default function UserDropdown() {
  const {logout} = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {user} = useAuth();
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = async () => {
    setAnchorEl(null);
  };


  const logoutUser = async () => {
    setAnchorEl(null);
    await logout();
    navigate('/login')
  };

  const menuId = 'dropdowns-user-dropdown-id';
  const renderMenu = (
      <Menu
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          id={menuId}
          keepMounted
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
          open={isMenuOpen}
          onClose={handleMenuClose}
      >
        <Typography
            variant="h6"
            component="h6"
            classes={{root: classes.menuTitle}}
        >
          Welcome!
        </Typography>
        <Divider component="div" classes={{root: classes.dividerRoot}}/>
        <Box
            display="flex!important"
            alignItems="center!important"
            component={MenuItem}
            onClick={logoutUser}
        >
          <Box
              component={DirectionsRun}
              width="1.25rem!important"
              height="1.25rem!important"
              marginRight="1rem"
          />
          <span>Logout</span>
        </Box>
      </Menu>
  );

  return (
      <>
        <Button
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            classes={{
              label: classes.buttonLabel,
              root: classes.buttonRoot,
            }}
        >
          <Avatar
              alt="..."
              src={user?.avatar ?? require('assets/img/theme/team-4-800x800.jpg').default}
              classes={{
                root: classes.avatarRoot,
              }}
          />
          <Hidden mdDown>{user?.name}</Hidden>
        </Button>
        {renderMenu}
      </>
  );
}
