import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from '../../configs/axios';

const initialState = {
  roles: [],
};

export const fetchRoles = createAsyncThunk('user/fetchRole',
    async (data = {}, {rejectWithValue}) => {
      try {
        let paramString = new URLSearchParams(data).toString();
        return await axios.get(`/roles?${paramString}`);
      } catch ({response}) {
        return rejectWithValue(response);
      }
    },
);

const roleReduce = createSlice({
  name: 'role',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRoles.pending]: (state) => {
      state.status = 'loading';
    },

    [fetchRoles.fulfilled]: (state, action) => {
      state.roles = action?.payload?.data?.data;
    },

  },
});

export const getRoles = state => state.role.roles;
export default roleReduce.reducer;
