import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import EditRecruitmentModal from './EditRecruitmentModal';
import RecruitmentDetailModal from './Details/RecruitmentDetailModal';
import CancelRecruitmentModal from './CancelRecruitmentModal';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchRecruitmentRequest,
    getRecruitmentRequestEdit,
    getRecruitmentRequests,
    getRecruitmentRequestShow,
    getRecruitmentRequestShowId, getRecruitmentRequestShowRequest,
    setRecruitmentRequestShow,
} from '../../../../features/processes/processSlice';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {useAuth} from '../../../../contexts/AuthContext';
import {useQuery} from '../../../../hooks/useQuery';
import {isEmpty} from 'lodash';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    iconColor: {
        color: '#ffffff',
    },
    edit: {
        borderRadius: '50%',
        marginLeft: '5px',
        backgroundColor: '#ff9800',
        '&:hover': {
            backgroundColor: 'rgba(255,152,0,0.78)',
        },
    },
    cancel: {
        borderRadius: '50%',
        marginLeft: '5px',
        backgroundColor: '#F44336',
        '&:hover': {
            backgroundColor: '#f16156',
        },
    },
    show: {
        borderRadius: '50%',
        backgroundColor: '#00bcd4',
        '&:hover': {
            backgroundColor: 'rgba(0,188,212,0.85)',
        },
    },
    vertical_bl: {
        verticalAlign: 'baseline',
    },
    buttonDisabled: {
        opacity: '.65',
        boxShadow: 'none!important',
        cursor: 'not-allowed !important',
        pointerEvents: 'none',
    },
});

export default function RecruitmentList(props) {
    const classes = useStyles();
    const recruitmentRequests = useSelector(getRecruitmentRequests);
    const [showEditRecruitmentModal, setShowEditRecruitmentModal] = useState(
        false);
    const dispatch = useDispatch();
    const {authorize} = useAuth();
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [editRecruitment, setEditRecruitment] = useState({});
    const editRecruitmentModal = (recruitment) => {
        if (recruitment.processes_status == 'cancel' ||
            recruitment.status_active_order <= 1) {
            setShowEditRecruitmentModal(true);
            setEditRecruitment(recruitment);
        }
    };
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
    const [deleteRecruitment, setDeleteRecruitment] = useState();
    const recruitmentDelete = (recruitment) => {
        setShowModalConfirmDelete(true);
        setDeleteRecruitment(recruitment.id);
    };
    const recruitmentEditShow = useSelector(getRecruitmentRequestShow);

    const query = useQuery();
    let recruitmentRequestShowID = query.get('recruitment_id');
    useEffect(() => {
        async function replaceUrlWhenReload() {
            if (window.performance.navigation.type === 1 &&
                !isEmpty(recruitmentRequestShowID)) {
                let urlReload = location.origin + location.pathname;
                window.location.replace(urlReload);
            }
        }

        replaceUrlWhenReload();
    }, []);

    const recruitmentRequestShow = useSelector(getRecruitmentRequestShow);
    const recruitmentRequestId = useSelector(getRecruitmentRequestShowId);

    useEffect( () => {
      async function getRecruitmentRequestWithId() {
        if (!isEmpty(recruitmentRequestShowID)) {
          await dispatch(getRecruitmentRequestEdit({
            id: recruitmentRequestShowID,
          }));
        }
      }
      getRecruitmentRequestWithId();
    }, [recruitmentRequestShowID]);

    useEffect( () => {
      async function getRecruitmentRequestWithId() {
        if (recruitmentRequestId) {
          await dispatch(getRecruitmentRequestShowRequest({
            id: recruitmentRequestId,
          }));
        }
      }

      getRecruitmentRequestWithId();

    }, [recruitmentRequestId]);
    useEffect( () => {
      function setRecruitmentRequestModal() {
        if (!isEmpty(recruitmentEditShow)) {
          setShowDetailModal(true);
        }
      }
      setRecruitmentRequestModal();

    }, [recruitmentEditShow]);

    useEffect( () => {
      function showRecruitmentDetail() {
        if (!isEmpty(recruitmentRequestShow)) {
          setShowDetailModal(true);
        }
      }
      showRecruitmentDetail();
    }, [recruitmentRequestShow]);

    function handleShowRecruitmentDefault(recruitment) {
        setShowDetailModal(true);
        dispatch(setRecruitmentRequestShow(recruitment));
    }

    function renderRecruitmentRequest(recruitmentRequests) {
        let result = '';
        if (recruitmentRequests && recruitmentRequests?.data &&
            recruitmentRequests.data.length > 0) {
            let meta = recruitmentRequests.meta;
            result = recruitmentRequests.data.map((item, index) => {
                let iterator = (index + 1) +
                    ((meta.current_page - 1) * meta.per_page);
                return (<TableRow key={`user-${item.id}`}>
                    <TableCell component="th" className={classes.vertical_bl}
                               scope="row"
                               align="center">{iterator}</TableCell>
                    <TableCell className={classes.vertical_bl}
                               align="center">{item.full_name}</TableCell>
                    <TableCell className={classes.vertical_bl}
                               align="center">{item.created_at}</TableCell>
                    <TableCell className={classes.vertical_bl}
                               align="center">{item.status}</TableCell>
                    <TableCell className={classes.vertical_bl}
                               align="center">{item.demand_originator_name}
                    </TableCell>
                    <TableCell className={classes.vertical_bl} align="center">
                        <IconButton className={classes.show}
                                    onClick={() => handleShowRecruitmentDefault(
                                        item)}
                                    title={'Xem chi tiết nhu cầu'}
                        >
                            <VisibilityIcon className={classes.iconColor} />
                        </IconButton>
                        {
                            authorize.hasRole('head-of-department')
                                ? (<>
                                        <IconButton
                                            className={`${classes.edit}  ${(item.processes_status ==
                                                'cancel' ||
                                                item.status_active_order > 1)
                                                ? classes.buttonDisabled
                                                : ''}`}
                                            title={'Chỉnh sửa nhu cầu'}
                                            onClick={() => editRecruitmentModal(
                                                item)}>
                                            <EditIcon
                                                className={classes.iconColor} />
                                        </IconButton>
                                        <IconButton
                                            className={`${classes.cancel}  ${(item.processes_status ==
                                                'cancel' ||
                                                item.status_active_order > 1)
                                                ? classes.buttonDisabled
                                                : ''}`}
                                            title={'Huỷ nhu cầu'}
                                            onClick={() => recruitmentDelete(item)}>
                                            <CloseIcon
                                                className={classes.iconColor} />
                                        </IconButton>
                                    </>
                                )
                                : ''
                        }
                    </TableCell>
                </TableRow>);
            });
        } else {
            result = (<TableRow align="center">
                    <TableCell width="5rem">
                        <Typography variant="subtitle2">No data.</Typography>
                    </TableCell>
                </TableRow>
            );
        }

        return result;
    }

    const handleChange = async (event, value) => {
        let data = {
            page: value,
            name: props.nameProp,
            status: props.status,
            division: props.divisionSearch,
        };
        await dispatch(fetchRecruitmentRequest(data));
    };

    const getPagination = (paginate) => {
        if (paginate) {
            return (
                <Pagination
                    count={paginate.last_page}
                    onChange={handleChange}
                    color="primary" variant="outlined"
                    boundaryCount={5}
                    size={'small'}
                    page={paginate.current_page} />);
        }
    };

    return (
        <TableContainer component={Paper} style={{margin: '20px 0'}}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">STT</TableCell>
                        <TableCell align="center">Tên nhu cầu</TableCell>
                        <TableCell align="center">Thời gian
                            gửi&nbsp;</TableCell>
                        <TableCell align="center">Trạng thái&nbsp;</TableCell>
                        <TableCell align="center">Người gửi&nbsp;</TableCell>
                        <TableCell align="center">Hành động&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderRecruitmentRequest(recruitmentRequests)}
                </TableBody>
            </Table>

            <Box justifyContent="flex-start" display="flex"
                 style={{margin: '20px 0'}}>
                {getPagination(recruitmentRequests?.meta)}
            </Box>

            <EditRecruitmentModal editRecruitment={editRecruitment}
                                  setShowEditRecruitmentModal={setShowEditRecruitmentModal}
                                  showEditRecruitmentModal={showEditRecruitmentModal} />

            <RecruitmentDetailModal setShowDetailModal={setShowDetailModal}
                                    showDetailModal={showDetailModal}
            />
            <CancelRecruitmentModal
                showModalConfirmDelete={showModalConfirmDelete}
                deleteRecruitment={deleteRecruitment}
                setShowModalConfirmDelete={setShowModalConfirmDelete} />
        </TableContainer>
    );
}
RecruitmentList.propTypes = {
    nameProp: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    divisionSearch: PropTypes.string.isRequired,
};