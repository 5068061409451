import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInterns, getInterns } from '../../../features/user/userSlice';
import IconButton from '@material-ui/core/IconButton';
import { Card } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import UpdateScoreModal from './UpdateScoreModal';
import { getSubjects } from '../../../features/subjectSlice';
import ShowScoreModal from "./ShowScoreModal";
import { useAuth } from '../../../contexts/AuthContext';
import ProgressBar from 'components/ProgressBars/ProgressBar';
import { progressBarColor } from 'configs/app';

const useStyles = makeStyles({
    tableContainer: {
        minWidth: '650px',
    },
    table: {
        marginBottom: 0,
    },
    edit: {
        borderRadius: '50%',
        marginLeft: '5px',
        backgroundColor: '#ff9800',
        '&:hover': {
            backgroundColor: 'rgba(255,152,0,0.78)',
        },
    },
    show: {
        borderRadius: '50%',
        backgroundColor: '#00bcd4',
        '&:hover': {
            backgroundColor: 'rgba(0,188,212,0.85)',
        },
    },
    iconColor: {
        color: '#ffffff',
    },
    valignCenter: {
        verticalAlign: 'middle !important',
    },
    progressBar: {
        minWidth: '200px'
    }
});

export default function List(props) {
    const classes = useStyles();
    const subjects = useSelector(getSubjects);
    const [showEditModal, setShowEditModal] = useState(false);
    const { authorize } = useAuth();

    const [showScoreModal, setShowScoreModal] = useState(false)


    const [internEditId, setInternEditId] = useState(null);
    const [internShowId, setInternShowId] = useState(null);
    const interns = useSelector(getInterns);
    const dispatch = useDispatch();

    const editIntern = (id) => {
        setShowEditModal(true);
        setInternEditId(id);
    };

    const showScore = (id) => {
        setShowScoreModal(true);
        setInternShowId(id);
    };

    const renderProgressBar = (process) => {
        return (<ProgressBar bgcolor={progressBarColor.danger} percent={process} />)
    }

    function renderUser(users, subjects) {

        let result = '';

        if (users && users?.data && users.data.length > 0 && subjects &&
            subjects?.data) {
            let meta = users.meta;
            result = users.data.map((item, index) => {
                let iterator = (index + 1) + ((meta.current_page - 1) * meta.per_page);
                return (<TableRow key={item.id}>
                    <TableCell component="th" className={classes.valignCenter} scope="row"
                        align="center">{iterator}</TableCell>
                    <TableCell align="left" className={classes.valignCenter}
                        align-middle="center"> {item.name}</TableCell>
                    <TableCell align="center"
                        className={classes.valignCenter}>{item.start_date}</TableCell>
                    <TableCell align="center"
                        className={classes.valignCenter}>{item.date_training_total}</TableCell>
                    <TableCell align="center"
                            className={classes.valignCenter}>{item.division ?? 'NA'}</TableCell>
                    <TableCell align="center"
                        className={classes.valignCenter}>{item.position ?? 'NA'}</TableCell>
                    <TableCell align="center"
                        className={[classes.valignCenter, classes.progressBar]}>{renderProgressBar(item?.process_percent)}</TableCell>
                    <TableCell align="center"
                        className={classes.valignCenter}>{
                            item?.avg_score ?
                                parseFloat(item?.avg_score).toFixed(1) :
                                'NA'}</TableCell>
                    <TableCell align="center"
                        className={classes.valignCenter}>
                        {item.is_intern_pass == null
                            ? (<b>NA </b>)
                            : (item.is_intern_pass == true ? <span className={'text-success'}>Pass </span>
                                : <span className={'text-danger'}> Fail </span>
                            )
                        }
                    </TableCell>
                    <TableCell align="center">
                        {authorize.hasPermission('show-intern')
                            ? (<IconButton className={classes.show} title={'show'} onClick={() => {
                                showScore(item.id)
                            }}>
                                <VisibilityIcon className={classes.iconColor} />
                            </IconButton>
                            )
                            : ''
                        }
                        {authorize.hasPermission('edit-intern') && (item.training_state != "Dừng thực tập")
                            ? (
                                <IconButton className={classes.edit} title={'edit'} onClick={() => {
                                    editIntern(item.id);
                                }}>
                                    <EditIcon className={classes.iconColor} />
                                </IconButton>
                            )
                            : ''
                        }
                    </TableCell>
                </TableRow>);
            });
        } else {
            result = (<TableRow align="center">
                <TableCell width="5rem">
                    <Typography variant="subtitle2">No data.</Typography>
                </TableCell>
            </TableRow>
            );
        }

        return result;
    }

    const handleChange = async (event, value) => {
        let data = {
            page: value,
            name: props.nameProp,
            training_state: props.trainingProp,
            recruitment_plan_id: props.recruitmentPlanId
        };
        await dispatch(fetchInterns(data));
    };
    const getPagination = (paginate) => {
        if (paginate) {
            return (
                <Pagination
                    count={paginate.last_page}
                    onChange={handleChange}
                    color="primary" variant="outlined"
                    boundaryCount={5}
                    size={'small'}
                    page={paginate.current_page} />);
        }
    };

    return (
        <>
            <TableContainer component={Card} style={{ margin: '20px 0' }}
                className={classes.tableContainer}>
                <Table aria-label="table" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">STT</TableCell>
                            <TableCell align="center">Tên</TableCell>
                            <TableCell align="center">Bắt đầu&nbsp;</TableCell>
                            <TableCell align="center">Số ngày TT&nbsp;</TableCell>
                            <TableCell align="center">Bộ phận&nbsp;</TableCell>
                            <TableCell align="center">Vị trí&nbsp;</TableCell>
                            <TableCell align="center">Tiến độ&nbsp;</TableCell>
                            <TableCell align="center">Tổng kết&nbsp;</TableCell>
                            <TableCell align="center">Kết quả&nbsp;</TableCell>
                            <TableCell align="center">Hành động&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderUser(interns, subjects)}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box justifyContent="flex-start" display="flex"
                style={{ margin: '20px 0' }}>
                {getPagination(interns?.meta)}
            </Box>
            <ShowScoreModal
                showScoreModal={showScoreModal}
                internShowId={internShowId}
                setShowScoreModal={setShowScoreModal} />
            <UpdateScoreModal showEditModal={showEditModal}
                setShowInternModal={setShowEditModal}
                internEditId={internEditId} />
        </>
    );
}

List.propTypes = {
    nameProp: PropTypes.string.isRequired,
    trainingProp: PropTypes.string.isRequired,
    recruitmentPlanId: PropTypes.string.isRequired,
};