import {Checkbox, withStyles} from '@material-ui/core';
import {green} from '@material-ui/core/colors';
import React from 'react';

export const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
