import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from '../../configs/axios';
import {setParamQueryString} from '../../services/queryStringService';

const initialState = {
    interview_statistic_index: {},
    interview_statistic_chart: [],
    interview_statistic_growth: {},
}

export const fetchInterviewStatisticIndex = createAsyncThunk('interviewStatistic/fetchInterviewStatisticIndex',
    async (data = {}, {rejectWithValue}) => {
        try {
            let paramString = setParamQueryString(data);
            return await axios.get(`/interviews/interview-statistical${paramString}`);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const fetchInterviewStatisticChart = createAsyncThunk('interviewStatistic/fetchInterviewStatisticChart',
    async (data = {}, {rejectWithValue}) => {
        try {
            let paramString = setParamQueryString(data);
            return await axios.get(`/interviews/chart-interview${paramString}`);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

export const fetchInterviewStatisticGrowth = createAsyncThunk('interviewStatistic/fetchInterviewStatisticGrowth',
    async (data = {}, {rejectWithValue}) => {
        try {
            let paramString = setParamQueryString(data);
            return await axios.get(`/interviews/growth-result-statistics${paramString}`);
        } catch ({response}) {
            return rejectWithValue(response);
        }
    },
);

const interviewStatisticSlice = createSlice({
    name: 'interviewStatistic',
    initialState,
    reducers: {},
    extraReducers: {

        [fetchInterviewStatisticIndex.pending]: (state) => {
            state.status = 'loading'
        },

        [fetchInterviewStatisticIndex.fulfilled]: (state, action) => {
            state.interview_statistic_index = action?.payload?.data?.data;
        },

        [fetchInterviewStatisticChart.pending]: (state) => {
            state.status = 'loading'
        },

        [fetchInterviewStatisticChart.fulfilled]: (state, action) => {
            state.interview_statistic_chart = action?.payload?.data?.data;
        },


        [fetchInterviewStatisticGrowth.pending]: (state) => {
            state.status = 'loading'
        },

        [fetchInterviewStatisticGrowth.fulfilled]: (state, action) => {
            state.interview_statistic_growth = action?.payload?.data?.data;
        },
    }
});

export const getInterviewStatisticIndex = state => state.interviewStatistic.interview_statistic_index;
export const getInterviewStatisticChart = state => state.interviewStatistic.interview_statistic_chart;
export const getInterviewStatisticGrowth = state => state.interviewStatistic.interview_statistic_growth;

export default interviewStatisticSlice.reducer;
