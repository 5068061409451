import React, {useEffect, useState} from 'react';
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import '../../../../assets/css/custom/container.css';

import componentStyles from "assets/theme/views/admin/dashboard.js";
import {MenuItem, Select, withStyles} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import HelpIcon from '@material-ui/icons/Help';
import Button from "@material-ui/core/Button";
import WorkIcon from "@material-ui/icons/Work";
import {useDispatch, useSelector} from 'react-redux';
import {
   getRecruitmentRequestCreatePlan,
} from '../../../../features/processes/processSlice';
import useDebounce from '../../../../hooks/useDebounce';
import {useLocation} from 'react-router-dom';
import RecruitmentProcess from "../Recruitments/RecruitmentProcess";
import RecruitmentPlanList from "./RecruitmentPlanList";
import AddRecruitmentPlanModal from "./AddRecruitmentPlanModal";
import {isEmpty} from 'lodash';
import {
    fetchRecruitmentPlan,
    resetRecruitmentRequestIdForCreate,
} from '../../../../features/processes/recruitmentPlanSlice';
import {dehaDivisions} from '../../../../configs/school';

const useStyles = makeStyles(componentStyles);

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

function RecruitmentPlan() {
    const classes = useStyles();
    const selectStyle = {
        marginLeft: '10px',
        width: '15rem',
    };
    const inputSearchStyle = {
        border: '1px solid #ced4da',
        borderRadius: 4,
        width: '15rem',
    };
    const breadCrumbsStyle = {
        paddingTop: '0.5rem',
        marginBottom: '-0.55rem',
        marginLeft: '3px',
        fontWeight: 'bold',
    };
    const titleStyle = {
        marginTop: '-1.25rem',
    };
    const workIconStyle = {
        position: 'absolute',
        marginTop: '1.4rem',
    };

    const breadcrumbStyle = {
        marginLeft: "-0.48rem",
    };
    const buttonRecruitmentProcess = {
        position: "absolute",
        width: "15rem",
        fontSize: "15px",
        color: "rgb(8 89 191)",
        top: "6rem",
        right: "0.4rem"
    };


    const [showAddRecruitmentPlanModal, setShowAddRecruitmentPlanModal] = useState(false)
    const paramsSearch = useLocation();
    const [recruitmentPlanCreate, setRecruitmentPlanCreate] = useState({});
    const [nameSearch, setNameSearch] = useState('');
    const [status, setStatus] = useState(' ');
    const [divisionSearch, setDivisionSearch] = useState(' ');
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Kế hoạch tuyển dụng';
        dispatch(resetRecruitmentRequestIdForCreate())
    }, []);

    useEffect(() => {
        async function getRecruitmentPlanWithName() {
            if (paramsSearch?.name) {
                dispatch(fetchRecruitmentPlan({
                    name: paramsSearch?.name,
                    page: 1,
                }));
                setNameSearch(paramsSearch.name);
            } else {
                dispatch(fetchRecruitmentPlan());
            }
            setShowAddRecruitmentPlanModal(false);
        }

        getRecruitmentPlanWithName().then();
    }, [paramsSearch?.name]);

    async function searchWithName(e) {
        let nameSearch = e.target.value;
        setNameSearch(nameSearch);
        search(nameSearch, status, divisionSearch);
    }

   async function searchWithStatus(e) {
        let value = e.target.value;
        setStatus(value);
        searchWithoutDebounce(nameSearch, value, divisionSearch)
    }

    function searchWithDivision(e) {
        let value = e.target.value;
        setDivisionSearch(value);
        searchWithoutDebounce(nameSearch, status, value).then( )
    }

    const searchWithoutDebounce = async (name, status, division) => {
        let data = {
            page: 1,
            name: name,
            status: status,
            division: division
        };
        await dispatch(fetchRecruitmentPlan(data));
    }
    const search = useDebounce(function (...arg) {
        const [name, status, division] = arg;

        let data = {
            page: 1,
            name: name,
            status: status,
            division: division
        };
        dispatch(fetchRecruitmentPlan(data));
    });

    const [showRecruitmentProcessModal, setShowRecruitmentProcessModal] = useState();
    const showRecruitmentProcess = () => {
        setShowRecruitmentProcessModal(true);
    };
    const helpIconStyle = {
        height: "25px",
        width: "25px"
    }

    const recruitmentCreatePlan = useSelector(getRecruitmentRequestCreatePlan);

    useEffect(() => {
        if(!isEmpty(recruitmentCreatePlan))
        {
            setRecruitmentPlanCreate(recruitmentCreatePlan)
            setShowAddRecruitmentPlanModal(true)
        }
        return () => { };
    }, [recruitmentCreatePlan]);



    return (
        <>
            {/* Page content */}
            <Container
                maxWidth={false}
                className={`educate ${classes.flexGrow_1}`}
                classes={{root: classes.containerRoot}}
            >
                <div>
                    <WorkIcon style={workIconStyle}/>
                    <Breadcrumbs style={breadCrumbsStyle} aria-label="breadcrumb">
                        <Link color="inherit">
                            Tuyển dụng
                        </Link>
                        <Link color="inherit" to="/recruitment-plan" style={breadcrumbStyle}>
                            Kế hoạch tuyển dụng
                        </Link>
                    </Breadcrumbs>
                    <h2 style={titleStyle}>Kế hoạch tuyển dụng</h2>
                    <TextField id="filled-search" placeholder="Tìm kiếm..."
                               type="search"
                               variant="filled"
                               value={nameSearch}
                               onChange={e => searchWithName(e)}
                               style={inputSearchStyle}
                    />
                    <FormControl style={selectStyle}>
                        <Select
                            input={<BootstrapInput/>}
                            defaultValue={' '}
                            value={status}
                            onChange={e => searchWithStatus(e)}

                        >
                            <MenuItem value={' '}>Trạng thái...</MenuItem>
                            <MenuItem value={'Đang chờ'}>Đang chờ</MenuItem>
                            <MenuItem value={'Đã phê duyệt'}>Đã phê duyệt</MenuItem>
                            <MenuItem value={'Đã hoàn thành'}>Đã hoàn thành</MenuItem>
                            <MenuItem value={'Bị từ chối'}>Bị từ chối</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl style={selectStyle}>
                        <Select
                            input={<BootstrapInput/>}
                            value={divisionSearch}
                            onChange={event => searchWithDivision(event)}
                            defaultValue={' '}
                        >
                            <MenuItem value={' '}>Bộ phận...</MenuItem>
                            {dehaDivisions.map((division) => {
                                return (
                                    <MenuItem key={`edit-recruitment${division}`}
                                              value={division}>{division}</MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>

                    <Button onClick={showRecruitmentProcess}
                            style={buttonRecruitmentProcess}>
                        Quy trình tuyển dụng <HelpIcon style={helpIconStyle}/>
                    </Button>
                    <RecruitmentProcess setShowRecruitmentProcessModal={setShowRecruitmentProcessModal}
                                        showRecruitmentProcessModal={showRecruitmentProcessModal}/>
                    <RecruitmentPlanList nameProp={nameSearch}
                                         divisionSearch ={divisionSearch}
                                         status={status}/>
                    <AddRecruitmentPlanModal recruitmentPlanCreate={recruitmentPlanCreate}
                                             setShowAddRecruitmentPlanModal={setShowAddRecruitmentPlanModal}
                                             showAddRecruitmentPlanModal={showAddRecruitmentPlanModal}/>
                </div>
            </Container>
        </>
    );
}

export default RecruitmentPlan;
